import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Observable } from 'rxjs';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'admin-orders',
  templateUrl: './admin-orders.html',
  styleUrls: ['./admin-orders.scss'],
})

export class AdminOrdersComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    public loading;

    public createOrder;
    public new_order = {};
    public services;

    //TABLE
    public orderColumns = ['id', 'date', 'user', 'service', 'price', 'payment', 'status', 'edit'];
    public order_status;
    public totalItems = 0;
    public page = 0;
    public size = 10;
    public orders: MatTableDataSource<any>;
    @ViewChild('orderPag') paginator: MatPaginator;

    //FILTER
    public filter: any = {};
    private default_filter = { status: 'any', search: '' };

    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _shop: ShopService,
        private _utils: Utils,
    ) {
        this.order_status = _utils.order_status;
    }


    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'order' && !this.loaded){
                this.loaded = true;
                Object.assign(this.filter, this.default_filter);
                this._loadServices();
                this.loadOrders();
            }
        });
    }


    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */

    private _loadServices(){

        this._shop.getServices().toPromise()
        .then(result =>{
            this.services = result['services'].sort( (a,b) => a.price - b.price );
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar servicios", 3000);
        })

    }


    public loadOrders(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        this._admin.getOrders2(this.size, this.page, query)
        .then(response => {
            this._loadTable(response['data'], response['total']);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar pedidos");
        })

    }


    private async _loadTable(data, total){

        let placeholder = new Array(total)

        //Load users and services
        await this._fillUsers(data);

        const startItem = this.page * this.size;
        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = data[i];
        }

        this.orders = new MatTableDataSource(placeholder);
        this.orders.paginator = this.paginator;

        this.loading = false;

    }


    private async _fillUsers(data){

        //Get ids
        let ids = data.map(item => item.buyer_id);

        let response = await this._admin.getUsers(ids.length, 0, {_id: {$in: ids}});

        //Fill items with corresponding user
        let users = response['data'];
        data.map(item => item.user = this._findUser(item.buyer_id, users));

    }


    private _findUser(id, users){

        for(let user of users) if(user._id == id) return user;

    }


    public getBilling(user){

        return `${user.cif}
        ${user.billName}
        ${user.address}
        ${user.cp} ${user.city}
        ${user.country}`

    }

    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadOrders(true);

    }


    private _parseFilter(){

        let query: any = {};

        if(this.filter.status !== 'any') query.status = this.filter.status;
        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {buyer_id: num },
                {price: num },
                {'service_snapshot.quantity': num },

                //Texts
                {'service_snapshot.name': regex },
                {'service_snapshot.service_type': regex }

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }


    /*
    |--------------------------------------------------------------------------
    | ACTIONS
    |--------------------------------------------------------------------------
    */

    public editOrder(order){

        var data = { status: order.status };
        this._admin.updateOrder(order._id, data).toPromise()
        .then(result =>{
            this._utils.showSuccess('Pedido #' + order._id + ' editado correctamente', 5000);
            order.edit = false;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar pedido", 3000);
        })

    }


    public downloadBill(order){
        this._admin.billOrder(order._id).toPromise()
        .then(response =>{
            order.billed = true;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar pedido", 3000);
        })
    }


    public downloadInvoice(order_id){

        document.body.style.cursor = 'progress';

        this._admin.getInvoicePDF(order_id)
        .then( (res: any) =>{
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("No es posible descargar esta factura")
        })
        .finally(()=>{
            document.body.style.cursor = 'default';
        })

    }

    public sendInvoiceEmail(order){

        this._admin.sendInvoiceEmail(order._id)
        .then( res => {
            this._utils.showSuccess("Envío realizado")
            order.invoice_sent = true;            
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al enviar la factura")
        })

    }


    public addOrder(){

        this._shop.createOrderAdmin(this.new_order['buyer_id'], {
            service_id: this.new_order['service']._id,
            pay_method: this.new_order['pay_method']
        })
        .then(result => {
            this.loadOrders();
            this._utils.showSuccess("Pedido creado correctamente");
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al crear pedido", 3000);
        })

    }

    public upload2CS(order){

        this._admin.uploadOrder2MT(order._id)
        .then( res => {
            this._utils.showSuccess("Subida realizada")
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al subir el pedido")
        })

    }

    public updateCustomer(order){
            
        this._admin.updateCustomer(order._id)
        .then( res => {
            this._utils.showSuccess("Factura actualizada")
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al actualizar la factura")
        })

    }



}

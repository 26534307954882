import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AlertSnackbarComponent } from '../components/dialogs/alert-snackbar/alert-snackbar';
import { MatSnackBar, MatBottomSheet } from '@angular/material';
import { resolve, reject } from 'q';
import { Angulartics2 } from 'angulartics2';
import { sha256 } from 'js-sha256';
import { ConfirmDialog } from '../components/dialogs/confirm-dialog/confirm-dialog';
declare var $: any;

@Injectable()
export class Utils {

    hideAlert: boolean = true;

    constructor(
        private meta: Meta, 
        private title: Title, 
        public snackBar: MatSnackBar, 
        private _http: HttpClient, 
        private angulartics2: Angulartics2,
        private confirmDialog: MatBottomSheet 
    ) {}

    /* PRO */
    frontURL = "https://panel.imperatool.com";
    backURL = "https://api.imperatool.com";


    /* DEV */
    // frontURL = "http://localhost:8083";
    // backURL = "http://localhost:8084";

    public lg_credits = 
    [
        0.5, //LG 1
        0.6, //LG 2
        0.7, //LG 3
        0.8, //LG 4
        0.9, //LG 5
        1.0, //LG 6
        1.1, //LG 7
        1.3, //LG 8
        1.6, //LG 9
        2.0, //LG 10
    ]

    public credits_eur = 10;

    public credits_rw = 100;

    public min_credits_pay = 250;

    public ref_days = 30 * 4;

    public ref_levels = [
        { 
            lvl: 1, 
            limit_accounts: 15,
            revenue: 0.2
        },
        { 
            lvl: 2, 
            limit_accounts: 30,
            revenue: 0.25
        },
        { 
            lvl: 3, 
            limit_accounts: 50,
            revenue: 0.3
        },
        { 
            lvl: 4, 
            limit_accounts: 100,
            revenue: 0.35
        },
        { 
            lvl: 5,
            revenue: 0.5
        },

    ];

    public order_status = {
        UNPAID: 0,
        PAID: 1,
        COMPLETED: 3,
        CANCELED: 4
    };

    public sub_status = {
        PAUSED: 0,
        ACTIVE: 1,
        FINISHED: 2
    };

    public review_status = {
        WAITING: 0,
        RESERVED: 1,
        CHECKING: 2,
        PUBLISHED: 3,
        ADMIN: 4,
        CANCELED: -1,
    }

    public account_type = {
        COMPANY: "company",
        PUBLISHER: "publisher",
        MULTI: "both"
    }

    public transaction_type = {

        //User purchase credits
        CREDIT_PURCHASE: "credit_purchase",

        //User spend credits on a new review
        REVIEW_PURCHASE: "rw_purchase",

        //Admin or user cancel a review
        REVIEW_CANCEL: "rw_cancel",

        //User publish review and get credits for it
        REVIEW_PAY: "rw_pay",

        //User spend credits on a new review extra
        REVIEW_EXTRAS: "rw_extras",

        //User spend money and Ref gets credits
        REF_PAY: "ref_pay",

        //User withdraw credits into money
        WITHDRAW: "withdraw",
        
        //User left monthly reviews
        SUB_SAVE: "sub_save",

        CREDIT_EXPIRATION: "credit_exp",

        OTHER: "other"
    }

    public error_codes = {
        MAX_REVIEWS_REACHED : 1,
        RW_TEXT_REPEATED: 2
    }

    jwt() {
        // create authorization header with jwt token
        let headers;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
          headers = new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
        }

        return {headers : headers};
    }

    showError(msg, time = 5000) {
        var data = {
            msg: msg,
            img: 'errorFace.png'
        };
        this.snackBar.openFromComponent(AlertSnackbarComponent,{data: data, duration: time, panelClass: ['errorPanel']});
    }

    showSuccess(msg, time = 5000) {
      var data = {
        msg: msg,
        img: 'successFace.png'
      };
      this.snackBar.openFromComponent(AlertSnackbarComponent,{data: data, duration: time, panelClass: ['errorPanel']});
    }

    lazyScript(src){
        let node = document.createElement('script');
        node.src = src;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }


    getCountries(){
        return this._http.get(this.backURL + '/api/gmb/countries').pipe();
    }

    getCities(countryCode){
        return this._http.get(this.backURL + '/api/gmb/city/' + countryCode).pipe().toPromise();
    }

    //Facebook Pixel
    pixelEvent(eventName, data){
        try{ this.angulartics2.eventTrack.next({ action: eventName, properties: data }) }catch(e){console.log("Pixel error", e)}
    }

    loginTawk(name, email){
        try{
            const api_key = '94a70d7f5cce0c7e488a14e03b1188ed27c3540c';
            this.setTawkTo({
                name: name,
                email: email,
                hash: sha256.hmac(api_key, email)
            });
        }catch(e){console.log("Tawkto error", e)}
    }

    setTawkTo(data){
        try{
            var tawk_api = window['Tawk_API'];
            if(tawk_api){
    
                //Hide for publishers
                if(window.localStorage.currentUser){
                    let user = JSON.parse(window.localStorage.currentUser);
                    if(user.type == this.account_type.PUBLISHER) tawk_api.hideWidget();
                }
    
                tawk_api.setAttributes(data, error =>{
                    if(error) console.log(error);
                });
            }
        }catch(e){console.log("Tawkto error", e)}
    }

    reloadTawkTo(){

        var url = window['tawkto'];

        if(url){

            var data = this.parseTawktoURL(url);

            this._http.get(data.vs + '?' + 
            'k='    + data.k +
            '&u='   + data.u +
            '&w='   + data.w + 
            '&uv='  + data.uv +
            '&a='   + data.a +
            '&jv='  + data.jv +
            '&p='   + data.p +
            '&r='   + data.r +
            '&transport=' + data.transport).toPromise()
            .then(response=>{})
            .catch(error=>{});
        }

    }

    findQuery(key, array){
        for (let i = 0; i < array.length; i++) {
            var cookie = array[i].split('=');
            if(key == cookie[0])return cookie[1];
        }
    }

    parseTawktoURL(url){
        var vs = url.split('?k')[0];
        var params = url.split(vs+'?')[1].split('&');
        var k = this.findQuery('k', params);
        var u = this.findQuery('u', params);
        var w = this.findQuery('w', params);
        var uv = this.findQuery('uv', params);
        var a = this.findQuery('a', params);
        var jv = this.findQuery('jv', params);

        var data = {
            vs: vs,
            k: k,
            u: u,
            w: w,
            uv: uv,
            a: a,
            jv: jv,
            p: window.location.pathname,
            r: window.location.href,
            transport: 'polling'
        }

        return data;
    }

    //Confirm dialog
    openConfirmation(message, okMessage, cancelMessage, doCallback, cancelCallback = ()=>{}){
        this.confirmDialog.open(ConfirmDialog, 
        { data: {
            message: message,
            ok_text: okMessage,
            cancel_text: cancelMessage,
            next: doCallback,
            back: cancelCallback
        }});
    }

    parseDate(date){
        return new Date(date).toLocaleDateString();
    }

    parseDateTime(date){
        return new Date(date).toLocaleTimeString();
    }

    parseProfileURL(publish_url){
        return publish_url.replace('writereview', 'reviews');

    }

    parsePrice(price){
        return new Number(price).toFixed(2);
    }

    jsonp(uri) {
        return new Promise(function(resolve, reject) {
            var id = '_' + Math.round(10000 * Math.random());
            var callbackName = 'jsonp_callback_' + id;
            window[callbackName] = function(data) {
                delete window[callbackName];
                var ele = document.getElementById(id);
                ele.parentNode.removeChild(ele);
                resolve(data);
            }
    
            var src = uri + '&callback=' + callbackName;
            var script = document.createElement('script');
            script.src = src;
            script.id = id;
            script.addEventListener('error', reject);
            (document.getElementsByTagName('head')[0] || document.body || document.documentElement).appendChild(script)
        });
    }

    img2data(url){
        return new Promise( (resolve, reject)=>{
            var image = new Image();
            image.crossOrigin = "anonymous";
            image.src = url;
            // get file name - you might need to modify this if your image url doesn't contain a file extension otherwise you can set the file name manually
            var fileName = image.src.split(/(\\|\/)/g).pop();
            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
                canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size
                canvas.getContext('2d').drawImage(image, 0, 0);
                var blob;
                // ... get as Data URI
                if (image.src.indexOf(".jpg") > -1) {
                    blob = canvas.toDataURL("image/jpeg");
                } else if (image.src.indexOf(".png") > -1) {
                    blob = canvas.toDataURL("image/png");
                } else if (image.src.indexOf(".gif") > -1) {
                    blob = canvas.toDataURL("image/gif");
                } else {
                    blob = canvas.toDataURL("image/png");
                }
                resolve(blob);
            };
        })
    }
    
}

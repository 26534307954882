import { Component, OnInit } from '@angular/core';

import { Utils } from '../../../../services/utils';
import { FormControl, Validators } from '@angular/forms';
import { GoogleServices } from 'src/app/services/google-service';
import { UserServices } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
    selector: 'new-profile',
    templateUrl: 'new-profile.component.html',
    styleUrls: ['./new-profile.component.scss'],
})

export class NewProfileComponent implements OnInit {

    nameControl: FormControl;
    manualControl: FormControl;
    countryControl: FormControl;
    cityControl: FormControl;
    countryControlM: FormControl;
    cityControlM: FormControl;

    public countrySelected;
    public countries;

    public citySelected;
    public cities;

    callingAPI: boolean;
    profile: any;

    constructor(
        public _i18n: CustomTranslator,
        private _utils: Utils,
        private google: GoogleServices,
        private _user: UserServices,
        private _router: Router
    ) { }

    ngOnInit() {
        this.nameControl = new FormControl('', [Validators.required]);
        this.manualControl = new FormControl('', [Validators.required]);
        this.countryControl = new FormControl('', [Validators.required]);
        this.cityControl = new FormControl('', [Validators.required]);
        this.countryControlM = new FormControl('', [Validators.required]);
        this.cityControlM = new FormControl('', [Validators.required]);
        this._loadCountries();
    }

    countryChange() {
        this._utils.getCities(this.countrySelected.alpha2Code)
            .then(cities => {
                this.cities = cities;
            })
            .catch(error => {
                console.log(error);
                this._utils.showError("Error al cargar la lista de ciudades", 5000);
            })
    }

    _loadCountries() {
        this._utils.getCountries().toPromise()
            .then((countries: Array<any>) => {
                countries.sort((a, b) => {
                    var a_value: String = a.translations.es ? a.translations.es : a.name;
                    var b_value = b.translations.es ? b.translations.es : b.name;
                    return a_value.localeCompare(b_value);
                })
                this.countries = countries;
            })
            .catch(error => {
                console.log(error);
                this._utils.showError("Error al cargar la lista de paises", 5000);
            })
    }

    nameChanged() {
        if (this.nameControl.value == "" || this.callingAPI) return;
        this.callingAPI = true;
        var oldValue = this.nameControl.value
        setTimeout(() => { this.checkInput(oldValue) }, 2500);
    }

    checkInput(oldValue) {
        //INPUT DID NOT CHANGE IN 3000ms
        if (this.nameControl.value === oldValue) {
            this.callingAPI = true;
            this.search();
        }
        //INPUT CHANGED
        else {
            setTimeout(() => { this.checkInput(this.nameControl.value) }, 2500);
        }
    }

    search() {

        if (!this.countrySelected || !this.citySelected) {
            this.countryControl.markAsTouched();
            this.cityControl.markAsTouched();
            this.callingAPI = false;
            return;
        }

        var name = this._removeAccent(this.nameControl.value + ' ' + this.citySelected.name);
        name = name.split(" ").join("+");
        this.google.searchGoogle(name).subscribe(
            result => {
                this.profile = result;
                this.callingAPI = false;
            },
            error => {
                this.profile = {};
                this.callingAPI = false;
                this.nameControl.setErrors({ NotFound: true });
                this._utils.showError("No se ha encontrado ningún perfil", 5000);
            }
        );
    }

    save() {
        this.profile['keywords'] = this.nameControl.value;
        this.profile['country'] = this.countrySelected.name;
        this.profile['city'] = this.citySelected.name;

        this._user.createProfile(this.profile).toPromise()
            .then(result => {
                //update cache
                var user = this._user.getLocalUser();
                if (user.profiles) user.profiles = user.profiles.concat([result['profile']]);
                else user.profiles = [result['profile']];
                this._user.setLocalUser(user);

                this._router.navigate(["/es/dashboard/profiles"]);
            })
            .catch(error => {
                console.log(error);
                this._utils.showError("Error al guardar perfil.", 5000);
            });
    }

    saveManual() {

        if (!this.countrySelected || !this.citySelected) {
            this.countryControlM.markAsTouched();
            this.cityControlM.markAsTouched();
            return;
        }

        var data = {
            profile: this.manualControl.value,
            country: this.countrySelected.name,
            city: this.citySelected.name
        }
        this._user.createProfileManual(data).toPromise()
            .then(result => {
                //update cache
                var user = this._user.getLocalUser();
                if (user.profiles) user.profiles = user.profiles.concat([result['profile']]);
                else user.profiles = [result['profile']];
                this._user.setLocalUser(user);

                this._router.navigate(["/es/dashboard/profiles"]);
            })
            .catch(error => {
                console.log(error);
                this._utils.showError("Error al guardar perfil.", 5000);
            });
    }

    private _removeAccent(text: string) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

}

/**
 * Created by marco on 18/06/2017.
 */
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class AdminService {
  
    constructor(private _http: HttpClient, private _utils : Utils) { }

    getAuth(){
        return this._http.get(this._utils.backURL + '/api/user/admin', this._utils.jwt()).pipe();
    }

    createService(service){
        return this._http.post(this._utils.backURL + '/api/shop/service', service, this._utils.jwt()).pipe();
    }
    
    updateOrder(order_id, data){
        return this._http.put(this._utils.backURL + '/api/shop/order/' + order_id, data, this._utils.jwt()).pipe();
    }
    
    getReviews(){
        return this._http.get(this._utils.backURL + '/api/gmb/review/list', this._utils.jwt()).pipe();
    }
    
    getReviewsPag(size, page, filter){
        return this._http.post(this._utils.backURL + `/api/gmb/review/list?size=${size}&page=${page}`, filter, this._utils.jwt()).pipe();
    }
    
    getUser(id){
        return this._http.get(this._utils.backURL + '/api/user/data/' + id, this._utils.jwt()).pipe();
    }
    
    updateLG(account_id, lg){
        return this._http.put(this._utils.backURL + '/api/gmb/account/' + account_id + '/lg', {lg: lg}, this._utils.jwt()).pipe();
    }
    
    getSubscriptions(){
        return this._http.get(this._utils.backURL + '/api/admin/subs/', this._utils.jwt()).pipe();
    }

    updateSub(sub_id, status){
        var sub = { _id: sub_id, status: status};
        return this._http.put(this._utils.backURL + '/api/admin/sub/', {sub: sub}, this._utils.jwt()).pipe();
    }
    
    renewSub(sub_id){
        return this._http.post(this._utils.backURL + '/api/admin/sub/' + sub_id + '/renew', {}, this._utils.jwt()).pipe();
    }

    billSub(sub_id){
        return this._http.post(this._utils.backURL + '/api/admin/sub/' + sub_id + '/bill', {}, this._utils.jwt()).pipe();
    }
    
    billOrder(order_id){
        return this._http.post(this._utils.backURL + '/api/admin/order/' + order_id + '/bill', {}, this._utils.jwt()).pipe();
    }

    /*
    |-------------
    | ORDERS
    |-------------
    */

    uploadOrder2MT(order_id){
        return this._http.post(this._utils.backURL + '/api/admin/order/' + order_id + '/upload2mt', {}, this._utils.jwt()).toPromise();
    }

    updateCustomer(order_id){
        return this._http.post(this._utils.backURL + '/api/admin/order/' + order_id + '/update-billing', {}, this._utils.jwt()).toPromise();
    }

    /*
    |-------------
    | INVOICES
    |-------------
    */

    getInvoicePDF(order_id){

        const url = `${this._utils.backURL}/api/admin/order/${order_id}/invoice`;
        
        let options = this._utils.jwt();
        options['responseType'] = 'blob';

        return this._http.get(url, options).toPromise();

    }

    sendInvoiceEmail(order_id){

        const url = `${this._utils.backURL}/api/admin/order/${order_id}/invoice/send-email`;

        return this._http.post(url, {}, this._utils.jwt()).toPromise();

    }

    //Transactions
  
    getTransactions(){
        return this._http.get(this._utils.backURL + '/api/admin/transactions/', this._utils.jwt()).pipe();
    }

    createTransaction(user_id, credits, type, info){

        var data = {
            user_id: user_id,
            credits: credits,
            type: type,
            info: info
        };

        return this._http.post(this._utils.backURL + '/api/admin/transaction/', data, this._utils.jwt()).pipe();
    }

    /*
    |-------------
    | PROFILES
    |-------------
    */

    getProfiles(){

        return this._http.get(
            this._utils.backURL + '/api/admin/profiles/', 
            this._utils.jwt()
        ).pipe();

    }

    checkProfile(profile_id, place_id, rating, img){

        return this._http.post(
            this._utils.backURL + '/api/admin/profile/' + profile_id + '/check', 
            {
                place_id: place_id,
                rating: rating,
                img: img
            }, 
            this._utils.jwt()
        ).pipe();

    }

    deleteProfile(profile_id){

        return this._http.post(
            this._utils.backURL + '/api/admin/profile/' + profile_id + '/delete', 
            {}, 
            this._utils.jwt()
        ).toPromise();

    }

    requestProfileInfo(profile_id){

        return this._http.post(
            this._utils.backURL + '/api/admin/profile/' + profile_id + '/request-info', 
            {}, 
            this._utils.jwt()
        ).toPromise();

    }

    /*
    |-------------
    | REVIEWS
    |-------------
    */

    cancelReview(review_id){
        return this._http.put(this._utils.backURL + '/api/admin/review/' + review_id + '/cancel', {}, this._utils.jwt()).pipe();
    }

    clearFailsRW(review_id, account_id){

        return this._http.post(
            `${this._utils.backURL}/api/admin/review/${review_id}/clear-fail`,
            {
                account_id: account_id
            }, 
            this._utils.jwt()
        ).toPromise();

    }

    checkReview(review_id){
        return this._http.put(this._utils.backURL + '/api/gmb/review/' + review_id + '/check', {}, this._utils.jwt()).pipe();
    }

    checkReview_assign(review_id, account_id){
        return this._http.put(this._utils.backURL + '/api/gmb/review/' + review_id + '/check', {account_id: account_id}, this._utils.jwt()).pipe();
    }

    warrantyCheckRW(review_id){
        return this._http.post(
            `${this._utils.backURL}/api/gmb/review/${review_id}/warranty-check`, 
            {}, 
            this._utils.jwt()
        ).pipe();
    }

    /*
    |-------------
    | USERS
    |-------------
    */

    activateAgency(user_id){
        return this._http.post(this._utils.backURL + '/api/admin/user/' + user_id + '/agency', {}, this._utils.jwt()).pipe();
    }

    hardBan(user_id, hard_ban){
        return this._http.post(this._utils.backURL + '/api/admin/user/' + user_id + '/hard-ban', {
            hard_ban : hard_ban
        }, this._utils.jwt()).pipe();
    }

    ban(user_id){
        return this._http.post(this._utils.backURL + '/api/admin/user/' + user_id + '/ban', {}, this._utils.jwt()).pipe();
    }
    
    unban(user_id){
        return this._http.post(this._utils.backURL + '/api/admin/user/' + user_id + '/unban', {}, this._utils.jwt()).pipe();
    }

    getOrders(){
        return this._http.get(this._utils.backURL + '/api/shop/order', this._utils.jwt()).pipe();
    }

    test(uri){
        return this._http.get(this._utils.backURL + '/api/admin/test' + uri, this._utils.jwt()).pipe();
    }

    getLog(){
        return this._http.get(this._utils.backURL + '/api/admin/log', this._utils.jwt()).pipe();
    }

    getCreditsStats(){
        return this._http.get(this._utils.backURL + '/api/admin/test/unused-credits', this._utils.jwt()).pipe();
    }

    uploadMT(user_id){
        return this._http.post(
            this._utils.backURL + '/api/admin/user/' + user_id + '/mt', 
            {}, 
            this._utils.jwt()
        ).pipe();
    }

    /*
    |-------------
    | GMB ACCOUNTS
    |-------------
    */

    getGMBAccounts() {
        return this._http.get(this._utils.backURL + '/api/admin/gmb-accounts/', this._utils.jwt()).pipe();
    }

    updateStrikes(account_id, strike){
        return this._http.post(this._utils.backURL + '/api/admin/gmb-account/' + account_id + '/strike', {strike: strike}, this._utils.jwt()).pipe();
    }

    banGMBA(gmba_id, method){
        return this._http.post(
            `${this._utils.backURL}/api/admin/gmba/${gmba_id}/${method}`, 
            {}, 
            this._utils.jwt()
        ).pipe().toPromise();
    }

    /*
    |-------------
    | SECURITY
    |-------------
    */

    banFingerprint(fp){

        return this._http.post(
            this._utils.backURL + '/api/stats/fp/ban', 
            {fp:fp}, 
            this._utils.jwt()
        ).toPromise();
        
    }
    
    unbanFingerprint(fp){

        return this._http.post(
            this._utils.backURL + '/api/stats/fp/unban', 
            {fp:fp}, 
            this._utils.jwt()
        ).toPromise();
        
    }

    getFingerprintBlacklist(){

        return this._http.get(
            this._utils.backURL + '/api/stats/fp', 
            this._utils.jwt()
        ).toPromise();

    }

    checkMultiFPS(){

        return this._http.get(
            this._utils.backURL + '/api/stats/fp/multi', 
            this._utils.jwt()
        ).toPromise();

    }

    findFingerprint(query, type){

        return this._http.post(
            this._utils.backURL + '/api/stats/fp/find', 
            {query: query, type: type},
            this._utils.jwt()
        ).toPromise();

    }


    /*
    |-------------
    | CONFIG
    |-------------
    */


    getConfig(){

        return this._http.get(
            this._utils.backURL + '/api/admin/config', 
            this._utils.jwt()
        ).toPromise();

    }


    setConfig(config){

        return this._http.post(
            this._utils.backURL + '/api/admin/config',
            {config: config},
            this._utils.jwt()
        ).toPromise();

    }

    /*
    |-------------
    | GETTERS
    |-------------
    */

    public getOrders2(size, page, filter){

        return this._getter('order', size, page, { name: 'date', value: -1 }, filter);

    }

    public getTransactions2(size, page, filter){

        return this._getter('transaction', size, page, { name: 'date', value: -1 }, filter);

    }

    public getUsers(size, page, filter, order = { name: 'creation_date', value: -1 } ){

        return this._getter('user', size, page, order, filter);

    }

    public getGMBA(size, page, filter, order = { name: '_id', value: -1 } ){

        return this._getter('gmba', size, page, order, filter);

    }

    public getGMBProfiles(size, page, filter, order = { name: '_id', value: -1 } ){

        filter.check = false;

        return this._getter('profile', size, page, order, filter);

    }

    public getGMBManualProfiles(){

        return this._getter('profile', 0, 0, { name: '_id', value: -1 }, {
            check: true, 
            _active: true
        });

    }

    public getAnswers(size, page, filter={}){

        return this._getter('answer', size, page, { name: '_id', value: -1 }, filter);

    }

    /*
    |-------------
    | PRIVATE METHODS
    |-------------
    */

    private _getter(resource_name, size, page, sort, filter){

        return this._http.post(
            `${this._utils.backURL}/api/admin/getter/${resource_name}?size=${size}&page=${page}&sort=${sort.name}&sort_order=${sort.value}`,
            filter,
            this._utils.jwt()
        ).toPromise();

    }



    
}
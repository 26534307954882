import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class ImgService {
  
    constructor(private _utils : Utils, private _http: HttpClient) { }

    uploadImg(image){

        let data = {
            image: image.split(',')[1],
            type: 'base64'
        }

        return this._http.post(this._utils.backURL + '/api/user/rw-image', data, this._utils.jwt()).pipe();
    
    }

}

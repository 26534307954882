import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AdminService } from "src/app/services/admin.service";
import { Utils } from "src/app/services/utils";
import { GoogleServices } from "src/app/services/google-service";

@Component({
    selector: 'check-review-dialog',
    templateUrl: 'check-review.html',
    styleUrls: ['./check-review.scss'],
})

/* BUY DIALOG */
export class CheckReviewDialog implements OnInit{

    public review;
    public company;
    public publisher;
    public edit;
    public lg;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CheckReviewDialog>,
        private _utils: Utils,
        private _admin: AdminService,
        private _google: GoogleServices
    ) {
        this.review = data.review;
    };

    ngOnInit(){
        //Load company
        this._admin.getUser(this.review.profile_snapshot.user_id).toPromise()
        .then(response =>{
            this.company = response['user'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar empresa", 3000);
        })

        //Load publisher
        this._admin.getUser(this.review.publisher_id).toPromise()
        .then(response =>{
            this.publisher = response['user'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar empresa", 3000);
        })
    }

    editLG(){
        if(this.edit){
            //Update gmb account
            this._admin.updateLG(this.review.account_id, this.lg).toPromise()
            .then(response =>{
                this.review.account_snapshot = response['account'];
                this._utils.showSuccess("Cuenta actualizada", 3000);
            })
            .catch(error =>{
                console.log(error);
                this._utils.showError("Error al cargar empresa", 3000);
            })
        }
        this.edit = !this.edit;
    }

    freeReview(){
        this._google.freeReview(this.review._id).toPromise()
        .then(response =>{
            this.review = response['review'];
            this._utils.showSuccess("Reseña liberada", 3000);
            this.dialogRef.close({review: response['review']});
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al liberar reseña", 3000);
        })
    }

    aproveReview(){
        this._admin.checkReview(this.review._id).toPromise()
        .then(response =>{
            this.review = response['review'];
            this._utils.showSuccess("Reseña confirmada", 3000);
            this.dialogRef.close({review: response['review']});
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al confirmar reseña", 3000);
        })
    }

}
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AdminService } from "src/app/services/admin.service";
import { Utils } from "src/app/services/utils";
import { GoogleServices } from "src/app/services/google-service";

@Component({
    selector: 'assigned-review-dialog',
    templateUrl: 'assigned-review.html',
    styleUrls: ['./assigned-review.scss'],
})

/* BUY DIALOG */
export class AssignedReviewDialog implements OnInit{

    public review;
    public edit;
    public lg;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AssignedReviewDialog>,
        private _utils: Utils,
        private _google: GoogleServices
    ) {
        this.review = data.review;
    };

    ngOnInit(){

        //Remove access to info when timer ends
        //TODO


        //Load rw
        this._google.getAssignedReview(this.review._id).toPromise()
        .then(response =>{
            this.review = response['rw'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar información", 3000);
            this.dialogRef.close(false);
        })

    }

    freeReview(){
        
        this._google.freeReview(this.review._id).toPromise()
        .then(response =>{
            this._utils.showSuccess("Reseña cancelada. Ya no está asignada a su cuenta.", 3000);
            this.dialogRef.close(true);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cancelar la reseña", 3000);
            this.dialogRef.close(false);
        })

    }

    approveReview(){

        this._google.reviewPublished(this.review._id).toPromise()
        .then(response => {
            this._utils.showSuccess("Reseña enviada para su comprobación.", 5000);
            this.dialogRef.close(true);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar reseña.", 5000);
            this.dialogRef.close(false);
        })

    }

}
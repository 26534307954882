import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

import * as Chart from 'chart.js';

@Component({
  selector: 'admin-gmba',
  templateUrl: './admin-gmba.html',
  styleUrls: ['./admin-gmba.scss'],
})

export class AdminGMBAComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    public loading;

    //FILTER
    public filter: any = {};
    private default_filter = { search: '' };

    //TABLE
    public users: MatTableDataSource<any>;
    @ViewChild('paginator') paginator: MatPaginator;
    public userColumns = ['id', 'date', 'publisher_id', 'name', 'email', 'location', 'gender', 'lg', 'verified', 'rws', 'strikes', 'actions'];
    public totalItems = 0;
    public page = 0;
    public size = 10;

    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        public _utils: Utils,
    ) {
        Object.assign(this.filter, this.default_filter);
    }

    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'gmbaccount' && !this.loaded){
                this.loaded = true;
                this.loadGMBA();
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */


    public loadGMBA(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        this._admin.getGMBA(this.size, this.page, query)
        .then(response => {
            this._loadTable(response['data'], response['total']);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar cuentas");
        })

    }

    private async _loadTable(data, total){

        let placeholder = new Array(total)

        let startItem = this.page * this.size;

        //Reset to page 0 if total is smaller
        if(startItem >= total){
            this. page = 0;
            startItem = 0;
        }

        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = data[i];
        }

        this.users = new MatTableDataSource(placeholder);
        this.users.paginator = this.paginator;

        this.loading = false;

    }


    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadGMBA(true);

    }


    private _parseFilter(){

        let query: any = {};

        //Search
        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {publisher_id: num },
                {lg: num },
                {strikes: num },

                //Texts
                {name: regex },
                {email: regex },
                {gender: regex },
                {country: regex },
                {city: regex },

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }

    public showGmbaStats = false;

    public async calcGmbaStats(){

        this.showGmbaStats = true;
        try {
            let response = await this._admin.test('/gmba-stats').toPromise();

            this['STATS_GMBA_TOTAL'] = response['count'];
                  
            // Configuración para el chart de países
            const countryLabels = Object.keys(response['countries']);
            const countryData = Object.values(response['countries']);
            const countryBackgroundColors = ['#FF0000', ...Array(countryLabels.length - 1).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16))];
            const countryConfig = this.generatePieChartConfig(countryLabels, countryData, countryBackgroundColors);
            
            // Crear chart de países
            const countryCtx = document.getElementById('countryChart') as HTMLCanvasElement;
            const countryChart = new Chart(countryCtx, countryConfig);
            
            // Configuración para el chart de ciudades
            const cityLabels = Object.keys(response['cities']);
            const cityData = Object.values(response['cities']);
            const cityBackgroundColors = Array(cityLabels.length).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16));
            const cityConfig = this.generatePieChartConfig(cityLabels, cityData, cityBackgroundColors);
            
            // Crear chart de ciudades
            const cityCtx = document.getElementById('cityChart') as HTMLCanvasElement;
            const cityChart = new Chart(cityCtx, cityConfig);
          
          } catch (error) {
            console.error('Error al procesar los datos:', error);
          }

    }

    generatePieChartConfig(labels: string[], data: any[], backgroundColors: string[]): any {
      
        return {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                data: data,
                backgroundColor: backgroundColors,
                borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 50
            }
        };

    }

    /*
    |--------------------------------------------------------------------------
    | ACTIONS
    |--------------------------------------------------------------------------
    */

    updateStrikes(strike, account){
        this._admin.updateStrikes(account._id, strike).toPromise()
        .then(response =>{
            Object.assign(account, response['account']);
            this._utils.showSuccess("Actualizada", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar cuenta", 3000);
        })
    }


    ban(account){

        let method = account.bl? 'unban' : 'ban';

        this._admin.banGMBA(account._id, method)
        .then(response => {
            this.loadGMBA(true);
            this._utils.showSuccess("Cuenta actualizada")
        })
        .catch(e=>{
            console.log(e);
            this._utils.showError("Error al actualizar cuenta")
        })

    }

}

import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ShopService } from 'src/app/services/shop.service';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';

@Component({
  selector: 'panel-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})

export class ShopComponent {

    public selectedTab;

    public services;

    constructor(
        public dialog: MatDialog,
        private _shop: ShopService,
        private _utils: Utils,
        private _user: UserServices
    ) {

        // Load services
        this._shop.getServices().toPromise()
        .then(result => {
            this.services = result['services'].sort( (a,b) =>  a.price - b.price );
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar los servicios.", 5000);
        });

    }

    changeTab(index){
        this.selectedTab = index;
    }

    openDialog(pack, type): void {
        const dialogRef = this.dialog.open(BuyDialogComponent, {
        width: 'auto',
        data:  {
            service: pack,
            type: type
        }
        });

        dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        });
    }

    getNKeys(quantity){
        return Math.floor(quantity/2);
    }

}

@Component({
    selector: 'buy-dialog',
    templateUrl: 'buy-dialog.html',
    styleUrls: ['./buy-dialog.scss']
})

/* BUY DIALOG */
export class BuyDialogComponent {

    public loading = false;
    public discount: any = {};
    public price = 0;

    public paymentMethod = "stripe";

    constructor(
        public dialogRef: MatDialogRef<BuyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _shopService : ShopService,
        private _utils: Utils,
        public _user: UserServices
    ) {
        this.price = data.service.price;
    };

    close(): void {
        this.dialogRef.close();
    }

    //Actually creates the order
    createOrder(){
        var body = { 
            service_id: this.data.service._id, 
            discount: this.discount['code'],
            pay_method: this.paymentMethod
        }
        this.loading = true;
        this._shopService.serviceCache = this.data.service;
        this._shopService.createOrder(body).subscribe(
            success =>{
                localStorage.setItem('orderCache', JSON.stringify(success['order']));
                document.location.href = success['order'].pay_url;
            },
            error =>{
                this._utils.showError("Error al crear el pedido. Pruebe de nuevo más tarde.", 5000);
                this.loading = false;
                console.log(error);
            }
        );
    }

    checkDiscount(){
        this.price = this.data.service.price;
        this.discount = {code: this.discount['code']};
        this._shopService.checkDiscount(this.discount['code']).toPromise()
        .then(response =>{
            this.discount = response['discount'];
            if(this.discount['amount']) this.price = this.price - this.discount['amount'];
            if(this.discount['percent']) this.price = this.price - (this.discount['percent']/100) * this.price;
            if(this.discount['user_id'] && this._user.sharedUser && this.discount['user_id'] != this._user.sharedUser._id){
                this._utils.showError('Código no válido', 5000); 
                this.price = this.data.service.price;
                this.discount = null;
                return;
            }
            this._utils.showSuccess('Descuento aplicado correctamente', 5000);
        })
        .catch(error =>{ console.log(error); this._utils.showError('Código no válido', 5000)})
    }

}

/**
 * Created by marco on 18/06/2017.
 */
import { Injectable } from '@angular/core';

import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';
import { Router } from '@angular/router';
import { resolve } from 'url';

@Injectable()
export class UserServices {

    public sharedUser;
    public sharedProfiles;

    constructor(private _http: HttpClient, private _utils: Utils, private _router: Router) {}

    //Check login
    isLogged() {

        if (
            localStorage.getItem('currentUser') && 
            localStorage.getItem('currentUser').includes('token')
        ){
            return true;
        }

        return false;
    }

    logout(){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUser');
        this.sharedUser = null;
    }

    private _forceLogout(){
        this.logout();
        this._router.navigate(['/es/login']);
    }

    getUser(){
        return this._http.get(this._utils.backURL + '/api/user/current', this._utils.jwt()).pipe()
        .toPromise().catch(error => {
            //TODO check if jwt expired force logout
            if(error.status == 401 && error.error.message == "jwt expired") this._forceLogout();
            throw error;
        });
    }

    registerGoogle(googleCode, state) {
        return this._http.get(this._utils.backURL + '/api/user/auth/google/callback/?code=' + googleCode + '&state=' + state, this._utils.jwt()).pipe();
    }

    loginGoogle(googleCode) {
        return this._http.get(this._utils.backURL + '/api/user/auth/google/callback/?code=' + googleCode, this._utils.jwt()).pipe();
    }

    registerFacebook(facebookCode, state) {
        return this._http.get(this._utils.backURL + '/api/user/auth/facebook/callback/?code=' + facebookCode + '&state=' + state, this._utils.jwt()).pipe();
    }

    loginFacebook(facebookCode) {
        return this._http.get(this._utils.backURL + '/api/user/auth/facebook/callback/?code=' + facebookCode, this._utils.jwt()).pipe();
    }

    update(user){
        return this._http.put(this._utils.backURL + '/api/user', user, this._utils.jwt()).pipe();
    }

    getRegConfig(){
        return this._http.get(
            this._utils.backURL + '/api/user/reg-config'
        ).toPromise();
    }

    create(user: User){
        return this._http.post(this._utils.backURL + '/api/user', user, this._utils.jwt()).pipe();
    }

    getOrders(){
        return this._http.get(this._utils.backURL + '/api/user/orders', this._utils.jwt()).pipe();
    }

    getOrder(order_id){
        return this._http.get(this._utils.backURL + '/api/user/order/' + order_id, this._utils.jwt()).toPromise();
    }

    searchOrders(page = 0, size = 5, filter = {}){
        return this._http.post(
            `${this._utils.backURL}/api/user/orders/search?page=${page}&size=${size}`,
            filter, 
            this._utils.jwt()
        ).pipe().toPromise();
    }

    searchSubs(filter = {}){
        return this._http.post(
            `${this._utils.backURL}/api/user/subs/search`,
            filter, 
            this._utils.jwt()
        ).pipe().toPromise();
    }

    getSubs(){
        return this._http.get(this._utils.backURL + '/api/user/subs', this._utils.jwt()).pipe();
    }

    getSub(sub_id){
        return this._http.get(this._utils.backURL + '/api/user/subs/' + sub_id, this._utils.jwt()).pipe();
    }

    updateSub(sub){
        return this._http.put(this._utils.backURL + '/api/user/subs', {sub: sub}, this._utils.jwt()).pipe();
    }
    
    getProfiles(){
        return this._http.get(this._utils.backURL + '/api/user/profiles', this._utils.jwt()).pipe();
    }

    getProfile(profile_id){
        return this._http.get(this._utils.backURL + '/api/user/profiles/' + profile_id, this._utils.jwt()).pipe();
    }

    createProfile(profile){
        this._utils.pixelEvent("New_Profile", profile);
        return this._http.post(this._utils.backURL + '/api/user/profiles', profile, this._utils.jwt()).pipe();
    }
    
    createProfileManual(profile){
        return this._http.post(this._utils.backURL + '/api/user/profiles?manual=true', profile, this._utils.jwt()).pipe();
    }

    getLocalUser(){
        return this.sharedUser;
    }

    /**
     * Updates the shared user data with the arguments given 
     */
    setLocalUser(user){
        //Save token
        if(this.isLogged()){
        var token = JSON.parse(localStorage.getItem("currentUser")).token;
        user.token = token;
        }
        //Update user
        this.sharedUser = user;
        localStorage.setItem('currentUser', JSON.stringify(user));
        //Set cookie for home
        document.cookie = 'token=' + user.token + '; domain=imperatool.com';
        //Update Chat
        this._utils.loginTawk(user.name, user.email)
        var data = {
        id: user._id,
        credits: user.credits,
        type: user.type
        };
        this._utils.setTawkTo(data);
    }

    /**
     * Updates the shared user profiles with the arguments given 
     */
    setLocalProfiles(profiles){
        //Update profiles
        this.sharedProfiles = profiles;
    }

    /**
     * Get the user data from db and saves it to cache
     */
    loadUser(){
        return new Promise((resolve, reject)=>{
            if(!this.isLogged())return reject("No logeado");
            this.getUser()
            .then(user => {

                //Check Ban
                if(user['timeout'] || user['hard_ban']){
                    reject("Unknown error")
                    this._forceLogout();
                    return;
                }

                //Save token
                var token = JSON.parse(localStorage.getItem("currentUser")).token;
                user['token'] = token;
                
                //Set Local User
                this.sharedUser = user;
                localStorage.setItem('currentUser', JSON.stringify(this.sharedUser));

                //TODO: CHECK SECURITY INFO SAVED ON LOCALSTORAGE

                //Set Chat data
                this._utils.loginTawk(this.sharedUser.name, this.sharedUser.email)
                this._utils.setTawkTo({credits: user['credits']});

                //Return user
                resolve(user);
            })
            .catch(error =>{ reject(error); });
        })
    }

    /**
     * Get the user profiles from db and saves them to cache
     */
    loadProfiles(){
        return new Promise((resolve, reject)=>{
            if(!this.isLogged())return reject("No logeado");
            this.getProfiles().toPromise()
            .then(response =>{
                this.sharedProfiles = response['profiles'];
                resolve(this.sharedProfiles);
            })
            .catch(error =>{ reject(error); })
        });
    }

    getRefs(){
        return this._http.get(this._utils.backURL + '/api/user/refs', this._utils.jwt()).pipe();
    }

    //Admin
    getUsers(){
        return this._http.get(this._utils.backURL + '/api/user/', this._utils.jwt()).pipe();
    }
    
    //Partner Request
    partnerRequest(){
        return this._http.post(this._utils.backURL + '/api/user/partner', {}, this._utils.jwt()).pipe();
    }
    
    getRefPayments(){
        return this._http.get(this._utils.backURL + '/api/shop/ref-pay', this._utils.jwt()).pipe();
    }
    
    getTransactions(){
        return this._http.get(this._utils.backURL + '/api/shop/transactions', this._utils.jwt()).pipe();
    }
    
    requestPayment(){
        return this._http.post(this._utils.backURL + '/api/user/credits/pay', {}, this._utils.jwt()).pipe();
    }

    //Recover Password
    sendRecoverEmail(email){
        return this._http.post(this._utils.backURL + '/api/user/recover', {email: email}, this._utils.jwt()).pipe();
    }

    //Recover Password
    recoverPassword(id, hash, password){
        let data = {
            id: id,
            hash: hash,
            password: password
        };
        return this._http.post(this._utils.backURL + '/api/user/recover-end', data, this._utils.jwt()).pipe();
    }

}

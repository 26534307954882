import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnswersServices } from 'src/app/services/answers.services';
import { UserServices } from 'src/app/services/user.service';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'panel-answers',
    templateUrl: './panel-answers.component.html',
    styleUrls: ['./panel-answers.component.scss']
})

export class PanelAnswersComponent implements OnInit {

    public showSlider;
    constructor(
        private _user: UserServices, 
        private _utils: Utils, 
        private router: Router,
        private _asnwers: AnswersServices
    ) { }

    ngOnInit() {

        this.showSlider = false;

        this._asnwers.showTutorial()
        .then((response: any) => this.showSlider = response.show)
        .catch(error => console.log(error) );
        
    }



    //Public methods
    public toggleSlider(): void {
        this.showSlider = !this.showSlider;
    }
}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class CompanyService {

    constructor(private _http: HttpClient, private _utils: Utils) {}

    /*
    |--------------------------------------------------------------------------
    | PROFILE GROUPS
    |--------------------------------------------------------------------------
    */

    //Add new profile group: (group) => (groups)
    postGroup(group_name){
        return this._http.post(this._utils.backURL + '/api/user/group', {group: group_name}, this._utils.jwt()).toPromise();
    }

    //Remove existing profile group: (group) => (groups)
    deleteGroup(group_name){
        return this._http.post(this._utils.backURL + '/api/user/group/delete', {group: group_name}, this._utils.jwt()).toPromise();
    }

    //Add profile to group (profile_id, group) => ()
    addProfileGroup(profile_id, group_name){
        return this._http.post(this._utils.backURL + '/api/user/group/add-profile', {profile_id: profile_id, group: group_name}, this._utils.jwt()).toPromise();
    }

    //Remove profile from group (profile_id, group) => ()
    removeProfileGroup(profile_id, group_name){
        return this._http.post(this._utils.backURL + '/api/user/group/remove-profile', {profile_id: profile_id, group: group_name}, this._utils.jwt()).toPromise();
    }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class PublisherService {
  
  constructor(private _http: HttpClient, private _utils: Utils) { }

  getReviews(){
    return this._http.get(this._utils.backURL + '/api/gmb/review/', this._utils.jwt()).pipe();
  }

  getAccounts(){
    return this._http.get(this._utils.backURL + '/api/gmb/account/', this._utils.jwt()).pipe();
  }

  addAccount(account){
    return this._http.post(this._utils.backURL + '/api/gmb/account/', account, this._utils.jwt()).pipe();
  }

}

import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import {Utils} from '../../../../../services/utils';
import {UserServices} from '../../../../../services/user.service';
import {MatDialog} from '@angular/material';
import { SeoServices } from 'src/app/services/seo.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

declare var $: any;

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    
}

@Component({
  selector: 'tools-tracking',
  templateUrl: './tools-tracking.component.html',
  styleUrls: ['./tools-tracking.component.scss']
})
export class ToolsTrackingComponent implements OnInit {

    public newKey;
    public newKeyShow;

    public user;

    public profiles;
    public profileSelected;

    constructor(
        private _utils: Utils,
        private _user: UserServices,
        private _seo: SeoServices
    ) {}

    ngOnInit() {

        //Load profiles
        this._user.loadProfiles()
        .then(profiles => {
            this.profiles = profiles;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });

        //Load keywords
        this._seo.getKeywords().toPromise()
        .then(response => {
            this.user = response['user'];
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar datos de usuario", 5000);
        });

        $('.mat-sidenav-content')[0].style.backgroundColor = "#eee";

    }

    toggleKey(){
        this.newKeyShow = !this.newKeyShow;
    }

    addKey(){
        this.newKey;
        this._seo.addKeyword(this.newKey, this.profileSelected._id).toPromise()
        .then(response =>{
            this.user = response['user'];
            this.reload();
            this.newKey = '';
            this.profileSelected = undefined;
            this.toggleKey();
            this._utils.showSuccess("Keyword añadida con éxito.", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al añadir keyword.", 4000);
        })
    }

    removeKey(key){
        this._seo.deleteKeyword(key).toPromise()
        .then(response =>{
            this.user = response['user'];
            this.reload();
            this._utils.showSuccess("Keyword eliminada con éxito.", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al eliminar keyword.", 4000);
        })
    }
    
    reload(){
        if(document.getElementById('serpbook'))document.getElementById('serpbook')['src'] += '';
    }

}

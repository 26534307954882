import { Component, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'settings-affiliates',
  templateUrl: './settings-affiliates.html',
  styleUrls: ['./settings-affiliates.scss'],
})

export class SettingsAffiliatesComponent implements OnInit{
    
    user;
    ref_columns = ['id', 'time', 'revenue'];
    active_refs;
    ref_level;

    @ViewChild('refPag') 
    refPag: MatPaginator;
    refs: MatTableDataSource<any>;

    @ViewChild('transPag') 
    transPag: MatPaginator;
    transactions: MatTableDataSource<any>;
    transactions_columns = ['date', 'user_id', 'quantity'];

    constructor( 
        private _user: UserServices, 
        private _utils: Utils, 
    ) {
        this.user = _user.sharedUser;
    }

    ngOnInit(){
        //Load refs
        this._user.getRefs().toPromise()
        .then(response =>{
            this.refs = new MatTableDataSource(response['refs']);
            this.refs.paginator = this.refPag;
            this._getRefLevel();
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar referidos", 3000);
        })

        //Load transactions
        this._user.getRefPayments().toPromise()
        .then(response =>{
            this.transactions = new MatTableDataSource(response['regs']);
            this.transactions.paginator = this.transPag;
            this.user.ref_credits = this._getTotalCredits();
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar historial de pagos", 3000);
        })
    }

    getRefDays(ref){
        var ms_now = new Date().getTime();
        var ms_date = new Date(ref.creation_date).getTime();
        var days = Math.abs(ms_now - ms_date)/(1000 * 60 * 60 * 24);
        days = this._utils.ref_days - days;
        if(days < 0)return 0;
        return Math.floor(days);
    }

    _getTotalCredits(){
        var credits = 0;
        this.transactions.data.forEach(reg => {
            credits += reg.quantity;
        });
        return credits;
    }

    _getRefLevel(){
        //Calc active refs
        var c = 0;
        this.refs.data.forEach(ref => {
            if(this.getRefDays(ref) > 0)c++;
        });
        this.active_refs = c;
        //Calc level
        for (let i = 0; i < this._utils.ref_levels.length; i++) {
            const level = this._utils.ref_levels[i];
            if(c < level.limit_accounts){
                this.ref_level = level;
                return;
            }
        }
        if(!this.ref_level) this.ref_level = this._utils.ref_levels[this._utils.ref_levels.length-1];
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class GoogleServices {
  
    constructor(private _http: HttpClient, private _utils: Utils) { }

    //Search new company profile
    searchGoogle(keywords : String){
        this._utils.pixelEvent("Search", {search_string: keywords});
        return this._http.get(this._utils.backURL + '/api/social/google/profile/' + keywords, this._utils.jwt()).pipe();
    }

    newReview(review){
        return this._http.post(this._utils.backURL + '/api/gmb/review', review, this._utils.jwt()).pipe();
    }

    updateReview(review){
        return this._http.put(this._utils.backURL + '/api/gmb/review/' + review._id, review, this._utils.jwt()).pipe();
    }

    deleteReview(review_id){
        return this._http.delete(this._utils.backURL + '/api/gmb/review/' + review_id, this._utils.jwt()).pipe();
    }

    getReviews(profile_id){
        return this._http.get(this._utils.backURL + '/api/gmb/review/profile/' + profile_id, this._utils.jwt()).pipe();
    }

    getAssignedReview(rw_id){
        return this._http.get(this._utils.backURL + '/api/gmb/assigned-review/' + rw_id, this._utils.jwt()).pipe();
    }
    
    searchReview(profile_id, filter: Array<Number>){
        var query = '?filter=' + filter.toString();
        return this._http.get(this._utils.backURL + '/api/gmb/review/search/' + profile_id + query, this._utils.jwt()).pipe();
    }

    freeReview(review_id){
        return this._http.put(this._utils.backURL + '/api/gmb/review/' + review_id + '/free', {},this._utils.jwt()).pipe();
    }

    reviewPublished(review_id){
        return this._http.put(this._utils.backURL + '/api/gmb/review/' + review_id + '/publish', {},this._utils.jwt()).pipe();
    }

    reviewReported(review_id){
        return this._http.post(this._utils.backURL + '/api/gmb/review/' + review_id + '/report', {},this._utils.jwt()).pipe();
    }
    
    deleteProfile(profile_id){
        return this._http.put(this._utils.backURL + '/api/gmb/profile/' + profile_id + '/delete', {},this._utils.jwt()).pipe();
    }

    //SEO Tools (Contact Client)
    getReport(profile_id){
        return this._http.get(this._utils.backURL + '/api/social/google/profile/' + profile_id + '/report', this._utils.jwt()).pipe();
    }

    getProfile(profile_id){
        return this._http.get(this._utils.backURL + '/api/gmb/profile/' + profile_id + '/stats', this._utils.jwt()).pipe();
    }

    getAvailableRws(){
        return this._http.get(this._utils.backURL + '/api/gmb/review/available', this._utils.jwt()).pipe();
    }

    sendGMBVCode(account_id){
        return this._http.post(this._utils.backURL + '/api/gmb/account/' + account_id + '/send-vcode', {}, this._utils.jwt()).pipe();
    }

    verifyGMB(account_id, v_code){
        return this._http.post(this._utils.backURL + '/api/gmb/account/' + account_id + '/verify', {v_code: v_code}, this._utils.jwt()).pipe();
    }

    editGMBAccount(account){
        return this._http.put(this._utils.backURL + '/api/gmb/account/' + account._id, {account: account }, this._utils.jwt()).pipe();
    }

    allowFreeReview(profile_id){
        return this._http.get(
            `${this._utils.backURL}/api/gmb/profile/${profile_id}/promo-0-check`,
            this._utils.jwt()
        ).toPromise();
    }

}

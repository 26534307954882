import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { CheckReviewDialog } from '../../dialogs/check-review/check-review';
import { AssignReviewDialog } from '../../dialogs/assign-review/assign-review';
import { GoogleServices } from 'src/app/services/google-service';

import * as Chart from 'chart.js';

@Component({
  selector: 'admin-reviews',
  templateUrl: './admin-reviews.html',
  styleUrls: ['./admin-reviews.scss'],
})

export class AdminReviewsComponent implements OnInit{

    @Input('sharedUsers') users;

    //Status
    public loading;
    
    //RWs
    public reviewColumns = ['id', 'date', 'date_published', 'company', 'stars', 'img', 'text', "extras", "sex", "city", "profile", "views", "state", "warranty", "actions"];
    public review_status;
    public totalItems = 0;
    public page = 0;
    public size = 10;
    public reviews: MatTableDataSource<any>;
    @ViewChild('reviewPag') reviewPag: MatPaginator;

    //Filter
    public filter: any = {};
    private default_filter = { today: false, status: 'any', search: '' };

    //Sort
    public sort: any = {date:-1};
    public default_sort: any = {date:-1};


    constructor(
        private _admin: AdminService,
        private _utils: Utils,
        private _google: GoogleServices,
        private changeDetector: ChangeDetectorRef,
        private review_dialog: MatDialog,
        private assign_dialog: MatDialog
    ) {

        //Load rw status reference
        this.review_status = _utils.review_status;

    }

    ngOnInit(){

        //Load rws
        this.loading = true;
        Object.assign(this.filter, this.default_filter);
        this.loadReviews();

    }

    public loadReviews(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        this._admin.getReviewsPag(this.size, this.page, {
            query: query,
            sort: this.sort
        }).toPromise()
        .then(result =>{
            this._loadTable(result['reviews'], result['total']);
            this.loading = false;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar reseñas", 3000);
        })

    }


    private _loadTable(reviews, total){

        let placeholder = new Array(total)

        const startItem = this.page * this.size;
        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = reviews[i];
        }

        this.reviews = new MatTableDataSource(placeholder);
        this.reviews.paginator = this.reviewPag;

    }


    private _parseFilter(){

        let query: any = {};

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0,0,0,0);

        if(this.filter.today) query.date = { $lt: tomorrow };
        if(this.filter.status !== 'any') query.status = this.filter.status;
        if(this.filter.fail) query['failed_publishers.0'] = { $exists: true };
        if(this.filter.rewritten) query['rewritten.0'] = { $exists: true };
        if(this.filter.lg) query['extras.lg.value'] = true;
        if(this.filter.ratingOnly) query.text = { $exists: false };
        if(this.filter.reported) {
            query.report = true;
            this.sort = { wcheck_date: -1 };
        }else{ this.sort = this.default_sort }
        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {profile_id: num },
                {'profile_snapshot.user_id': num },
                {publisher_id: num },
                {account_id: num },
                {'view_publishers._id': num },
                {'view_publishers.publisher_id': num },
                {'failed_publishers._id': num },
                {'failed_publishers.publisher_id': num },

                //Texts
                {'profile_snapshot.name': regex },
                {text: regex },
                {country: regex },
                {city: regex },
                {'account_snapshot.name': regex },
                {'view_publishers.name': regex },
                {'view_publishers.email': regex },
                {'failed_publishers.name': regex },
                {'failed_publishers.email': regex },

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }


    public isFailer(review, account){

        return review.failed_publishers.some( publisher => publisher._id == account._id )

    }


    public getUser(id){

        for (const user of this.users) {
            if(user._id == id) return "(" + id + ") " + user.name + "\n<" + user.email + ">";
        }

    }


    pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadReviews(true);

    }

    public showRwStats = false;

    public async calcRwStats(){

        this.showRwStats = true;
        try {
            let response = await this._admin.test('/rw-stats').toPromise();

            this['STATS_RW_TOTAL'] = response['count'];
          
            // Configuración para el chart de países
            const countryLabels = Object.keys(response['countries']);
            const countryData = Object.values(response['countries']);
            const countryBackgroundColors = ['#FF0000', ...Array(countryLabels.length - 1).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16))];
            const countryConfig = this._generatePieChartConfig(countryLabels, countryData, countryBackgroundColors);
          
            // Crear chart de países
            const countryCtx = document.getElementById('countryChart') as HTMLCanvasElement;
            const countryChart = new Chart(countryCtx, countryConfig);
          
            // Configuración para el chart de ciudades
            const cityLabels = Object.keys(response['cities']);
            const cityData = Object.values(response['cities']);
            const cityBackgroundColors = Array(cityLabels.length).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16));
            const cityConfig = this._generatePieChartConfig(cityLabels, cityData, cityBackgroundColors);
          
            // Crear chart de ciudades
            const cityCtx = document.getElementById('cityChart') as HTMLCanvasElement;
            const cityChart = new Chart(cityCtx, cityConfig);
          
          } catch (error) {
            console.error('Error al procesar los datos:', error);
          }

    }

    private _generatePieChartConfig(labels: string[], data, backgroundColors: string[]): any {
        return {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                data: data,
                backgroundColor: backgroundColors,
                borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 50
            }
        };
    }


    /*
    |--------------------------------------------------------------------------
    | ACTIONS
    |--------------------------------------------------------------------------
    */
   
   
    public checkReview(review){
       
       const dialogRef = this.review_dialog.open(CheckReviewDialog, {
            width: 'auto',
            data:  {review: review}
        });
        
        dialogRef.afterClosed().toPromise()
        .then(result => {
            if(result && result['review']){
                this.loadReviews(true);
                this._utils.showSuccess("Reseña comprobada", 3000);
            }
        });
        
    }
    
    
    public assignReview(review){
            
        const dialogRef = this.assign_dialog.open(AssignReviewDialog, {
            width: 'auto',
            data:  {review: review}
        });
    
        dialogRef.afterClosed().toPromise()
        .then(result => {
            if(result && result['review']){
                this.loadReviews(true);
            }
        });

    }
    

    public freeReview(review){

        this._google.freeReview(review._id).toPromise()
        .then(response =>{
            if(response && response['review']){
                this.loadReviews(true);
                this._utils.showSuccess("Reseña liberada", 3000);
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al liberar reseña", 3000);
        })

    }


    public cancelReview(review){

        this._admin.cancelReview(review._id).toPromise()
        .then(response =>{
            if(response && response['review']){
                this.loadReviews(true);
                this._utils.showSuccess("Reseña cancelada", 3000);
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cancelar reseña", 3000);
        })

    }


    public warrantyCheck(review){
        this._admin.warrantyCheckRW(review._id).toPromise()
        .then(response =>{
            if(response && response['rw']){
                this.loadReviews(true);
                this._utils.showSuccess("Reseña actualizada", 3000);
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar reseña", 3000);
        })
    }


    public clearFail(review, account){

        this._admin.clearFailsRW(review._id, account._id)
        .then(response =>{
            if(response && response['review']){
                this.loadReviews(true);
                this._utils.showSuccess("Reseña actualizada", 3000);
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar reseña", 3000);
        })

    }

    /*
    |--------------------------------------------------------------------------
    | SORT
    |--------------------------------------------------------------------------
    */

    public sortBy(by){

        let sort2 = {};

        switch(by){

            case 'published': sort2 = {date_published: -1};
            break;

            default: sort2 = this.default_sort;
            break;

        }

        if(this.sort !== sort2) this.sort = sort2;
        else this.sort = this.default_sort;

        this.loadReviews();

    }


}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-subs',
  templateUrl: './admin-subs.html',
  styleUrls: ['./admin-subs.scss'],
})

export class AdminSubsComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    @ViewChild('subsPag')
    subsPag: MatPaginator;
    subs: MatTableDataSource<any>;
    subsColumns = ['id', 'last_paid', 'date', 'order', 'buyer', 'profiles', 'rw_monthly', 'rw_published', 'rw_total', 'status', 'actions'];
    sub_status;

    users;
    loading;

    default_filter = { status: 'any', search: '' };
    filter: any = {};

    subs_bulk;

    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _utils: Utils, 
    ) {
        this.sub_status = _utils.sub_status;
        Object.assign(this.filter, this.default_filter);
    }

    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'sub' && !this.loaded){
                this.loaded = true;
                this.loadUsers();
            }
        });
    }
    
    loadUsers(){
        this.loading = true;
        this._user.getUsers().toPromise()
        .then(response =>{
            this.users = response['users'];
            this.loadSubs();
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar usuarios", 3000);
        })
    }

    loadSubs(){
        this._admin.getSubscriptions().toPromise()
        .then(response =>{
            var subs = response['subs'];
            subs.forEach(sub => {
                sub.user = this.findUser(sub.buyer_id);        
            });
            this.subs_bulk = subs;
            this.subs = new MatTableDataSource(this.subs_bulk);
            this.executeFilter();
        })
        .catch(error =>{
          console.log(error);
          this._utils.showError("Error al cargar subs", 3000);
        })
    }

    findUser(user_id){

        for (let index = 0; index < this.users.length; index++) {
            const user = this.users[index];
            if(user._id == user_id) return user;
        }

    }

    changeSubStatus(sub, status){
        this._admin.updateSub(sub._id, status).toPromise()
        .then(response=>{
            this._utils.showSuccess("Subscripción actualizada correctamente.", 5000);
            this.loadSubs();
        })
        .catch(error=>{
            console.log(error);
            this._utils.showError("Error al actualizar sub", 3000);
        })
    }
    
    renewSub(sub){
        this._admin.renewSub(sub._id).toPromise()
        .then(response=>{
            this._utils.showSuccess("Subscripción renovada correctamente.", 5000);
            this.loadSubs();
        })
        .catch(error=>{
            console.log(error);
            this._utils.showError("Error al renovar sub", 3000);
        })
    }

    downloadBill(sub){
        this._admin.billSub(sub._id).toPromise()
        .then(response =>{
            sub.billed = true;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar pedido", 3000);
        })
    }

    public sendInvoiceEmail(sub){

        this._admin.sendInvoiceEmail(sub.order_id)
        .then( res => {
            this._utils.showSuccess("Envío realizado");
            sub.invoice_sent = true; 
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al enviar la factura")
        })

    }

    executeFilter(){
        this.loading = true;

        let filtered_list = [];
        for(let sub of this.subs_bulk){

            //Status filter
            if(this.filter.status != 'any'){
                if(sub.status != this.filter.status) continue;
            }

            if(this.filter.search){
                let fields = 
                    sub._id + ', ' + 
                    this._utils.parseDate(sub.start_date) + ', ' + 
                    this._utils.parseDate(sub.last_paid) + ', ' + 
                    sub.order_id + ', ' +
                    sub.rw_monthly;
                if(sub.user){
                    fields += sub.user.name + ', ' + sub.user._id;
                }
                    
                for (const profile of sub.profiles) {
                    fields += ', ' + profile.name;
                }

                if(!fields.toLowerCase().includes(this.filter.search.toLowerCase())) continue;
            }

            filtered_list.push(sub);

        }

        this.subs = new MatTableDataSource(filtered_list);
        this.subs.paginator = this.subsPag;
        this.loading = false;
    }

    getBilling(user){

        return `${user.cif}
        ${user.billName}
        ${user.address}
        ${user.cp} ${user.city}
        ${user.country}`

    }

    public downloadInvoice(order_id){

        document.body.style.cursor = 'progress';

        this._admin.getInvoicePDF(order_id)
        .then( (res: any) =>{
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("No es posible descargar esta factura")
        })
        .finally(()=>{
            document.body.style.cursor = 'default';
        })

    }

}

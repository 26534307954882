import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { UserServices } from 'src/app/services/user.service';

@Component({
  selector: 'admin-config',
  templateUrl: './config.html',
  styleUrls: ['./config.scss'],
})

export class AdminConfigComponent implements OnInit{

    //Content
    public config;

    constructor( 
        private _admin: AdminService,
        private _utils: Utils,
    ) {
        
    }

    ngOnInit(){

        //Load Config params
        this._loadConfig();

    }

    _loadConfig(){

        this._admin.getConfig()
        .then(server_config => {
            
            this.config = server_config;

        })
        .catch(e =>{
            console.error(e);
            this._utils.showError("Config load failed. Check log.");
        })

    }

    update(){

        this._admin.setConfig(this.config)
        .then(server_config => {
            
            this.config = server_config;

            this._utils.showSuccess("Config updated.");

        })
        .catch(e =>{
            console.error(e);
            this._utils.showError("Config updated failed. Check log.");
        })

    }


}

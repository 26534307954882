import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserServices } from 'src/app/services/user.service';
import { Utils } from 'src/app/services/utils';
import { MatSidenav } from '@angular/material';

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit{
    
    @ViewChild('sidenav')
    sidenav : MatSidenav;

  public account_type = {
    COMPANY: "company",
    PUBLISHER: "publisher",
    MULTI: "both"
  }

  public content: string;
  public url;
  
  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthenticationService,
    public _user: UserServices,
    public _utils: Utils
  ){}


ngOnInit(){

    //If not logged redirect
    if(!this._user.isLogged()){
        //Shop not logged
        if(window.location.pathname.includes('shop')){
            this._user.sharedUser = null;
            this.content = this.route.snapshot.data['content'];
            return;
        }
        return this._router.navigate(['/es/login']);
    }

    //Load active link depending on user type
    this.content = this.route.snapshot.data['content'];
    if(this.route.url['value'][0]) this.url = this.route.url['value'][0].path;
    else this.url = 'default';

    //Load profiles
    this.loadProfiles();
}

  logout(){
    this._auth.logout();
  }

  loadProfiles(){
    if(this._user.sharedUser && this._user.sharedUser.type == this.account_type.PUBLISHER)return;
    if(!this._user.sharedProfiles){
      this._user.loadProfiles()
      .catch(error => {
        console.log(error);
        this._utils.showError("Error al cargar perfiles", 5000);
      });
    }
  }

}

/**
 * Created by marco on 18/06/2017.
 */
import { Injectable } from '@angular/core';
import { Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserServices } from './user.service';
import { Utils } from './utils';

@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient, private _user: UserServices, private _utils: Utils) {}

  login(email: string, password: string): Observable<boolean> {

    let headers = new HttpHeaders({'Content-Type' : 'application/json'});

    var auth = JSON.stringify( { email: email, password: password } );

    return this.http.post(this._utils.backURL + '/api/user/login', auth, {headers: headers}).pipe(map(response => {

        //Check Hard Ban (an error should be thrown before, but just in case)
        if(response['timeout']) return false;

      if(response['token']){
        //Loggin succesful
        this._user.setLocalUser(response);
        return true;
      } 
      else{
        //Wrong login
        console.log(response);
        return false;
      }

    }));
  }

  logout(): void {
    this._user.logout();
  }

  isLogged(): boolean{
    var user = JSON.parse(localStorage.getItem('currentUser'));
    if(user != null && user['token'])return true;
    else return false;
  }
}

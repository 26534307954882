import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFound404Component } from './components/not-found/not-found404.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminComponent } from './components/admin/admin.component';
import { PaymentComponent } from './components/dashboard/shop/payment/payment.component';
import { RecoverPasswordComponent } from './components/recover/recover.component';

const routes: Routes = [

  { path: 'yolo', component: AdminComponent },
  { path: '', redirectTo: 'es' , pathMatch: 'full'},
  { path: ':language',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'register', component: RegisterComponent },
      { path: 'recover', component: RecoverPasswordComponent },
      { path: 'login', component: LoginComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'dashboard',
        children: [
          { path: '', component: DashboardComponent, data: { content: 'default' } },
          { path: 'publisher', component: DashboardComponent, data: { content: 'publisher' } },
          { path: 'profiles', component: DashboardComponent, data: { content: 'profiles' } },
          { path: 'profile/:id', component: DashboardComponent, data: { content: 'profile-detail' } },
          { path: 'new-profile', component: DashboardComponent, data: { content: 'new-profile' } },
          { path: 'orders', component: DashboardComponent, data: { content: 'orders' } },
          { path: 'publish', component: DashboardComponent, data: { content: 'publish' } },
          { path: 'shop', component: DashboardComponent, data: { content: 'shop' } },
          { path: 'settings', component: DashboardComponent, data: { content: 'settings' } },
          { path: 'tools', component: DashboardComponent, data: { content: 'tools' } },
          { path: 'ai-answers', component: DashboardComponent, data: { content: 'ai-answers' } },
        ]
      }
    ]
  },
  { path: '**', component: NotFound404Component }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';

import { Utils } from '../../../../services/utils';
import { PublisherService } from 'src/app/services/publisher.service';
import { GoogleServices } from 'src/app/services/google-service';
import { MatDialog, MatStepper } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { ConfirmPublicationDialog } from '../../../dialogs/confirm-publication/confirm-publication';

@Component({
  selector: 'panel-publish',
  templateUrl: 'panel-publish.component.html',
  styleUrls: ['./panel-publish.component.scss'],
})

export class PanelPublishComponent implements OnInit {

    @ViewChild('stepper') stepper: MatStepper;

  private reviewTime = 60;
  private reviewCountDown = 0;

  public empty;
  public timeout;

  public accountSelected;
  private filter = [];

  public accounts = [];
  public nAcc = 0;

  public review;

  public privacy = false;

  public loading;
  public n_rw;

  private lastRefresh;
  
  constructor(
    private _utils: Utils,
    private _publisher: PublisherService,
    private _google: GoogleServices,
    public _user: UserServices,
    private _confirmDialog: MatDialog,
  ){}

    ngOnInit() {
        this._loadAccounts();
        this._loadUser();
    }

    //Check if the publisher is available for publish
    ifVerified(){

        //Load user data
        let user = this._user.getLocalUser();

        //Apply for users created later than X
        let limit_date = new Date('2050-01-01T00:00:00');

        //Check user cif
        if(!user.cif && new Date(user.creation_date) > limit_date) return false;
        else return true;

    }

    async _loadUser(){

        await this._user.loadUser();

        this._publisher.getAccounts().toPromise().then(response => this.nAcc = response['accounts'].length)

    }

    public refresh(){

        let now = new Date().getTime();

        if(this.lastRefresh && (now - this.lastRefresh) > 1000 * 60 * 3) this._loadAccounts();
        else this._utils.showError("Debe esperar unos minutos entre cada comprobación.")

    }

    _loadAccounts(){

        this.lastRefresh = new Date().getTime();

        this.loading = true;
        this.accounts = [];
        
        this._google.getAvailableRws().toPromise()
        .then(response =>{
            this.n_rw = response['count'];
            this.accounts = response['accounts'];
            if(this.accounts.length >= 1) this.accountSelected = this.accounts[0];
            this.loading = false;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al comprobar reseñas disponibles", 3000);
            this.loading = false;
        })

    }

  selectAccount(account){
    this.accountSelected = account;
  }

  searchReview(){

    //Check privacy
    if(!this.privacy) return this._utils.showError("Debe aceptar el acuerdo de privacidad.", 5000);

    this._google.searchReview(this.accountSelected._id, this.filter).toPromise()
    .then(response =>{
      this.review = response['review'];
      if(!this.review)this.empty = true;
      else{
        this.empty = false;
        this.timeout = false;
        this.reviewTimeout(this.review._id);

        if(this.review.img){
            if(this.review.img.indexOf('data')>-1) this.review.img_blob = this.review.img;
            else
                this._utils.img2data(this.review.img)
                .then(blob =>{this.review.img_blob = blob})
                .catch(error =>{
                    console.log(error)
                    this._utils.showError("Error al cargar imagen. Reseña liberada.", 5000);
                    this.clearReview();
                })
        }

      } 
    })
    .catch(error =>{
      console.log(error);
      this._utils.showError("Error al buscar reseñas.", 5000);
    })
  }

  clearReview(){
    this.filter = [];
    if(this.review){
      this._google.freeReview(this.review._id).toPromise()
      .then(response =>{
        this.review = null;
      })
      .catch(error =>{
        console.log(error);
        this._utils.showError("Error al rechazar reseña.", 5000);
      })
    }
  }

  addToFilter(){
    this.filter = this.filter.concat([this.review['_id']]);
    this._google.freeReview(this.review._id).toPromise()
    .then(response =>{
      this.review = null;
      this.searchReview();
    })
    .catch(error =>{
      console.log(error);
      this._utils.showError("Error al rechazar reseña.", 5000);
    })
  }

    reviewPublished() {


        //TODO confirm data
        const dialogRef = this._confirmDialog.open(ConfirmPublicationDialog, {
            width: 'auto',
            data:  {review: this.review}
        });

        dialogRef.afterClosed().toPromise()
        .then(result => {
            
            if(result){

                this._google.reviewPublished(this.review._id).toPromise()
                .then(response => {
                    this._utils.showSuccess("Reseña enviada para su comprobación.", 5000);
                    this.stepper.next();
                })
                .catch(error => {
                    console.log(error);
                    this._utils.showError("Error al actualizar reseña.", 5000);
                })

            }
            
        });

    }

  reviewTimeout(reviewID){

    //Set timeout
    setTimeout(()=>{
      if(this.review && this.review._id == reviewID){
        // this._google.freeReview(this.review._id); //Mejor que lo gestione el back
        this.timeout = true;
        this.review = null;
      }
    }, this.reviewTime * 60000);

    //Set countdown
    this.reviewCountDown = this.reviewTime * 60;
    var rec = setInterval(()=>{
      this.reviewCountDown--;
      if(this.reviewCountDown <= 0 || this.review == null) clearInterval(rec);
    }, 1000)
  }

  parseTimeLeft(time){
    var min = Math.floor(time / 60);
    var sec = time % 60;
    return min + ":" + (sec < 10? '0'+ sec : sec);
  }


}

import { Injectable } from "@angular/core";
import { getWebGlBasics, getWebGlExtensions, STATUS_GET_PARAMETER_NOT_A_FUNCTION, STATUS_NO_GL_CONTEXT } from '../lib/webgl'

import FingerprintJS from '@fingerprintjs/fingerprintjs/dist/fp.esm'
//IMPORTANT: ADD this to node-modules library fp.d.ts file anywhere to prevent TS error (declare module '@fingerprintjs/fingerprintjs/dist/fp.esm';)

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class FpServices {

    constructor(
        private _http: HttpClient,
        private _utils: Utils
    ){}

    public fp;
    public gc;

    public async onAppLoad(){

        console.log("App loaded")

        //We suppose that the user is logged in

        //Get info
        await this._load();

        //Send info to server
        this._send();

    }

    public async onLoginSuccessful(){

        console.log("Login successful")
        
        //Get info
        await this._load();

        //Send info to server
        this._send();

    }

    public async onRegisterSuccessful(){

        console.log("Register successful")
        
        //Get info
        await this._load();

        //Send as new user
        this._firstSession();

    }

    private async _load(){
        //Get Fingerprint
        if(!this.fp) await this._getFp();
        //Get WebGL
        if(!this.gc) this._getWebGl();
    }

    private _send(){

        //Send to server
        // console.log('Fingerprint:', this.fp);
        // console.log('WebGL:', this.gc);

        return this._http.post(this._utils.backURL + '/api/stats/session',
        {
            fp: this.fp,
            webgl: this.gc
        },
        this._utils.jwt()).toPromise();

    }

    private _firstSession(){
            
        //Send to server
        // console.log('Fingerprint:', this.fp);
        // console.log('WebGL:', this.gc);

        return this._http.post(this._utils.backURL + '/api/stats/session?first=true',
        {
            fp: this.fp,
            webgl: this.gc
        },
        this._utils.jwt()).toPromise();
    }

    private async _getFp(){

        const fp = await FingerprintJS.load({monitoring: false});
        const fingerprint = await fp.get();
        this.fp = fingerprint.visitorId;

    }

    private _getWebGl(){
    
        const webglBasics = getWebGlBasics({cache: {}});

        if (
            webglBasics === STATUS_NO_GL_CONTEXT ||
            webglBasics === STATUS_GET_PARAMETER_NOT_A_FUNCTION
        ) return;

        this.gc = webglBasics['rendererUnmasked'];

        // const webglExtensions = getWebGlExtensions({
        //     cache: { },
        // });
        // if (webglExtensions === STATUS_NO_GL_CONTEXT) {
        //     console.log("WebGL context is not available");
        // } else if (webglExtensions === STATUS_GET_PARAMETER_NOT_A_FUNCTION) {
        //     console.log("WebGL context `getParameter` method is not a function");
        // } else {
        //     console.log(webglExtensions);
        // }

    }

}
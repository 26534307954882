import { Component, OnInit } from '@angular/core';
import { UserServices } from 'src/app/services/user.service';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'panel-tools',
  templateUrl: './panel-tools.component.html',
  styleUrls: ['./panel-tools.component.scss']
})

export class PanelToolsComponent implements OnInit{

    sub_active = false;

    constructor(private _user: UserServices, private _utils: Utils) {}

    ngOnInit(){
        this._user.getSubs().toPromise()
        .then(response =>{
            let subs = response['subs'];
            for(let sub of subs){
                if(sub.status == this._utils.sub_status.ACTIVE){
                    this.sub_active = true;
                    break;
                }
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al comprobar suscripciones activas", 5000);
        })
    }

}

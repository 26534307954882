import { Component, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { PublisherService } from 'src/app/services/publisher.service';
import { UserServices } from 'src/app/services/user.service';
import { utils } from 'protractor';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { VerifyGAccountDialog } from '../../dialogs/verify-gaccount/verify-gaccount';
import { EditGMBAccountDialog } from '../../dialogs/edit-gmbaccount/edit-gmbaccount';
import { GoogleServices } from 'src/app/services/google-service';
import { CustomTranslator } from 'src/app/services/i18n';
import { AssignedReviewDialog } from '../../dialogs/assigned-review/assigned-review';

@Component({
  selector: 'panel-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
})

export class PublisherComponent implements OnInit{

    public loading;

    public showForm;

    public accounts;

    public newAccount = {
        name: "",
        email: "",
        gender: "xy",
        country: "",
        city: "",
        lg: 1
    }

    public stats = {
        reviews_total: 0,
        reviews_checking: 0,
        reviews_published: 0,
        reviews_hidden: 0
    }

    private notified = false;

    public n_rw;
    public rw_available = [];

    public countrySelected;
    public countries;

    public citySelected;
    public cities;

    public review_status;

    lg_credits;

    columnsToDisplay = ['date', 'account', /*'company', 'stars', 'text',*/ 'status', 'revenue', 'actions'];
    
    @ViewChild('reviewPag') 
    reviewPag: MatPaginator;
    reviews: MatTableDataSource<any>;

    pub_credits;

    //Internal events
    private _user_loaded: Promise<unknown>;

    constructor(
        public _i18n: CustomTranslator,
        public _utils: Utils,
        public _publisher: PublisherService,
        public _user: UserServices,
        private _google: GoogleServices,
        private verifyDialog: MatDialog,
        private assigned_dialog: MatDialog
    ){
        this.review_status = this._utils.review_status;
        this.lg_credits = this._utils.lg_credits;
    }

    ngOnInit(){

        //Async
        this._user_loaded = this._user.loadUser();
        this._loadCountries();
        this._loadAvilableRws();
        this._loadAccounts();
        this._loadReviews();

    }

    _loadAvilableRws(){
        this.loading = true;
        this._google.getAvailableRws().toPromise()
        .then(response =>{
            this.n_rw = response['count'];
            this.rw_available = response['accounts'];
            this.loading = false;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al comprobar reseñas disponibles", 3000);
            this.loading = false;
        })
    }

    countryChange(){
        this._utils.getCities(this.countrySelected.alpha2Code)
        .then(cities =>{
            this.cities = cities;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de ciudades", 5000);
        })
    }

    _loadCountries(){
        this._utils.getCountries().toPromise()
        .then((countries: Array<any>) =>{
            countries.sort((a, b)=>{
                var a_value: String = a.translations.es? a.translations.es : a.name;
                var b_value = b.translations.es? b.translations.es : b.name;
                return a_value.localeCompare(b_value);
            })
            this.countries = countries;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de paises", 5000);
        })
    }

    _loadAccounts(){
        //Get Accounts
        this._publisher.getAccounts().toPromise()
        .then(response => {
            this.accounts = response['accounts'].sort((a,b) => {return b.lg - a.lg} );
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar las cuentas de Google", 5000);
        })
    }

    _loadReviews(){
        //Get Reviews
        this._publisher.getReviews().toPromise()
        .then(async response => {
            let data = await this._loadStats(response['reviews']);
            this.reviews = new MatTableDataSource(data);
            this.reviews.paginator = this.reviewPag;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar las reseñas publicadas", 5000);
        })
    }

    private async _loadStats(reviews){

        await this._user_loaded;

        let show_rws = [];

        this.stats.reviews_total = reviews.length;
        this.stats.reviews_checking = 0;
        for(let review of reviews){

            //Hidden
            if(review.publisher_id !== this._user.sharedUser._id){
                this.stats.reviews_hidden++;
                continue;
            }

            //Not Hidden
            else{
                show_rws.push(review)
                if(review['status'] == this.review_status.CHECKING)this.stats.reviews_checking++;
                if(review['status'] == this.review_status.PUBLISHED)this.stats.reviews_published++;
                if(review['status'] == this.review_status.RESERVED && !this.notified){ this._openNotifyDialog(review); this.notified = true }
            }

        }
        this._getTotalPubCredits();

        return show_rws;
    }

    toggleForm(){
        this.showForm = !this.showForm;
    }

    parseDate(date){
        return new Date(date).toLocaleDateString();
    }

    saveAccount(){

        this.newAccount.country = this.countrySelected.name;
        this.newAccount.city = this.citySelected.name;

        this._publisher.addAccount(this.newAccount).toPromise()
        .then(response => {
            this.accounts = this.accounts.concat([response['account']]);
            this.toggleForm();
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al crear perfil", 5000);
        })
    }


    _getTotalPubCredits(){

        //Load transactions
        this._user.getTransactions().toPromise()
        .then(response =>{
            let transactions = response['regs'];
            let credits = 0;
            for(let reg of transactions){
                if(reg.type == this._utils.transaction_type.REVIEW_PAY)
                credits += reg.quantity;
            }
            this.pub_credits = credits;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar historial de transacciones", 3000);
        })
    
    }

    openEditDialog(account){
        const dialogRef = this.verifyDialog.open(EditGMBAccountDialog, {
            width: 'auto',
            data:  {account: account, countries: this.countries}
        });
    
        dialogRef.afterClosed().toPromise()
        .then(result => {
            if(result && result['account']){
                Object.assign(account, result['account']);
            }
        });
    }


    openVerifyDialog(account){
        const dialogRef = this.verifyDialog.open(VerifyGAccountDialog, {
            width: 'auto',
            data:  {account: account}
        });
    
        dialogRef.afterClosed().toPromise()
        .then(result => {
            if(result && result['account']){
                let filtered = this.accounts.filter(item =>{
                    if(item._id !== account._id) return item;
                })

                this.accounts = [result['account']].concat(filtered);
            }
        });
    }


    show(review){

        const dialogRef = this.assigned_dialog.open(AssignedReviewDialog, {
            width: 'auto',
            data:  {review: review}
        });
    
        dialogRef.afterClosed().toPromise()
        .then(result => {
            this._loadReviews();
        });

    }

    _openNotifyDialog(rw){

        const me = this;

        this._utils.openConfirmation(
            "Tiene una reseña asignada pendiente de publicación, por favor complete el proceso de haciendo click en publicar.",
            "Publicar",
            "Omitir",
            function(){me.show(rw)},
            function(){me._utils.showError("La reseña permanecerá asignada por un breve periodo (<1h), recuerde publicarla lo antes posible.")}
        )

    }


    public scroll2(el: HTMLElement){
        el.scrollIntoView();
        el.classList.add("focusAnimation")
    }


}

import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleServices } from 'src/app/services/google-service';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'verify-gaccount',
    templateUrl: 'verify-gaccount.html',
    styleUrls: ['./verify-gaccount.scss']
})

export class VerifyGAccountDialog {

    code: String;

    constructor(
        @Inject(MAT_DIALOG_DATA) public input: any,
        private dialog: MatDialogRef<VerifyGAccountDialog>,
        private _gmb: GoogleServices,
        private _utils: Utils
    ) {}

    verify(){

        if(!this.code) return;

        this._gmb.verifyGMB(this.input.account._id, this.code).toPromise()
        .then(response =>{
            this._utils.showSuccess("Cuenta verificada correctamente", 5000);
            this.dialog.close({account: response['account']});
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Código incorrecto", 5000);
        })
    }

    resend(){
        this._gmb.sendGMBVCode(this.input.account._id).toPromise()
        .then(response =>{
            this._utils.showSuccess("Código enviado, compruebe su bandeja de entrada", 5000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al reenviar el código", 5000);
        })
    }
    
    close(){
        this.dialog.close();
    }
}
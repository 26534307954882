import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: "img[defaultOnError]",
})
export class DefaultImageErrorDirective {
    constructor(private el: ElementRef) {}

    @HostListener("error")
    private onError() {
        this.el.nativeElement.src = 'assets/img/defaultProfile.png';
    }
}
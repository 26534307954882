import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DateAdapter, MatStepper, MatStep } from '@angular/material';

import { Utils } from '../../services/utils';
import { ApiService } from '../../services/api.service';
import { UserServices } from '../../services/user.service';
import { User } from 'src/app/models/user';
import { CustomTranslator } from 'src/app/services/i18n';
import { FpServices } from 'src/app/services/fp.service';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss'],
})

export class RegisterComponent implements OnInit {

    private PWD_MIN_LENGTH = 6;
    private PWD_MAX_LENGTH = 12;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('step1') step1: MatStep;
    @ViewChild('step2') step2: MatStep;
    @ViewChild('finalStep') finalStep: MatStep;

    //Validators
    firstFormGroup : FormGroup;

    nameControl : FormControl;
    emailControl : FormControl;
    passControl : FormControl;
    confControl : FormControl;

    typeControl : FormControl;

    // thirdFormGroup : FormGroup;

    // companyControl : FormControl;
    // usernameControl : FormControl;
    // paisControl : FormControl;
    // cityControl : FormControl;

    // filteredCountries : Observable<string[]>;
    // countries : string[];

    //Form models
    public accountType = "company";

    private config;
  
    constructor(
        public _i18n: CustomTranslator,
        private _utils: Utils,
        private _API: ApiService,
        private _user: UserServices,
        private _formBuilder: FormBuilder,
        private _fp: FpServices
    ){}

    ngOnInit() {

        //Load config params from backend
        this._user.getRegConfig().then(config => this.config = config)

        this.nameControl = new FormControl( '', [ Validators.required] );
        this.emailControl = new FormControl( '', [ Validators.required, Validators.email ] );
        this.passControl = new FormControl( '', [ Validators.required, Validators.minLength(this.PWD_MIN_LENGTH), Validators.maxLength(this.PWD_MAX_LENGTH) ] );
        this.confControl = new FormControl( '', [ Validators.required, Validators.minLength(this.PWD_MIN_LENGTH), Validators.maxLength(this.PWD_MAX_LENGTH) ] );

        var name  = this.nameControl;
        var email  = this.emailControl
        var password  = this.passControl;
        var password2  = this.confControl;

        this.firstFormGroup = this._formBuilder.group({
            name,
            email,
            password,
            password2
        })
        
    }
    
    //Post Method
    async createAccount(){

        if(!this.firstFormGroup.valid)return;

        //Check publisher register allowed
        if(
            this.accountType != "company" &&
            this.config && 
            this.config.allow_rp == false
        ) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        //Check FP
        // let valid = await this._user.checkFP();
        // let unique = await this._user.uniqueFP();

        // if(!valid || !unique) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        var data = new User();

        data.type = this.accountType;

        data.name = this.nameControl.value;
        data.email = this.emailControl.value;
        data.password = this.passControl.value;

        var ref = this._utils.findQuery(' imp_ref', document.cookie.split(';'));
        if(ref)data.ref_id = ref;

        this._user.create(data).subscribe(
        result => {
            //Login
            this._registerSuccessful(result);
            //Finish register
            this.step1.editable = false;
            this.step2.editable = false;
            this.finalStep.completed = true;
            this.stepper.selectedIndex = 2;
        },
        error => {
            if(error.error.error.includes('email')){
            this.emailControl.setErrors({'repeated': true});
            }
            else{
            this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);
            }
            console.log(error.error.error);
        });
    }

    checkPass(){
        if( this.passControl.value !== this.confControl.value ){
            this.confControl.setErrors({Match : true});
        }
    }

    async googleLogin() {

        //Check publisher register allowed
        if(
            this.accountType != "company" &&
            this.config && 
            this.config.allow_rp == false
        ) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        //Check FP
        // let valid = await this._user.checkFP();
        // let unique = await this._user.uniqueFP();

        // if(!valid || !unique) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        var url = this._utils.backURL + '/api/user/auth/google?type=' + this.accountType;
        var ref = this._utils.findQuery(' imp_ref', document.cookie.split(';'));
        if(ref)url += '&ref_id=' + ref;
        window.location.href = url;

        /**
         * [OLD FP]TODO: FP security could be skipped if someone type the calculated url
         * Blacklisted fps are blocked anyways on login or page loading
         * Uniqueness is not checked anywhere else
         */
    }

    async facebookLogin() {

        //Check publisher register allowed
        if(
            this.accountType != "company" &&
            this.config && 
            this.config.allow_rp == false
        ) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        //Check FP
        // let valid = await this._user.checkFP();
        // let unique = await this._user.uniqueFP();

        // if(!valid || !unique) return this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);

        var data = { type: this.accountType };
        var url = this._utils.backURL + '/api/user/auth/facebook?type=' + this.accountType;
        var ref = this._utils.findQuery(' imp_ref', document.cookie.split(';'));
        if(ref)data['ref_id'] = ref;
        localStorage.fbRegister = btoa(JSON.stringify(data));
        window.location.href = url;

        /**
         * [OLD FP]TODO: FP security could be skipped if someone type the calculated url
         * Blacklisted fps are blocked anyways on login or page loading
         * Uniqueness is not checked anywhere else
         */
    }

    // private _filter(value: string): string[] {
    //   const filterValue = value.toLowerCase();
    //   return this.countries.filter(option => this._removeAccent(option.toLowerCase()).includes(filterValue));
    // }

    private _removeAccent(text: string){
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    private _registerSuccessful(user){
        this._user.setLocalUser(user);
        this._utils.pixelEvent("CompleteRegistration", user);
        
        //Add fp to user
        this._fp.onRegisterSuccessful();
    }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Material
import { MATERIAL_IMPORTS } from './material-imports';
import { MAT_SNACK_BAR_DATA, MAT_DATE_LOCALE } from '@angular/material';

//Servicios
import { UserServices } from "./services/user.service";
import { Utils } from "./services/utils";
import { ApiService } from "./services/api.service";
import { AuthenticationService } from "./services/authentication.service";
import { ShopService } from './services/shop.service';
import { AdminService } from './services/admin.service';
import { GoogleServices } from './services/google-service';
import { PublisherService } from './services/publisher.service';
import { CompanyService } from './services/company.service';
import { SeoServices } from './services/seo.service';
import { ImgService } from './services/img.service';
import { CustomTranslator } from './services/i18n';

//Para graficos
import { ChartsModule } from 'ng2-charts/ng2-charts';

//Facebook Pixel
import { Angulartics2Module } from 'angulartics2';

//Components
import { NotFound404Component } from './components/not-found/not-found404.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RecoverPasswordComponent } from './components/recover/recover.component';

//Dashboard
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ShopComponent, BuyDialogComponent } from './components/dashboard/shop/shop.component';
import { PaymentComponent } from './components/dashboard/shop/payment/payment.component';
import { PanelSettingsComponent } from './components/dashboard/settings/panel-settings.component';
import { SettingsAffiliatesComponent } from './components/dashboard/settings/affiliates/settings-affiliates';
import { SettingsCreditsComponent } from './components/dashboard/settings/credits/settings-credits';
import { SettingsEditComponent } from './components/dashboard/settings/edit/settings-edit';

//Company
import { PanelProfilesComponent, AddSubDialogComponent } from './components/dashboard/company/profiles/panel-profiles.component';
import { NewProfileComponent } from './components/dashboard/company/new-profile/new-profile.component';
import { PanelOrdersComponent, AddProfileDialogComponent } from './components/dashboard/company/orders/panel-orders.component';
import { ProfileDetailComponent, CreditsDialogComponent } from './components/dashboard/company/profile-detail/profile-detail.component';
import { PanelToolsComponent } from './components/dashboard/company/tools/panel-tools.component';
import { ToolsTrackingComponent, SafePipe } from './components/dashboard/company/tools/tracking/tools-tracking.component';
import { ToolsProfileComponent } from './components/dashboard/company/tools/profile/tools-profile.component';
import { ToolsCalculatorComponent } from './components/dashboard/company/tools/calc/tools-calc.component';
import { ToolsContactComponent } from './components/dashboard/company/tools/contact/tools-contact.component';

//Publisher
import { PanelPublishComponent } from './components/dashboard/publisher/publish/panel-publish.component';
import { PublisherComponent } from './components/dashboard/publisher/publisher.component';

//Dialogs
import { AlertSnackbarComponent } from './components/dialogs/alert-snackbar/alert-snackbar';
import { CheckReviewDialog } from './components/dialogs/check-review/check-review';
import { ConfirmDialog } from './components/dialogs/confirm-dialog/confirm-dialog';
import { AssignReviewDialog } from './components/dialogs/assign-review/assign-review';
import { AssignedReviewDialog } from './components/dialogs/assigned-review/assigned-review';
import { VerifyGAccountDialog } from './components/dialogs/verify-gaccount/verify-gaccount';
import { EditGMBAccountDialog } from './components/dialogs/edit-gmbaccount/edit-gmbaccount';
import { ConfirmPublicationDialog } from './components/dialogs/confirm-publication/confirm-publication';

//Admin components
import { AdminComponent } from './components/admin/admin.component';
import { AdminReviewsComponent } from './components/admin/reviews/admin-reviews';
import { AdminOrdersComponent } from './components/admin/orders/admin-orders';
import { AdminSubsComponent } from './components/admin/subs/admin-subs';
import { AdminUsersComponent } from './components/admin/users/admin-users';
import { AdminProfilesComponent } from './components/admin/gmb-profiles/admin-profiles';
import { AdminGMBAComponent } from './components/admin/gmb-acc/admin-gmba';
import { AdminTransactionsComponent } from './components/admin/transactions/admin-transactions';
import { AdminSecurityComponent } from './components/admin/security/security';
import { AdminConfigComponent } from './components/admin/config/config';
import { AdminAnswersComponent } from './components/admin/answers/admin-answers';

import { GroupProfilesDialog } from './components/dialogs/group-profiles/group-profiles';
import { AutoResizeDirective } from './components/ui/directives/autoResize';
import { PanelAnswersComponent } from './components/dashboard/company/ai-answers/panel-answers.component';

import { StarRatingComponent } from './components/ui/star-rating/star-rating';
import { AnswersSliderComponent } from './components/dashboard/company/ai-answers/slider/answers-slider';
import { AnswersManagerComponent, AnswersLinkDialog } from './components/dashboard/company/ai-answers/manager/answers-manager';
import { AnswersServices } from './services/answers.services';
import { DefaultImageErrorDirective } from './directives/default-img-error';
import { FpServices } from './services/fp.service';


@NgModule({
    declarations: [
        //COMPONENTS
        AppComponent,
        
        //Admin
        AdminComponent,
        AdminOrdersComponent,
        AdminProfilesComponent,
        AdminReviewsComponent,
        AdminSubsComponent,
        AdminTransactionsComponent,
        AdminUsersComponent,
        AdminGMBAComponent,
        AdminSecurityComponent,
        AdminConfigComponent,
        AdminAnswersComponent,

        //Panel
        PaymentComponent,
        PanelOrdersComponent,
        PanelProfilesComponent,
        PanelPublishComponent,
        PanelSettingsComponent,
        PanelToolsComponent,
        PanelAnswersComponent,

        //Settings
        SettingsAffiliatesComponent,
        SettingsCreditsComponent,
        SettingsEditComponent,

        //Tools
        ToolsCalculatorComponent,
        ToolsProfileComponent,
        ToolsTrackingComponent,
        ToolsContactComponent,

        //Profile
        ProfileDetailComponent,
        NewProfileComponent,

        DashboardComponent,
        LoginComponent,
        RegisterComponent,
        AlertSnackbarComponent,
        BuyDialogComponent,
        NotFound404Component,
        PublisherComponent,
        RecoverPasswordComponent,
        ShopComponent,

        //Dialogs
        AddProfileDialogComponent,
        AddSubDialogComponent,
        CreditsDialogComponent,
        CheckReviewDialog,
        ConfirmDialog,
        AssignReviewDialog,
        AssignedReviewDialog,
        VerifyGAccountDialog,
        EditGMBAccountDialog,
        ConfirmPublicationDialog,
        GroupProfilesDialog,
        AnswersLinkDialog,

        //Pipes
        SafePipe,

        //Directives,
        DefaultImageErrorDirective,
        AutoResizeDirective,

        //UI Components
        StarRatingComponent,

        //SubComponents
        AnswersSliderComponent,
        AnswersManagerComponent
    ],
    entryComponents : [
        //Dialogs
        BuyDialogComponent,
        AddProfileDialogComponent,
        AddSubDialogComponent,
        CreditsDialogComponent,
        AlertSnackbarComponent,
        CheckReviewDialog,
        ConfirmDialog,
        AssignReviewDialog,
        AssignedReviewDialog,
        VerifyGAccountDialog,
        EditGMBAccountDialog,
        ConfirmPublicationDialog,
        GroupProfilesDialog,
        AnswersLinkDialog
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ChartsModule,
        MATERIAL_IMPORTS,
        Angulartics2Module.forRoot()
    ],
    providers: [

        //Services
        UserServices,
        Utils,
        ApiService,
        AuthenticationService,
        GoogleServices,
        ShopService,
        PublisherService,
        CompanyService,
        SeoServices,
        AdminService,
        ImgService,
        CustomTranslator,
        AnswersServices,
        FpServices,
        { provide: MAT_SNACK_BAR_DATA, useValue: {} },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import {Utils} from '../../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { GoogleServices } from 'src/app/services/google-service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
  selector: 'tools-contact',
  templateUrl: './tools-contact.component.html',
  styleUrls: ['./tools-contact.component.scss']
})

export class ToolsContactComponent implements OnInit {

    @ViewChild('reviewPag')
    reviewPag: MatPaginator;
    reviews: MatTableDataSource<any>;
    review_columns = ['date', 'stars', 'text', 'user', 'actions'];

    public profiles;
    public profileSelected;

    public report;

    constructor(
        private _utils: Utils,
        private _user: UserServices,
        private _google: GoogleServices,
        public _i18n: CustomTranslator,
    ) {}

    ngOnInit() {

        //Load profiles
        this._user.loadProfiles()
        .then(profiles => {
            this.profiles = profiles;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });

    }

    loadProfile(){
        this.report = undefined;
        this._google.getReport(this.profileSelected._id).toPromise()
        .then(response => {
            if(response['report']){
                this.report = response['report'];
                this.reviews = new MatTableDataSource(this.report.reviews);
                setTimeout(()=>{
                    this.reviews.paginator = this.reviewPag;
                })
            }
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar informe", 5000);
        });
    }

    getURL(link){
        const hangoutURL = 'https://hangouts.google.com/webchat/start?hl=en&action=chat&pi=';
        var id = link.split('/')[5];
        return hangoutURL + id;
    }

    getImg(string: String){
        return string.slice(5,string.length-2);
    }

    getStars(string: String){
        return string.slice(0,1);
    }

    parseDate(time){
        return new Date(time * 1000).toLocaleDateString();
    }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Utils } from '../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
  selector: 'recover-password',
  templateUrl: 'recover.component.html',
  styleUrls: ['./recover.component.scss'],
})

export class RecoverPasswordComponent implements OnInit {

    returnURL = '/es/dashboard';

    secondStep;

    emailControl: FormControl;
    passControl : FormControl;
    confControl : FormControl;

    private PWD_MIN_LENGTH = 6;
    private PWD_MAX_LENGTH = 12;

    user_id;
    hash;

    constructor(
        public _i18n: CustomTranslator,
        private _utils: Utils,
        private _user: UserServices,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ){
        this.activatedRoute.queryParams.subscribe(params => {
            this.user_id = params['id'];
            this. hash = params['hash'];
            if(this.user_id && this.hash) this.secondStep = true;
        });
    }

    ngOnInit() {
        this.emailControl = new FormControl( '', [ Validators.required, Validators.email ] );
        this.passControl = new FormControl( '', [ Validators.required, Validators.minLength(this.PWD_MIN_LENGTH), Validators.maxLength(this.PWD_MAX_LENGTH) ] );
        this.confControl = new FormControl( '', [ Validators.required, Validators.minLength(this.PWD_MIN_LENGTH), Validators.maxLength(this.PWD_MAX_LENGTH) ] );
    }

    checkPass(){
        if( this.passControl.value !== this.confControl.value ){
            this.confControl.setErrors({'match' : true});
        }
    }

    sendRecoverEmail(){
        if(this.emailControl.valid){
            this._user.sendRecoverEmail(this.emailControl.value).toPromise()
            .then(response =>{
                this._utils.showSuccess("Email enviado correctamente.", 4000);
            })
            .catch(error =>{
                console.log(error);
                this.emailControl.setErrors({'email_unknown': true});
            })
        }else{
            this.emailControl.markAsTouched();
        }
    }

    updatePassword(){
        if(!this.confControl.valid){
            this.passControl.markAsTouched();
            this.confControl.markAsTouched();
            return;
        }
        this._user.recoverPassword(this.user_id, this.hash, this.passControl.value).toPromise()
        .then(response =>{
            this._utils.showSuccess("Contraseña modificada correctamente", 4000);
            this._user.setLocalUser(response['sesion']);
            this.router.navigate([this.returnURL]);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar contraseña. Inténtelo de nuevo.", 4000);
        })
    }

    googleLogin() {
        window.location.href = 'https://api.imperatool.com/api/user/auth/google';
    }
    
    facebookLogin() {
        window.location.href = 'https://api.imperatool.com/api/user/auth/facebook';
    }

}

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from "@angular/material";
import { AdminService } from "src/app/services/admin.service";
import { Utils } from "src/app/services/utils";
import { filter } from "rxjs/operators";

@Component({
    selector: 'assign-review-dialog',
    templateUrl: 'assign-review.html',
    styleUrls: ['./assign-review.scss'],
})

/* BUY DIALOG */
export class AssignReviewDialog implements OnInit{

    public review;
    public filter;

    bulk_accounts;
    accounts_list: MatTableDataSource<any>;
    columnsToDisplay = ['account', 'lg', 'publisher', 'actions'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AssignReviewDialog>,
        private _utils: Utils,
        private _admin: AdminService
    ) {
        this.review = data.review;
    };

    ngOnInit(){
        this._loadAccounts();
    }

    _loadAccounts(){
        this._admin.getGMBAccounts().toPromise()
        .then(response =>{
            this.bulk_accounts = response['accounts'];
            this.accounts_list = new MatTableDataSource(this.bulk_accounts.slice(0,50));
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar cuentas", 5000);
        })
    }

    assignReview(account){
        this._admin.checkReview_assign(this.review._id, account._id).toPromise()
        .then(response =>{
            this._utils.showSuccess("Reseña confirmada", 3000);
            this.dialogRef.close({review: response['review']});
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al asociar cuenta", 5000);
        })
    }

    filterAccounts(){
        if(this.filter){
            let filtered_list = this.bulk_accounts.filter(account => (account.name + account._id).toLowerCase().includes(this.filter.toLowerCase()));
            this.accounts_list = new MatTableDataSource(filtered_list.slice(0,50));
        }
        else{
            this.accounts_list = new MatTableDataSource(this.bulk_accounts.slice(0,50));
        }
    }

}
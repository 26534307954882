import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Utils } from 'src/app/services/utils';
import { ShopService } from 'src/app/services/shop.service';
import { MatTabChangeEvent, MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Subject } from 'rxjs';

import * as Chart from 'chart.js';

declare var paypal;

@Component({
  selector: 'admin-component',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})

export class AdminComponent implements OnInit{

    private lazyLoadEvent: Subject<String> = new Subject<String>();

    @ViewChild('subsPag')
    subsPag: MatPaginator;
    subs: MatTableDataSource<any>;
    subsColumns = ['id', 'buyer_id', 'last_paid', 'date', 'order', 'profiles', 'rw_monthly', 'rw_published', 'rw_total', 'status'];
    sub_status;
    sub_input;
    sub_preview;

    //Shared User List
    sharedUsers;

    //Auth
    auth = false;

    //Service
    name: String;
    description: String;
    img: String;
    type: String;
    quantity: Number;
    price: Number;

    services = [];
    columnsToDisplay = ['id','name','type','quantity','price'];

    //Test
    log: Array<String>; 
    logBulk: Array<String>;
    logStep = 0;

    constructor(
        private _utils: Utils,
        private _admin: AdminService,
        private _shop: ShopService,
        private _user: UserServices,
    ){

        this.sub_status = _utils.sub_status;

        _admin.getAuth().toPromise()
        .then(result=>{
            this.auth = true;
        })
        .catch(error=>{this.auth = false;})

    }

    ngOnInit(){
        this.loadUsers();
        this.lazyLoadEvent.next('rw');
    }

    loadTab(event: MatTabChangeEvent){

        switch(event.index){

            //Reviews
            case 0: this.lazyLoadEvent.next('rw'); break;

            //Answers
            case 1: this.lazyLoadEvent.next('answer'); break;

            //Orders
            case 2: this.lazyLoadEvent.next('order'); break;

            //Subscriptions
            case 3: this.lazyLoadEvent.next('sub'); break;

            //Transactions
            case 4: this.lazyLoadEvent.next('reg'); break;

            //Users
            case 5: this.lazyLoadEvent.next('usr'); break;

            //Profiles
            case 6: this.lazyLoadEvent.next('profile'); break;

            //GMBAccounts
            case 7: this.lazyLoadEvent.next('gmbaccount'); break;
            
            //Servicios
            case 8: if(this.services.length == 0) this.loadServices(); break;

            case 9: this.getLog(); break;

        }
    }

    loadUsers(){
        this._user.getUsers().toPromise()
        .then(result =>{
            this.sharedUsers = result['users'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar usuarios", 3000);
        })    
    }

    loadServices(){

        this._shop.getServices().toPromise()
        .then(result =>{
            this.services = result['services'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar servicios", 3000);
        })

    }

    createService(){
        var data = {
        name: this.name,
        description: this.description,
        img: this.img,
        type: this.type,
        quantity: this.quantity,
        price: this.price
        }

        this._admin.createService(data).toPromise()
        .then(result =>{
            this.services = this.services.concat([result['service']]);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al crear servicio", 3000);
        });

    }

    parseDate(date){
        return new Date(date).toLocaleDateString();
    }

    

    @ViewChild('paypal') paypalElement: ElementRef;

    async test(option){

        switch (option) {

            case 1:
                await this._admin.test('/').toPromise()
                break;

            case 2:
                await this._admin.test('/auto-check').toPromise()
                break;

            case 3:
                await this._admin.test('/update-relations').toPromise()
                break;

            case 4:
                // try {
                //     let response = await this._admin.test('/gmba-stats').toPromise();

                //     this['STATS_GMBA_TOTAL'] = response['count'];
                  
                //     // Configuración para el chart de países
                //     const countryLabels = Object.keys(response['countries']);
                //     const countryData = Object.values(response['countries']);
                //     const countryBackgroundColors = ['#FF0000', ...Array(countryLabels.length - 1).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16))];
                //     const countryConfig = this.generatePieChartConfig(countryLabels, countryData, countryBackgroundColors);
                  
                //     // Crear chart de países
                //     const countryCtx = document.getElementById('countryChart') as HTMLCanvasElement;
                //     const countryChart = new Chart(countryCtx, countryConfig);
                  
                //     // Configuración para el chart de ciudades
                //     const cityLabels = Object.keys(response['cities']);
                //     const cityData = Object.values(response['cities']);
                //     const cityBackgroundColors = Array(cityLabels.length).fill('').map(_ => '#' + Math.floor(Math.random() * 16777215).toString(16));
                //     const cityConfig = this.generatePieChartConfig(cityLabels, cityData, cityBackgroundColors);
                  
                //     // Crear chart de ciudades
                //     const cityCtx = document.getElementById('cityChart') as HTMLCanvasElement;
                //     const cityChart = new Chart(cityCtx, cityConfig);
                  
                //   } catch (error) {
                //     console.error('Error al procesar los datos:', error);
                //   }
                break;
        
            default:
                break;
        }        

    }

    // generatePieChartConfig(labels: string[], data: any[], backgroundColors: string[]): any {
      
    //     return {
    //       type: 'pie',
    //       data: {
    //         labels: labels,
    //         datasets: [{
    //           data: data,
    //           backgroundColor: backgroundColors,
    //           borderWidth: 0
    //         }]
    //       },
    //       options: {
    //         responsive: true,
    //         maintainAspectRatio: false,
    //         cutoutPercentage: 50
    //       }
    //     };
    //   }
      
      


    getLog(){

        this._admin.getLog().toPromise()
        .then(response => {
            this.logBulk = response['log']//.slice(0, 500);
            this.log = this.logBulk.slice(0,500);
            this.logStep = 0;
        })

    }

    nextLog(){

        this.logStep++;

        this.log = this.logBulk.slice(this.logStep * 500, (this.logStep + 1) * 500)

    }

    backLog(){

        if(this.logStep > 0) this.logStep--;

        this.log = this.logBulk.slice(this.logStep * 500, (this.logStep + 1) * 500)

    }

}

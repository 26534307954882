import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-security',
  templateUrl: './security.html',
  styleUrls: ['./security.scss'],
})

export class AdminSecurityComponent implements OnInit{

    //Content
    public content: '';


    //Fingerprint
    public fpInput;
    public fpBL = [];
    public findType = 'id';
    public findInput;
    public findResult;

    //Multi Accounts
    public multis;
    public multisColumns = ['user', 'name', 'email', 'fp', 'flag']

    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _utils: Utils,
    ) {
        
    }

    ngOnInit(){

    }


    /*
    |-------------
    | FINGERPRINTS
    |-------------
    */

    
    banFP(){

        this._admin.banFingerprint(this.fpInput)
        .then(response => {
            this.fpBL = response['bl'];
            this._utils.showSuccess("Fingerprint added to blacklist")
        })
        .catch(e => {
            console.error(e)
            this._utils.showError("Error. Check log")
        })

    }

    unbanFP(){

        this._admin.unbanFingerprint(this.fpInput)
        .then(response => {
            this.fpBL = response['bl'];
            this._utils.showSuccess("Fingerprint removed from blacklist")
        })
        .catch(e => {
            console.error(e)
            this._utils.showError("Error. Check log")
        })

    }

    loadFPBL(){

        this._admin.getFingerprintBlacklist()
        .then(response => {
            this.fpBL = response['bl'];
        })
        .catch(e => {
            console.error(e)
            this._utils.showError("Error loading BL. Check log")
        })

    }

    find(){

        if(!this.findInput) return this.findResult = null;

        this._admin.findFingerprint(this.findInput, this.findType)
        .then(response => {
            this.findResult = response['result'];
            if(this.findResult.length == 0){
                this.findResult = null;
                this._utils.showSuccess("No hay resultados")
            }
        })
        .catch(e => {
            console.error(e)
            this._utils.showError("Error. Check log")
        })

    }


    /*
    |-------------
    | MULTI ACCOUNTS
    |-------------
    */

    getMulti(){

        this._admin.checkMultiFPS()
        .then(response => {
            
            this.multis = this._sortMultis(response['multis']);

            if(this.multis.length == 0)
                this._utils.showSuccess("No multis found");

        })
        .catch(e => {
            console.error(e)
            this._utils.showError("Error loading Multis. Check log")
        })

    }

    _sortMultis(unsorted){

        let sorted = [];

        //Compare every account with everyone else fp by fp
        while(unsorted.length > 0){

            let account = unsorted[0];

            //Add the account to sorted array
            sorted.push(account);

            //Remove account from array
            unsorted.splice(0,1)

            //Get everyone with same fps as account
            let j = unsorted.length; while(j > 0){

                let other_acc = unsorted[j-1];

                //Calc fps coincident
                let intersection = account.fps.filter(fp => other_acc.fps.includes(fp));

                //If some -> order this accounts together
                if(intersection.length > 0){

                    //Remove account from array
                    unsorted.splice(j-1,1);

                    //Save intersection for presentation
                    if(account.intersection) account.intersection = [...new Set(account.intersection.concat(intersection))];
                    else  account.intersection = intersection;
                    other_acc.intersection = intersection;

                    //Add to sorted array
                    sorted.push(other_acc);

                }

                j--;

            }

        }

        return sorted;

    }

}

export class User {
    _id: number;
    type: string;
    name: string;
    email: string;
    password: string;
    cif: string;
    address: string;
    country: string;
    paypalEmail: string;
    ref_id: string;
    // //Company
    // company: string;
    // //Publisher
    // username: string;
    // country: string;
    // city: number;
}

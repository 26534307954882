import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

//Servicios
import { UserServices } from "./services/user.service";

//FBPixel
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Utils } from './services/utils';
import { FpServices } from './services/fp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    public user_snapshot;

    constructor( 
        public _userAPI : UserServices, 
        public router: Router, 
        private fbPixel: Angulartics2Facebook, 
        private _utils: Utils,
        private _fp: FpServices
    ){}

    //On every app load
    ngOnInit(){

        this.fbPixel.startTracking();

        this.router.events.subscribe(event=>{
            if(event instanceof NavigationEnd){
                this._utils.reloadTawkTo();
            }
        })

        //Check session
        if(this._userAPI.isLogged()){
            this._userAPI.loadUser()
            .then(user=>{

                this._fp.onAppLoad();

                //Check Ban
                if(user['hard_ban']){
                    //Log Out
                    this.logout();
                    return;
                }

                this.user_snapshot = user;
            })
        }

    }

  isLogged(){
    if(this._userAPI.sharedUser){
      this.user_snapshot = this._userAPI.sharedUser;
      return true;
    }
    return false;
  }

  logout(){
    this.user_snapshot = null;
    this._userAPI.logout();
    this.router.navigate(['/es/login']);
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Utils } from '../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company.service';
import { GroupProfilesDialog } from 'src/app/components/dialogs/group-profiles/group-profiles';

@Component({
  selector: 'panel-profiles',
  templateUrl: 'panel-profiles.component.html',
  styleUrls: ['./panel-profiles.component.scss'],
})

export class PanelProfilesComponent implements OnInit {

    public profiles;
    private profiles_bulk;
    private subs;
    
    public search;

    public hideMore = true;
  
    constructor(
        public dialog: MatDialog,
        private _utils: Utils,
        private _user: UserServices,
        private _company: CompanyService
    ){}

    ngOnInit() {

        //Load groups
        this._loadGroups();

        //Load profiles
        this._loadSubs().then(()=>this._loadProfiles());

    }

    /*
    |--------------------------------------------------------------------------
    | LOAD
    |--------------------------------------------------------------------------
    */

    private async _loadGroups(){

        //Load user
        await this._user.loadUser();
        
        //Load groups
        this.groups = this._user.sharedUser.profile_groups? this._user.sharedUser.profile_groups : [];

    }
    
    
    private async _loadSubs(){

        //Load subs
        let response = await this._user.getSubs().toPromise()
        this.subs = response['subs'];

    }


    private async _loadProfiles(){

        //Load profiles
        let profiles = await this._user.loadProfiles()
        .catch(error => { console.log(error); this._utils.showError("Error al cargar perfiles", 5000); });

        //Parse profiles
        await this._includeSubInfo(profiles);
        
        //Save
        this.profiles_bulk = profiles;
        
        //Show profiles
        this.executeFilter();

    }


    private _includeSubInfo(profiles){

        //Load sub for each profile
        for(let profile of profiles){
            if(profile.sub_id) profile.sub = this._getSub(profile.sub_id);
        }

        return profiles;

    }


    private _getSub(id){

        for(let sub of this.subs) if(sub._id == id) return sub;
 
    }

    /*
    |--------------------------------------------------------------------------
    | PROFILE GROUPS
    |--------------------------------------------------------------------------
    */

    public groups = [];
    public createGroup = false;
    public groupName = "";
    public groupSelected = "";


    public saveGroup(){
        
        if(!this.groupName) return;

        //Add group to user
        this._company.postGroup(this.groupName)
        .then(response => {
            //Update groups
            this.groups = response['groups'];
            this.selectGroup(this.groupName);
            this.groupName = "";
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al añadir grupo. Inténtelo más tarde", 5000);
        })

    }


    public deleteGroup(group){

        this._utils.openConfirmation(
            `Se va a borrar el grupo <${group}>. 
            No se borrará ningún perfil de su cuenta solamente se actualizará el grupo de los perfiles correspondientes. 
            Los perfiles podrán compartir publicadores a partir de ahora.`,
            `Borrar grupo`,
            `Cancelar`,
            () => this._deleteGroup(group)
        )

    }


    private _deleteGroup(group){

        //Delete group from user and every profile
        this._company.deleteGroup(group)
        .then(response => {

            //Update groups
            this.groups = response['groups'];
            this.groupSelected = "";

            //Update profiles
            this._loadProfiles();

            this._utils.showSuccess("Grupo eliminado con éxito");
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("Error al eliminar grupo. Inténtelo más tarde", 5000);
        })

    }


    public selectGroup(group){

        //Toggle
        if(this.groupSelected == group){
            this.groupSelected = "";
            this.executeFilter();
            return;
        }

        //Select
        this.groupSelected = group;
        this.executeFilter();

    }

    public groupList(group){

        const dialogRef = this.dialog.open(GroupProfilesDialog, {
            width: 'auto',
            height: 'auto',
            maxWidth: '80vw',
            maxHeight: '90vh',
            data:  {group: group, profiles: this.profiles_bulk}
        });
      
        dialogRef.afterClosed().toPromise().finally(() => {
            this._loadProfiles();
        });

    }


    /*
    |--------------------------------------------------------------------------
    | FILTER
    |--------------------------------------------------------------------------
    */

    executeFilter(){

        //Apply filters
        this.profiles = this.profiles_bulk.filter( profile => {

            let query = true;

            //Group filter
            if(this.groupSelected) query = query && profile.group == this.groupSelected;
           
            //Search
            if(this.search){
                let text = profile.name + "," + profile.location + "," + profile.city + "," + profile.country + "," + profile.keywords;
                text = text.toLowerCase();
                query = query && text.includes(this.search.toLowerCase());
            }

            return query;
            
        });

    }


    /*
    |--------------------------------------------------------------------------
    | ADD SUB DIALOG
    |--------------------------------------------------------------------------
    */

    openDialog(profile): void {
        const dialogRef = this.dialog.open(AddSubDialogComponent, {
        width: 'auto',
        data:  {profile: profile}
        });

        dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        });
    }


}









@Component({
  selector: 'add-sub',
  templateUrl: 'add-sub-dialog.html',
  styleUrls: ['./add-sub-dialog.scss'],
})

/* BUY DIALOG */
export class AddSubDialogComponent implements OnInit{

    public subs;
    public subSelected;

    constructor(
        public dialogRef: MatDialogRef<AddSubDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _utils: Utils,
        private _user: UserServices
    ) {};

    ngOnInit(){
        this._user.getSubs().toPromise()
        .then(result => {
            //Load subs (only active)
            this.subs = result['subs'].filter(each => each.status == this._utils.sub_status.ACTIVE);
            //Load sub selected
            if(this.data.profile.sub_id){
                for (const sub of this.subs) {
                    if(sub._id == this.data.profile.sub_id) this.subSelected = sub;
                }
            }
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar suscripciones", 5000);
        });
    }

    link(){

        let profile = { _id: this.data.profile._id, name: this.data.profile.name };
        this.subSelected.profiles = this.subSelected.profiles.concat([profile]);

        this._user.updateSub(this.subSelected).toPromise()
        .then(result => {
            this._utils.showSuccess("Suscripción actualizada con éxito", 5000);
            this.data.profile.sub_id = this.subSelected._id;
            this.data.profile.sub = this.subSelected;
            this.dialogRef.close();
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar suscripción", 5000);
        })

    }


}

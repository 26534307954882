import { Component, OnInit, Inject } from '@angular/core';
import { AnswersServices } from 'src/app/services/answers.services';
import { UserServices } from 'src/app/services/user.service';
import { Utils } from 'src/app/services/utils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

/*
|-----------------------------
| LINK DIALOG
|-----------------------------
*/

@Component({
    selector: 'link-dialog',
    templateUrl: 'link.dialog.html',
})

export class AnswersLinkDialog {

    public url: string;
    public email: string;

    constructor(
        public dialogRef: MatDialogRef<AnswersLinkDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.url = data.url;
        this.email = data.email;
    }

    openURL() {
        window.open(this.url, '_blank');
    }

    close(): void {
        this.dialogRef.close();
    }

    copyToClipboard(text): void{
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        window.alert('Email copiado al portapapeles');
    }    

}


/*
|-----------------------------
| COMPONENT
|-----------------------------
*/

@Component({
    selector: 'answers-manager',
    templateUrl: './answers-manager.html',
    styleUrls: ['./answers-manager.scss']
})
export class AnswersManagerComponent implements OnInit {

    public profiles;
    public profileSelected;
    public profilesManaged;
    public answers : Array<any> = [];

    public showAddProfile = false;

    public rPage = 0;
    public rLimit = 10;

    constructor(
        private _user: UserServices,
        private _utils: Utils,
        private _answers: AnswersServices,
        private dialog: MatDialog // Add MatDialog to the constructor
    ) { }


    ngOnInit() {
        
        //Load profiles
        this._loadProfiles();

        //Load answers
        this._loadAnswers();

    }


    private _loadProfiles(){
        this._user.loadProfiles()
        .then((profiles: Array<any>) => {
            this.profilesManaged = profiles.filter(profile => profile.managed);
            this.profiles = profiles.filter(profile => !profile.managed);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });
    }

    private _loadAnswers(){
    
        this._answers.getAnswers(null, this.rPage, this.rLimit)
        .then((response : any) => {
            this.answers = response.answers.map(answer => {
                answer.rw_date = new Date(answer.rw_date).toLocaleDateString();
                return answer;
            });
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar respuestas.", 5000);
        });
    
    }

    private _openPopup(url: string) {

        const dialogRef = this.dialog.open(AnswersLinkDialog, {
            width: 'fit-content',
            data: {
                url: url,
                email: `GestorAI+${this.profileSelected._id}@auto.imperatool.com`
            },
        });

        dialogRef.afterClosed().subscribe(result => {

            this._utils.showSuccess("Verificaremos la conexión lo antes posible y te notificaremos por email cuando se active el servicio.", 5000);
            
        });

    }

    /**
     *  PUBLIC METHODS
     */


    public toggleAddProfile(){
        this.showAddProfile = !this.showAddProfile;
    }


    public connectProfile(){

        this._answers.getManageUrl(this.profileSelected._id)
        .then((response : any) => {

            const url = response.url;

            if(url){
                this._openPopup(url);
            }else{              
                this._utils.showError("Error al obtener la url de conexión.", 5000);
            }

        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar datos del perfil.", 5000);
        });

    }


    public toggleEdit(answer){ 
        console.log(answer);
        answer.edit = !answer.edit; 
    }

    public editAnswer(answer){
            
        this._answers.editAnswer(answer)
        .then((response : any) => {
            this._utils.showSuccess("Respuesta actualizada.", 5000);
            answer = response.answer;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar respuesta.", 5000);
        });

        this.toggleEdit(answer);
    
    }

    public publishAnswer(answer){
    
        this._answers.publishAnswer(answer)
        .then((response : any) => {
            this._utils.showSuccess("Estamos publicando la respuesta. Puede tardar unos minutos, te notificaremos por email cuando se realice.", 5000);
            answer = response.answer;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al publicar respuesta.", 5000);
        });

    }


    public toggleEditProfileConfig(profile){

        //Toggle showConfig
        profile.showConfig = !profile.showConfig;
        
        //Get card element
        const front: HTMLElement = document.querySelector(`#card-${profile._id} .front`);
        const back: HTMLElement = document.querySelector(`#card-${profile._id} .back`);

        //Get elements to hide and show
        const hide = profile.showConfig ? front : back;
        const show = profile.showConfig ? back : front;

        //Flip card
        hide.style.transform = 'rotateY(90deg)';
        
        //In the middle of the flip change content
        setTimeout(() => {
            hide.style.display = 'none';
            show.style.transform = 'rotateY(-90deg)';
            show.style.display = 'flex';
            setTimeout(() => {
                show.style.transform = 'rotateY(0deg)';
            }, 100);
        }, 500);     

    }


    public saveProfileConfig(profile){
            
        this._answers.editProfilePreferences(profile._id, profile.answer_config)
        .then((response : any) => {
            this._utils.showSuccess("Perfil actualizado.", 5000);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar perfil.", 5000);
        });

    }

    public toggleAutoAnswer(profile){

        this._answers.editProfilePreferences(profile._id, profile.answer_config)
        .then((response : any) => {
            const msg = profile.answer_config.auto? 
            "Respuestas automáticas activadas." : "Respuestas automáticas desactivadas.";
            this._utils.showSuccess(msg, 5000);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar el perfil.", 5000);
        });

    }


    public filterAnswers(profileID){

        this._answers.getAnswers(profileID, 0, this.rLimit)
        .then((response : any) => {
            this.answers = response.answers.map(answer => {
                answer.rw_date = new Date(answer.rw_date).toLocaleDateString();
                return answer;
            });
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar respuestas.", 5000);
        });

    }

}

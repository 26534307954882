import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-transactions',
  templateUrl: './admin-transactions.html',
  styleUrls: ['./admin-transactions.scss'],
})

export class AdminTransactionsComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    new_transaction = {
        user_id: undefined,
        credits: undefined,
        type: undefined,
        info: '{\n\n\n\n}'
    };

    private users;
    public loading;

    //TABLE
    public totalItems = 0;
    public page = 0;
    public size = 10;
    public transactions : MatTableDataSource<any>;
    @ViewChild('paginator') paginator: MatPaginator;
    public transactions_columns = ['date', 'user', 'type', 'info', 'quantity', 'before', 'after'];
    public transaction_type;

    //FILTER
    public filter: any = {};
    private default_filter = { search: '' };


    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _utils: Utils, 
    ) {
        this.transaction_type = _utils.transaction_type;
        Object.assign(this.filter, this.default_filter);
    }


    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'reg' && !this.loaded){
                this.loaded = true;
                this.loadTransactions();
            }
        });
    }


    info2String(info){
        if(!info) return '-';
        var text = JSON.stringify(info);
        text = text.replace(/{|}|"/gi, '');
        text = text.replace(/:/gi,': ');
        text = text.replace(/,/gi,'\n ');
        return text;
    }

    createTransaction(){
        var info = this.new_transaction.info;//.replace(/\n/gi, '');
        console.log(info)
        try{ info = JSON.parse(info); }
        catch(error){
            console.log(error);
            this._utils.showError("Info mal formateada", 3000);
            return;
        }

        this._admin.createTransaction(
            this.new_transaction.user_id,
            this.new_transaction.credits,
            this.new_transaction.type,
            info
        ).toPromise()
        .then(response =>{
            this.loadTransactions();
            this._utils.showSuccess("Transacción realizada", 3000);
            this.new_transaction = {
                user_id: '',
                credits: '',
                type: '',
                info: '{\n\n\n\n}'
            };
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al crear transacción", 3000);
        })
    }


    promo(){

        this.new_transaction.type = this.transaction_type.OTHER;
        this.new_transaction.info = '{ "promo" : "primera_gratis" }';
        this.new_transaction.credits = 100;

    }


    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */

    public loadTransactions(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        this._admin.getTransactions2(this.size, this.page, query)
        .then(response => {
            this._loadTable(response['data'], response['total']);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar transacciones");
        })

    }


    private async _loadTable(data, total){

        let placeholder = new Array(total)

        //Load users and services
        await this._fillUsers(data);

        const startItem = this.page * this.size;
        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = data[i];
        }

        this.transactions = new MatTableDataSource(placeholder);
        this.transactions.paginator = this.paginator;

        this.loading = false;

    }


    private async _fillUsers(data){

        //Load users if not loaded
        if(!this.users) await this._loadUsers();

        //Fill every order with its user
        for(let reg of data) reg.user = this._findUser(reg.user_id);

    }


    private _loadUsers(){

        return new Promise( (resolve, reject) => {

            this._user.getUsers().toPromise()
            .then(result =>{
                this.users = result['users'];
                resolve(true);
            })
            .catch(error =>{
                this._utils.showError("Error al cargar usuarios", 3000);
                reject(error)
            })

        } )
    
    }


    private _findUser(user_id){

        for (let index = 0; index < this.users.length; index++) {
            const user = this.users[index];
            if(user._id == user_id) return user;
        }

    }


    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadTransactions(true);

    }


    private _parseFilter(){

        let query: any = {};

        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {user_id: num },
                {quantity: num },

                //Texts
                {type: regex }

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }



}

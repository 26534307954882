import {Component, Inject} from '@angular/core';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.html',
    styleUrls: ['./confirm-dialog.scss']
})

export class ConfirmDialog {

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public input: any,
        private dialog: MatBottomSheetRef<ConfirmDialog>
    ) {}

    next(){
        this.input.next();
        this.dialog.dismiss();
    }
    
    back(){
        this.input.back();
        this.dialog.dismiss();
    }
}
import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../models/user';
import {UserServices} from '../../../services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../../../services/utils';
import { MatTabGroup } from '@angular/material';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'panel-settings',
  templateUrl: './panel-settings.component.html',
  styleUrls: ['./panel-settings.component.scss'],
})

export class PanelSettingsComponent implements OnInit {
  model: any = {};
  user: User;
  filteredCountries: any;
  countriesList: any[] = [];
  loading = false; // Para bloquear el boton mientras se procesa update

  account_type;

  partnerCheck = false;

  public defaultTab = 0;

  @ViewChild('tabGroup')
  tabgroup: MatTabGroup;

    constructor(
        private userService: UserServices, 
        public _utils: Utils, 
        private _http: HttpClient,
        private route: ActivatedRoute
    ) {
        this.account_type = _utils.account_type;
    }

  ngOnInit() {

    if(this.route.snapshot.fragment == 'edit') this.defaultTab = 1;
    if(this.route.snapshot.fragment == 'credits') this.defaultTab = 2;

      this.userService.loadUser().finally();
      // Petición de la info del usuario
      this.userService.getUser()
      .then(data => {
        this.model = data;
      })
      .catch(error => {
        this._utils.showError('Error al obtener la información de usuario. Logueese o contacte con un administrador', 10000);
      });


      // HTTP get paises para dropdown
      /*this._http.get('assets/countries/es_ES_country.json').subscribe(
        data => {
          for (const key in data) {
            this.countriesList.push(data[key]);
          }
          this.filteredCountries = this.countriesList;
        },
        error => {
          console.log('Error cargando paises');
        }
      );*/
  }

  @ViewChild('f') registerForm: HTMLFormElement;
  // Peticion a back para actualizar la información de usuario
  updateUser() {
    this.loading = true; // Bloquea boton de actualizar

    this.user = new User();
    this.user.password = this.model.password;
    this.user.email = this.model.email;
    this.user.name = this.model.name;
    this.user.cif = this.model.cif;
    this.user.address = this.model.address;
    this.user.country = this.model.country;
    this.user.paypalEmail = this.model.paypalEmail;

    if (this.model.password === this.model.repassword) {
        this.userService.update(this.user).toPromise()
        .then(response =>{
            this._utils.showSuccess('Su información se actualizo con éxito', 10000);
            this.loading = false;
            this.userService.setLocalUser(response['user']);
        })
        .catch(error => {
            if (error._body.includes('email')) {
                this._utils.showError('Email repetido, porfavor elija uno nuevo', 10000);
                this.registerForm.controls.email.setErrors({'incorrect': true});
            } else {
                this._utils.showError('Error inesperado, pruebe de nuevo más tarde', 10000);
            }
            this.loading = false;
        });
    } else { // Si las contraseñas no son iguales -> Error
        this._utils.showError('Las contraseñas no coinciden', 5000);
        this.loading = false;
    }
  }

  becomePartner(){
      if(this.partnerCheck){
            this.userService.partnerRequest().toPromise()
            .then(response =>{
                this.model = response['user'];
                this.userService.setLocalUser(this.model);
                this._utils.showSuccess("Solicitud aceptada. Ya puede utilizar su link de socio.", 5000);
                //TODO cambiar pestaña asociados
                this.tabgroup.selectedIndex = 3;
            })
            .catch(error =>{
                console.log(error);
                this._utils.showError("Error al obtener link", 3000);
            })
      }
  }

    updateNotify(){
        this.userService.update({email_ignore: !this.model.email_ignore}).toPromise()
        .then(response =>{
            this.model = response['user'];
            this._utils.showSuccess("Preferencias actualizadas correctamente.", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar preferencias.", 3000);
        })
    }

  // AUX Paises: Filtra según se escribe en el campo pais
  /*filter(country) {
    this.model.country = country;
    this.filteredCountries = this.filterCountries(this.model.country);
  }

  filterCountries(val: string) {
    return val ? this.countriesList.filter(s => new RegExp(`^${val}`, 'gi').test(s))
      : this.countriesList;
  }*/
  // FIN AUX Paises
}

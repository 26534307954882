import { Component, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'settings-credits',
  templateUrl: './settings-credits.html',
  styleUrls: ['./settings-credits.scss'],
})

export class SettingsCreditsComponent implements OnInit{

    account_type;

    @ViewChild('transPag') 
    transPag: MatPaginator;
    transactions: MatTableDataSource<any>;

    transactions_columns = ['date', 'type', 'info', 'quantity'];
    transaction_type;

    ref_credits;
    pub_credits;
    pay_credits;

    constructor( 
        public _user: UserServices, 
        private _utils: Utils, 
    ) {
        this.transaction_type = _utils.transaction_type;
        this.account_type = _utils.account_type;
    }

    ngOnInit(){
        this._load();
    }

    _load(){
        //Load transactions
        this._user.getTransactions().toPromise()
        .then(response =>{
            this.transactions = new MatTableDataSource(response['regs']);
            this.transactions.paginator = this.transPag;
            this._getTotalRefCredits();
            this._getTotalPubCredits();
            this._getTotalPayCredits();
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar historial de transacciones", 3000);
        })
    }

    _getTotalRefCredits(){
        var credits = 0;
        this.transactions.data.forEach(reg => {
            if(reg.type == this._utils.transaction_type.REF_PAY)
            credits += reg.quantity;
        });
        this.ref_credits = credits;
    }

    _getTotalPubCredits(){
        var credits = 0;
        this.transactions.data.forEach(reg => {
            if(reg.type == this._utils.transaction_type.REVIEW_PAY)
            credits += reg.quantity;
        });
        this.pub_credits = credits;
    }

    _getTotalPayCredits(){
        var credits = 0;
        this.transactions.data.forEach(reg => {
            if(reg.type == this._utils.transaction_type.WITHDRAW)
            credits += Math.abs(reg.quantity);
        });
        this.pay_credits = credits;
    }

    _confirmPayRequest(credits){
        //Open confirmation
        this._utils.openConfirmation(
            "Se van a retirar " + credits + " créditos de su cuenta por valor de " + (credits / this._utils.credits_eur).toFixed(2) + " €" +
            ". Dejarán de estar disponibles para realizar operaciones en Imperatool.",
            "Sí",
            "No",
            ()=>{
                //Send request
                this._user.requestPayment().toPromise()
                .then(response =>{
                    //Update user credits
                    this._user.setLocalUser(response['user']);
                    
                    //Update transactions
                    this._load();

                    this._utils.showSuccess("Pago solicitado correctamente. El próximo día 15 se procesarán todos los pagos pendientes.", 5000);
                })
                .catch(error =>{
                    console.log(error);
                    this._utils.showError("Error al enviar solicitud, inténtelo más tarde o contacte con el soporte.", 5000);
                })
            },
            ()=>{
                this._utils.showError("Cobro cancelado.", 3000);
            }
        );
    }

    payRequest(){
        //Check info
        this._user.getUser()
        .then((user: any) =>{
            if(
                !user.billName ||
                !user.cif ||
                !user.country ||
                !user.address ||
                !user.paypalEmail
            ){
                this._utils.showError('No ha introducido todos los datos de pago, puede hacerlo desde la pestaña de "Editar datos".', 5000);                
            }
            else{
                //Calculate credits to pay
                var credits = this.pub_credits + this.ref_credits - this.pay_credits;
                
                //Check minimun
                if(credits < this._utils.min_credits_pay){
                    this._utils.showError("La cantidad mínima para solicitar un cobro es de 250 créditos.", 5000);
                }
                else{
                    //Valid payment
                    this._confirmPayRequest(credits);
                }
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al comprobar datos de usuario.", 4000);
        });      
    }

}

import { Component, OnInit } from '@angular/core';
import {Utils} from '../../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { GoogleServices } from 'src/app/services/google-service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'tools-calc',
  templateUrl: './tools-calc.component.html',
  styleUrls: ['./tools-calc.component.scss']
})

export class ToolsCalculatorComponent implements OnInit {

    public profiles;
    public profileSelected;

    params;
    profile;

    add_reviews = [];

    result;


    constructor(
        private _utils: Utils,
        private _user: UserServices,
        private _google: GoogleServices,
        private _admin: AdminService
    ) {
        this.params = { quantity: 0, stars: 5 };
    }

    ngOnInit() {

        //Load profiles
        this._user.loadProfiles()
        .then(profiles => {
            this.profiles = profiles;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });

    }

    loadProfile(){
        this.profile = null;
        this._google.getProfile(this.profileSelected._id).toPromise()
        .then(response =>{
            this.profile = response['profile'];
            console.log("Loaded Profile", this.profile)
            this.clear();
        })
        .catch(error =>{
            console.log(error)
            this._utils.showError("Error al cargar datos del perfil", 5000);
        });
    }

    fixNumber(){
        if(this.params.quantity < 0) this.params.quantity = 0;
    }

    setStars(n){
        this.params['stars'] = n;
    }

    calcRating(old_rating_string, old_reviews, new_rating, new_reviews){

        var k = Number(new_rating);
        var j = Number(new_reviews);
        var or = Number.parseFloat(old_rating_string+"".replace(',', '.'));
        var c = Number(old_reviews);

        var rating = (k * j + c * or) / (c + j);

        return rating.toFixed(2) + "";
    }


    addReviews(){
        const rating = this.params.stars;
        const quantity = this.params.quantity;

        let calc_reviews = Number(this.profile.reviews);

        for(let add of this.add_reviews){
            calc_reviews += add.quantity;
        }

        this.add_reviews.push({ stars: rating, quantity: quantity });

        this.result = this.calcRating(this.result, calc_reviews, rating, quantity);
    }


    clear(){
        this.result = this.profile.rating;
        this.add_reviews = [];
    }

}

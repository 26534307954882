import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource, MatSelectionListChange } from '@angular/material';

import { Utils } from '../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'panel-orders',
  templateUrl: 'panel-orders.component.html',
  styleUrls: ['./panel-orders.component.scss'],
})

export class PanelOrdersComponent implements OnInit {


    @ViewChild('subPag') 
    subPag: MatPaginator;
    
    @ViewChild('orderPag') 
    orderPag: MatPaginator;
    
    orders: any;
    subs: any;

    //Loading
    public loading: boolean = false;

    //Pagination
    public page: number;
    public size: number;
    public total: number;
    public totalPages: number;
    public filter: any;

    public sub_status;
    public order_status;

    sub_columns = ['id', 'profiles', 'date', 'last_paid', 'rw_monthly', 'rw_published', 'rw_total', 'status'];
    order_columns = ['id', 'date', "service_name", "service_type", "quantity", "price", "payment",  "status"];
  
    constructor(
        public dialog: MatDialog,
        private _utils: Utils,
        private _user: UserServices,
        private _shop: ShopService
    ){ 
        this.sub_status = this._utils.sub_status;
        this.order_status = this._utils.order_status;
    }

    async ngOnInit() {

        this.page = 0;
        this.size = 5;
        this.filter = {};
        this.total = 0;
        this.totalPages = 0;

        //Load orders
        this.getOrders();
        
    }

    getOrders(){

        //Only one request at a time
        if(this.loading) return;

        this.loading = true;

        //Save params for restore
        const page = this.page;
        const size = this.size;
        const filter = this.filter;

        this._user.searchOrders(page, size, filter)
        .then(async result => {

            console.log(`Page: ${page}, Size: ${size}, Filter:`, filter);

            this.orders = result['orders'];
            this.total = result['total'];
            this.totalPages = Math.ceil(this.total / size);

            await this.getSubs();

            //Restore params in case they have changed
            this.page = page;
            this.size = size;
            this.filter = filter;
            this.loading = false;
        })
        .catch(error =>{
            console.log(error);
            this.loading = false;
            this._utils.showError("Error al cargar los pedidos", 5000);
        })

    }

    getSubs(){

        const order_ids = this.orders.map(order => order._id);

        return this._user.searchSubs({
            order_id: { $in: order_ids }
        })
        .then(result => {
            const subs = result['subs'];
            this.orders.map(order => {
                if(order.sub){
                    order.sub = subs.find(sub => sub.order_id == order._id) || true;
                }
            })
        })
        .catch(error =>{
            console.log(error);
            this.loading = false;
            this._utils.showError("Error al cargar las suscripciones", 5000);
        })
    }

    filterByType(type){

        if(this.filter['service_snapshot.service_type'] == type){
            this.filter = {};
            this.getOrders();
            return;
        }
        
        this.filter = { 'service_snapshot.service_type': type };
        this.getOrders();
    }

    backPage(){
        this.page--;
        this.getOrders();
    }

    nextPage(){
        this.page++;
        this.getOrders();
    }

    parseDate(date){
        return new Date(date).toLocaleDateString();
    }

    getCreditsExpirationDate(order){
        let date = new Date(order.date);
        date.setDate(date.getDate() + 186);
        return date.toLocaleDateString();
    }

    getAnswersActiveDate(order){
        if(!order.sub || !order.sub.last_paid) return 'Sin activar';
        let date = new Date(order.sub.last_paid);
        date.setDate(date.getDate() + 32);
        return date.toLocaleDateString();
    }

    cancelOrder(order){
    
        this._shop.cancelOrder(order._id)
        .then(result => {
            this._utils.showSuccess("Pedido cancelado con éxito", 5000);
            order.status = this.order_status.CANCELED;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cancelar pedido", 5000);
        })
    
    }

    openDialog(sub): void {
        const dialogRef = this.dialog.open(AddProfileDialogComponent, {
        width: 'auto',
        data:  {sub: sub}
        });

        dialogRef.afterClosed().subscribe(result => {
        if(result){
            //Reload orders
            this.getOrders();
        }
        });
    }

    removeProfile(sub, profile){
        sub.profiles.splice(sub.profiles.indexOf(profile), 1);
        this._user.updateSub(sub).toPromise()
        .then(result => {
            this._utils.showSuccess("Suscripción actualizada con éxito", 5000);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar suscripción", 5000);
        })
    }

    downloadInvoice(order_id){

        document.body.style.cursor = 'progress';
        this._utils.showSuccess("Obteniendo factura, esto puede llevar hasta 1 minuto. Espere por favor...", 7000)

        this._shop.downloadInvoice(order_id)
        .then( (res: any) =>{
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        })
        .catch(error => {
            console.log(error)
            this._utils.showError("No es posible obtener la factura de manera automática. Puede solicitarla manualmente al email info@imperatool.com")
        })
        .finally(()=>{
            document.body.style.cursor = 'default';
        })

    }

}

@Component({
  selector: 'add-profile',
  templateUrl: 'add-profile-dialog.html',
  styleUrls: ['./add-profile-dialog.scss'],
})

/* ADD PROFILE DIALOG */
export class AddProfileDialogComponent implements OnInit{

  public profiles;
  public selectedProfiles;

  constructor(
    public dialogRef: MatDialogRef<AddProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _utils: Utils,
    private _user: UserServices
  ) {};

    ngOnInit(){
        //Get all profiles
        this._user.getProfiles().toPromise()
        .then(result => {
            
            //Save only id and name
            this.profiles = result['profiles'].map(each => { return {
                _id: each._id, 
                name: each.name
            } } );

            //Load selected profiles
            this.selectedProfiles = this.profiles.map(each => { 
                for (const profile of this.data.sub.profiles) {
                    if(profile._id == each._id) return each;
                }
            });

        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });
    }

    link(){

        this.data.sub.profiles = this.selectedProfiles;

        this._user.updateSub(this.data.sub).toPromise()
        .then(result => {
            this._utils.showSuccess("Suscripción actualizada con éxito", 5000);
            this.dialogRef.close(true);
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar suscripción", 5000);
        })
    }

    selectionChange(event: MatSelectionListChange){
        //Only one profile for answers
        if(this.data.sub.type == 'answers' && this.selectedProfiles.length !== 0)
            this.selectedProfiles = [event.option.value];
    }

}
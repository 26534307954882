import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class ShopService {

    public serviceCache;
  
    constructor(private _utils : Utils, private _http: HttpClient) { }

    createOrder(order){
        try{ this._utils.pixelEvent("InitiateCheckout", { service: this.serviceCache.name }); }
        catch(e){ console.log(e) }

        return this._http.post(this._utils.backURL + '/api/shop/new-order', order, this._utils.jwt()).pipe();
    }

    createOrderAdmin(buyer_id, order){

        const data = {
            buyer_id: buyer_id,
            order: order
        }

        return this._http.post(this._utils.backURL + '/api/shop/new-order-admin', data, this._utils.jwt()).toPromise();
    }

    // payOrder(paypalData){
    //     try{
    //         var orderCache = JSON.parse(localStorage.getItem('orderCache'));
    //         this._utils.pixelEvent("Purchase", 
    //         {
    //             currency: 'EUR',
    //             value: orderCache.price * orderCache.units,
    //             sub: orderCache.sub,
    //             service: orderCache.service_snapshot.name            
    //         });
    //     }catch(e){ console.log(e) }

    //     return this._http.post(this._utils.backURL+ '/api/shop/pay', paypalData, this._utils.jwt()).pipe();
    // }
    
    // paySub(paypalData){
    //     try{
    //         var orderCache = JSON.parse(localStorage.getItem('orderCache'));
    //         this._utils.pixelEvent("Purchase", 
    //         {
    //             currency: 'EUR',
    //             value: orderCache.price * orderCache.units,
    //             sub: orderCache.sub,
    //             service: orderCache.service_snapshot.name            
    //         });
    //         this._utils.pixelEvent("Subscribe", {});
    //     }catch(e){ console.log(e) }
        
    //     return this._http.post(this._utils.backURL + '/api/shop/subscribe', paypalData, this._utils.jwt()).pipe();
    // }

    getServices(){
        return this._http.get(this._utils.backURL + '/api/shop/service').pipe();
    }

    checkDiscount(code){
        return this._http.get(this._utils.backURL + '/api/shop/discount/' + code).pipe();
    }

    downloadInvoice(order_id){

        const url = `${this._utils.backURL}/api/shop/order/${order_id}/invoice`;

        let options = this._utils.jwt();
        options['responseType'] = 'blob';

        return this._http.get(url, options).toPromise();

    }

    paymentCompleted(orderID: any, body = null) {
        return this._http.post(
            `${this._utils.backURL}/api/shop/order/${orderID}/paid`,
            body, 
            this._utils.jwt()
        ).toPromise();
    }

    cancelOrder(orderID: any) {
        return this._http.post(
            `${this._utils.backURL}/api/shop/order/${orderID}/cancel`,
            null, 
            this._utils.jwt()
        ).toPromise();
    }

}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-users',
  templateUrl: './admin-users.html',
  styleUrls: ['./admin-users.scss'],
})

export class AdminUsersComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    public loading;
    public credit_stats;
    
    //TABLE
    public users: MatTableDataSource<any>;
    @ViewChild('userPag') paginator: MatPaginator;
    public userColumns = ['id', 'ref_id', 'date', 'type', 'name', 'bill', 'email', 'paypal', 'credits', 'actions'];
    public totalItems = 0;
    public page = 0;
    public size = 10;

    //FILTER
    public filter: any = {};
    private default_filter = { search: '', order: 'id' };


    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _utils: Utils,
    ) {
        Object.assign(this.filter, this.default_filter);
    }


    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'usr' && !this.loaded){
                this.loaded = true;
                this.loadUsers();
                this._loadStats();
            }
        });
    }


    public clearFilter(){
        this.filter = {};
        Object.assign(this.filter, this.default_filter);
        this.loadUsers();
    }


    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */


    public loadUsers(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        let order = this._getOrder();

        this._admin.getUsers(this.size, this.page, query, order)
        .then(response => {
            this._loadTable(response['data'], response['total']);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar usuarios");
        })

    }


    private async _loadTable(data, total){

        let placeholder = new Array(total)

        let startItem = this.page * this.size;

        //Reset to page 0 if total is smaller
        if(startItem >= total){
            this. page = 0;
            startItem = 0;
        }

        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = data[i];
        }

        this.users = new MatTableDataSource(placeholder);
        this.users.paginator = this.paginator;

        this.loading = false;

    }


    private _loadStats(){
        this._admin.getCreditsStats().toPromise()
        .then(result =>{
            this.credit_stats = result;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar stats", 3000);
        })
    }


    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadUsers(true);

    }


    private _parseFilter(){

        let query: any = {};

        //Flags
        if(this.filter.admin) query.admin = true;
        if(this.filter.agency) query.agency = true;
        if(this.filter.partner) query.partner = true;
        if(this.filter.billing) query.billing = true;
        if(this.filter.bl) query.bl = true;
        if(this.filter.email_ignore) query.email_ignore = true;
        if(this.filter.hard_ban) query.hard_ban = true;
        if(this.filter.multiFP) query.multiFP = true;

        //Type
        if(this.filter.type) query.type = this.filter.type;

        //Search
        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {ref_id: num },

                //Texts
                {profile_groups: regex },
                {fps: regex },
                {name: regex },
                {email: regex },
                {paypalEmail: regex },
                {billingEmail: regex },
                {billName: regex },
                {cif: regex },
                {address: regex },
                {cp: regex },
                {city: regex },
                {country: regex },

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }


    private _getOrder(){

        switch(this.filter.order){

            case 'id': return { name: 'creation_date', value: -1 }; break;
            case 'credits': return { name: 'credits', value: -1 }; break;
            default: return null;

        }

    }


    /*
    |--------------------------------------------------------------------------
    | ACTIONS
    |--------------------------------------------------------------------------
    */

    activateAgency(user){
        this._admin.activateAgency(user._id).toPromise()
        .then(response =>{
            Object.assign(user, response['user']);
            this._utils.showSuccess("Usuario actualizado", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al banear usuario", 3000);
        })
    }


    ban(user){
        this._admin.ban(user._id).toPromise()
        .then(response =>{
            user.bl = true;
            this._utils.showSuccess("Usuario añadido a Black List", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error añadir usuario a Black List", 3000);
        })
    }


    hardBan(user){
        console.log("Hard ban user", !user.hard_ban)
        this._admin.hardBan(user._id, !user.hard_ban).toPromise()
        .then(response =>{
            user.hard_ban = !user.hard_ban;
            this._utils.showSuccess("Usuario actualizado", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar usuario", 3000);
        })
    }


    unban(user){
        this._admin.unban(user._id).toPromise()
        .then(response =>{
            user.bl = false;
            this._utils.showSuccess("Usuario eliminado de Black List", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al eliminar usuario de Black List", 3000);
        })
    }
    

    uploadMT(user){
        this._admin.uploadMT(user._id).toPromise()
        .then(response =>{
            this._utils.showSuccess("Usuario actualizado en MT", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar usuario", 3000);
        })
    }

}

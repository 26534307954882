import { Component, Input } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.html',
  styleUrls: ['./star-rating.scss']
})
export class StarRatingComponent {
  @Input() rating: number | undefined;

  get filledStars(): number[] {
    return Array(Math.floor(this.rating)).fill(0);
  }

  get emptyStars(): number[] {
    return Array(Math.ceil(5 - this.rating)).fill(0);
  }
}
import { Injectable } from '@angular/core';
import  es  from '../../i18n/es.json';
import  en  from '../../i18n/en.json';

@Injectable()
export class CustomTranslator {

    //Available translations
    languages = {
        'es' : es,
        'en' : en
    }

    //Base text
    base = es;

    //Default translation
    translation = es;


    constructor() {}

    load(language){
        for (const lan in this.languages) {
            if(lan == language)this.translation = this.languages[lan];
        }
    }

    trans(text){
        for (const entry in this.base) {
            if(this.base[entry] === text) return this.translation[entry];
        }
        console.log("Text not found", text);
        return text;
    }

    get(key){
        return this.translation[key];
    }

}

import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { UserServices } from 'src/app/services/user.service';
import {FormControl, Validators} from '@angular/forms';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
  selector: 'settings-edit',
  templateUrl: './settings-edit.html',
  styleUrls: ['./settings-edit.scss'],
})

export class SettingsEditComponent implements OnInit{
    
    private PWD_MIN_LENGTH = 6;
    private PWD_MAX_LENGTH = 12;

    //Form
    emailCtrl = new FormControl('', [ Validators.required, Validators.email ]);
    paypalCtrl = new FormControl('', [ Validators.email ]);
    billingEmailCtrl = new FormControl('', [ Validators.email ]);
    passCtrl = new FormControl( '', [ Validators.minLength(this.PWD_MIN_LENGTH), Validators.maxLength(this.PWD_MAX_LENGTH) ] );

    account_type;
    user;

    confPass;

    countries;
    countrySelected;

    constructor( 
        public _i18n: CustomTranslator,
        private _user: UserServices, 
        private _utils: Utils, 
    ) {
        this.account_type = _utils.account_type;
    }

    ngOnInit(){
        this._load();
    }

    _load(){
        //Load user data
        this._user.getUser()
        .then(user =>{
            this.user = user;
            this._loadValues();
            this._loadCountries();
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar los datos de usuario", 3000);
        })
    }

    _loadCountries(){
        this._utils.getCountries().toPromise()
        .then((countries: Array<any>) =>{
            countries.sort((a, b)=>{
                if(a.name == this.user.country && this.countrySelected == null) this.countrySelected = a;
                var a_value: String = a.translations.es? a.translations.es : a.name;
                var b_value = b.translations.es? b.translations.es : b.name;
                return a_value.localeCompare(b_value);
            })
            this.countries = countries;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de paises", 5000);
        })
    }

    _loadValues(){
        this.emailCtrl.setValue(this.user.email);
        this.paypalCtrl.setValue(this.user.paypalEmail);
        this.billingEmailCtrl.setValue(this.user.billingEmail? this.user.billingEmail : this.user.email);
    }

    update(){

        //Check validators
        if(
            this.emailCtrl.invalid ||
            this.paypalCtrl.invalid ||
            this.billingEmailCtrl.invalid ||
            this.passCtrl.value && this.passCtrl.invalid
        ){
            return;
        }        
        else{
            this.user.email = this.emailCtrl.value;
            this.user.paypalEmail = this.paypalCtrl.value;
            this.user.billingEmail = this.billingEmailCtrl.value;
        }

        //Check password
        if(this.passCtrl.value){
            if(this.passCtrl.value !== this.confPass){
                this._utils.showError("Las contraseñas no coinciden", 5000);
                return;
            }
            this.user.password = this.passCtrl.value;
        }

        //Country
        if(this.countrySelected){
            this.user.country = this.countrySelected.name;
        }

        //Update
        this._user.update(this.user).toPromise()
        .then(response =>{
            this.user = response['user'];
            this._loadValues();
            this._utils.showSuccess("Datos actualizados correctamente.", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al actualizar usuario", 3000);
        })


    }

}

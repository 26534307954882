import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[autoResize]'
})
export class AutoResizeDirective {

  constructor(private elementRef: ElementRef) { }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  ngOnInit(): void {
    setTimeout(() => this.adjust(), 0);
  }

  adjust(): void {
    const textArea = this.elementRef.nativeElement as HTMLTextAreaElement;
    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
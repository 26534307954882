import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleServices } from 'src/app/services/google-service';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'edit-gmbaccount',
    templateUrl: 'edit-gmbaccount.html',
    styleUrls: ['./edit-gmbaccount.scss']
})

export class EditGMBAccountDialog implements OnInit{

    countries;
    countrySelected;

    cities;
    citySelected;

    account: any = {};

    constructor(
        @Inject(MAT_DIALOG_DATA) public input: any,
        private dialog: MatDialogRef<EditGMBAccountDialog>,
        private _gmb: GoogleServices,
        private _utils: Utils
    ) {
        Object.assign(this.account, input.account);
        this.countries = input.countries;
    }

    ngOnInit(){
        //Identify account country
        for (const country of this.countries) {
            if(country.name == this.account.country){
                this.countrySelected = country;
                //Load cities
                this.countryChange();
                return;
            }
        }
    }

    countryChange(){
        this._utils.getCities(this.countrySelected.alpha2Code)
        .then(cities =>{
            this.cities = cities;
            //Identify account city
            for (const city of this.cities) {
                if(city.name == this.account.city){
                    this.citySelected = city;
                    return;
                }
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de ciudades", 5000);
        })
    }

    save(){

        this.account.city = this.citySelected.name;
        this.account.country = this.countrySelected.name;

        this._gmb.editGMBAccount(this.account).toPromise()
        .then(response =>{
            this._utils.showSuccess("Cuenta modificada correctamente", 5000);
            this.dialog.close({account: response['account']});
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Código incorrecto", 5000);
        })
    }
    
    close(){
        this.dialog.close();
    }
}
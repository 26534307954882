import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Utils } from 'src/app/services/utils';
import { AdminService } from 'src/app/services/admin.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserServices } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-profiles',
  templateUrl: './admin-profiles.html',
  styleUrls: ['./admin-profiles.scss'],
})

export class AdminProfilesComponent implements OnInit{

    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;

    public loading;
    
    //TABLE AUX
    public inactive_profiles: MatTableDataSource<any>;
    @ViewChild('inactivePag') inactivePag: MatPaginator;
    public inactiveColumns = ['id', 'name', 'location', 'user', 'place_id', 'rating', 'img', 'actions'];
    
    //FILTER
    public filter: any = {};
    private default_filter = { search: '' };

    //TABLE
    public active_profiles: MatTableDataSource<any>;
    @ViewChild('paginator') paginator: MatPaginator;
    public profileColumns = ['id', 'name', 'location', 'user', 'sub', 'state'];
    public totalItems = 0;
    public page = 0;
    public size = 10;

    constructor( 
        private _admin: AdminService,
        private _user: UserServices,
        private _utils: Utils,
    ) {
        Object.assign(this.filter, this.default_filter);
    }

    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'profile' && !this.loaded){
                this.loaded = true;
                this.loadProfiles();
            }
        });
    }


    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */

    public loadProfiles(sameQuery = false){

        //Load active profiles
        if(!sameQuery) this.page = 0;

        this.loading = true;

        let query = this._parseFilter();

        this._admin.getGMBProfiles(this.size, this.page, query)
        .then(response => {
            this._loadTable(response['data'], response['total']);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar perfiles");
        })


        //Load manual profiles
        this._admin.getGMBManualProfiles()
        .then(async response => {
            let data = response['data'];
            await this._fillUsers(data);
            this.inactive_profiles = new MatTableDataSource(data);
            this.inactive_profiles.paginator = this.inactivePag;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar perfiles manuales");
        })

    }


    private async _loadTable(data, total){

        let placeholder = new Array(total)

        //Load users and services
        await this._fillUsers(data);

        let startItem = this.page * this.size;

        //Reset to page 0 if total is smaller
        if(startItem >= total){
            this. page = 0;
            startItem = 0;
        }

        for(let i=0; i<this.size; i++){
            if(startItem + i < total) placeholder[startItem + i] = data[i];
        }

        this.active_profiles = new MatTableDataSource(placeholder);
        this.active_profiles.paginator = this.paginator;

        this.loading = false;

    }


    private async _fillUsers(data){

        //Get ids
        let ids = data.map(item => item.user_id);

        let response = await this._admin.getUsers(ids.length, 0, {_id: {$in: ids}});

        //Fill items with corresponding user
        let users = response['data'];
        data.map(item => item.user = this._findUser(item.user_id, users));

    }


    private _findUser(id, users){

        for(let user of users) if(user._id == id) return user;

    }


    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.loadProfiles(true);

    }


    private _parseFilter(){

        let query: any = {};

        //Search
        if(this.filter.search){

            const num = Number.parseInt(this.filter.search)? Number.parseInt(this.filter.search) : -1;
            const regex = { $regex: this.filter.search, $options: 'i' }

            let search = { $or: [

                //IDs
                {_id: num },
                {user_id: num },
                {sub_id: num },

                //Texts
                {place_id: this.filter.search },
                {name: regex },
                {country: regex },
                {city: regex },
                {group: regex },

            ] }

            query = { $and: [query, search] };

        }

        return query;

    }

    /*
    |--------------------------------------------------------------------------
    | ACTIONS
    |--------------------------------------------------------------------------
    */

    public checkProfile(profile){

        this._admin.checkProfile(profile._id, profile.place_id, profile.rating, profile.img).toPromise()
        .then(result =>{
            this.loadProfiles();
            this._utils.showSuccess("Perfil activado correctamente", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al activar perfil", 3000);
        })
    }


    public deleteProfile(profile){

        this._admin.deleteProfile(profile._id)
        .then(result =>{
            this.loadProfiles();
            this._utils.showSuccess("Perfil eliminado correctamente", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al eliminar perfil", 3000);
        })

    }


    public requestInfo(profile){

        this._admin.requestProfileInfo(profile._id)
        .then(result =>{
            this._utils.showSuccess("Solicitud enviada correctamente", 3000);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al enviar solicitud", 3000);
        })

    }

}

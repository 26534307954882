import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AdminService } from 'src/app/services/admin.service';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'admin-answers',
    templateUrl: './admin-answers.html',
    styleUrls: ['./admin-answers.scss'],
})

export class AdminAnswersComponent implements OnInit{
  
    @Input('load') lazyLoad: Observable<String>;
    loaded: boolean;
  
    public loading;

    //FILTER
    public filter: any = {};

    //TABLE
    public columns = ['id', 'profile', 'rw', 'text'];
    public totalItems = 0;
    public page = 0;
    public size = 10;
    public data: MatTableDataSource<any>;
    @ViewChild('paginator') paginator: MatPaginator;

    constructor( 
        private _admin: AdminService,
        private _utils: Utils,
    ) {
        this['parseDate'] = this._utils.parseDate;
        this['parseDateTime'] = this._utils.parseDateTime;
    }

    ngOnInit(){
        this.lazyLoad.subscribe((tabName)=>{
            if(tabName == 'answer' && !this.loaded){
                this.loaded = true;
                this.load();
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    | LOADERS
    |--------------------------------------------------------------------------
    */

    public load(sameQuery = false){

        if(!sameQuery) this.page = 0;

        this.loading = true;
        this._admin.getAnswers(this.page, this.size)
        .then((res:any)=>{
            this.totalItems = res.total;
            this.data = new MatTableDataSource(res.data);
            this.data.paginator = this.paginator;
            this.loading = false;
        })
    }  

    /*
    |--------------------------------------------------------------------------
    | PAGINATOR
    |--------------------------------------------------------------------------
    */

    public pageEvent(event){

        this.page = event.pageIndex;
        this.size = event.pageSize;

        this.load(false);

    }

}
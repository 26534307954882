import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class AnswersServices {
  
    constructor(private _http: HttpClient, private _utils : Utils) { }

    /*
    |-------------
    | METHODS
    |-------------
    */

    getAnswers(profileID=null, page=0, limit=10){

        let url = `${this._utils.backURL}/api/answers?page=${page}&limit=${limit}`;
        if(profileID) url += `&profileID=${profileID}`;

        return this._http.get(url, this._utils.jwt()).toPromise();
    }

    getManageUrl(profileID){
        return this._http.get(
            `${this._utils.backURL}/api/answers/profile/${profileID}/manage-url`,
            this._utils.jwt()
        ).toPromise();
    }

    editAnswer(answer){
        return this._http.put(
            `${this._utils.backURL}/api/answers/${answer._id}`,
            {text: answer.text},
            this._utils.jwt()
        ).toPromise();
    }

    publishAnswer(answer){
        return this._http.post(
            `${this._utils.backURL}/api/answers/${answer._id}/publish`,
            {},
            this._utils.jwt()
        ).toPromise();
    }

    editProfilePreferences(profileID, preferences){
        return this._http.put(
            `${this._utils.backURL}/api/answers/profile/${profileID}/preferences`,
            preferences,
            this._utils.jwt()
        ).toPromise();
    }

    showTutorial(){
        return this._http.get(
            `${this._utils.backURL}/api/answers/intro`,
            this._utils.jwt()
        ).toPromise();
    }

}
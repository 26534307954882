import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserServices } from 'src/app/services/user.service';
import { Utils } from 'src/app/services/utils';

@Component({
    selector: 'answers-slider',
    templateUrl: './answers-slider.html',
    styleUrls: ['./answers-slider.scss']
})

export class AnswersSliderComponent implements OnInit{
    
    @Input('toggle') toggle: Function;

    public sub_active = false;
    
    constructor(
        private _user: UserServices, 
        private _utils: Utils,
        private _router: Router
    ) {}
    
    ngOnInit(){
    
        this._scrollHandler();
    
        this._loadAutoScroll();
        
        this._user.getSubs().toPromise()
        .then(response =>{
    
            let subs = response['subs'];
            for(let sub of subs){
                if(sub.status == this._utils.sub_status.ACTIVE){
                    //TODO [For 1.0] sub.type === 'answers'
                    this.sub_active = true;
                    break;
                }
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al comprobar suscripciones activas", 5000);
        })
    }

    //CSS helper
    public chatBubbles = Array.from({ length: 23 }, (_, index) => ({
        id: index,
        duration: this.getRandomDuration(),
        top: this.getRandomTop(),
        left: this.getRandomLeft(),
        delay: 0//index * 0.1
    }));
    
    private getRandomDuration(): string {
        const duracionAleatoria = Math.random() * 10 + 5; // Duración entre 15 y 10 segundos
        return duracionAleatoria + 's';
    }

    private getRandomTop(): string {
        const topAleatorio = Math.random() * 40 + 70; // Top
        return topAleatorio + '%';
    }
    
      private getRandomLeft(): string {
        const leftAleatorio = Math.random() * 100; // Cualquier valor de left
        return leftAleatorio + '%';
    }

    public lastSlide = false;

    private _nextTs = 0;

    private _nextSlide():void{

        //Ignore multiple moves on a 1.5s period (animation last for 2s)
        if(new Date().getTime() < this._nextTs + 1500) return;

        //Update timestamp on the last handle
        this._nextTs = new Date().getTime();

        const slideActive = document.querySelector('.slide.active');
        const nextSlide = slideActive.nextElementSibling;
        if(!nextSlide || !nextSlide.classList.contains('slide')) return;

        slideActive.classList.remove('active');
        slideActive.classList.add('moveUp');
        nextSlide.classList.add('active');

        if(!nextSlide.nextElementSibling || !nextSlide.nextElementSibling.classList.contains('slide')) this.lastSlide = true;

    }

    private _backTs = 0;

    public backSlide():void{

        //Ignore multiple moves on a 1.5s period (animation last for 2s)
        if(new Date().getTime() < this._backTs + 1500) return;

        //Update timestamp on the last handle
        this._backTs = new Date().getTime();

        const slideActive = document.querySelector('.slide.active');
        const nextSlide = slideActive.previousElementSibling;
        if(!nextSlide || !nextSlide.classList.contains('slide')) return;

        slideActive.classList.remove('active');
        nextSlide.classList.remove('moveUp');
        nextSlide.classList.add('active');

        this.lastSlide = false;

    }

    //End CSS

    //Scroll Handler
    private _scrollHandler(): void{

        const scrollContainer: HTMLElement = document.querySelector('.scrollContainer');

        const parent_env = this;
    
        function scrollHandler(event) {

            parent_env._userInteract = true;

            //Scroll bottom
            if (event.deltaY > 0) {
                parent_env._nextSlide();
            }
            
            //Scroll top
            if (event.deltaY < 0) {
                parent_env.backSlide();
            }

        }
    
        scrollContainer.onwheel = scrollHandler;

        //Swipe handler
        let touchStartY = 0;

        function handleTouchStart(event) {
            touchStartY = event.touches[0].clientY;
            parent_env._userInteract = true;
        }

        function swipeHandler(event) {

            event.preventDefault();

            const touchEndY = event.touches[0].clientY;
            const deltaY = touchEndY - touchStartY;
            
            // Scroll bottom
            if (deltaY < 0) {
                parent_env._nextSlide();
            }

            // Scroll top
            if (deltaY > 0) {
                parent_env.backSlide();
            }

            // Reset touchStartY to avoid multiple swipes with one touch
            touchStartY = touchEndY;
            

        }

        // Attach touch events for mobile swipe
        scrollContainer.addEventListener('touchstart', handleTouchStart);
        scrollContainer.addEventListener('touchmove', swipeHandler);

    }

    //Auto scroll
    private _userInteract = false;

    public nextSlideClick():void{
        this._userInteract = true;
        this._nextSlide();
    }

    private _loadAutoScroll(){

        let interval = setInterval(()=>{

            if(this._userInteract || this.lastSlide) return clearInterval(interval);

            this._nextSlide();

        },7000)

    }

    //Beta gift
    public giftOpen = false;

    //Beta Action
    public chooseProfile(){
        this.giftOpen = true;
        document.body.style.cursor='wait'
        setTimeout(()=>{
            document.body.style.cursor='default';
            this.sub_active? this.toggle() : this._router.navigate(['/es/dashboard/shop'])
        },1500)
    }

    public examples = [
        {
            review: {
                rating: 3,
                text: `Amplio restaurante, con terraza climatizada. Servicio correcto.  Croquetas: MB. Tortilla trufa: B. Entrecot: B.
                Relación calidad precio: B.`
            },
            answer: `¡Muchas gracias por tu reseña! Nos alegra que disfrutaras de nuestras croquetas y que te haya gustado la terraza climatizada. 
            Agradecemos tus valoraciones y trabajaremos para que en tu próxima visita, la tortilla de trufa y el entrecot pasen de buenos a excelentes. 
            ¡Te esperamos pronto!`
        },
        {
            review: {
                rating: 1,
                text: `Llames a la hora que llames o te salta el contestador diciéndote el horario de atención a las llamadas 
                (la llamada está hecha dentro de ese horario) o está comunicando o te cuelgan directamente, estupenda gestión...`
            },
            answer: `Lamentamos la experiencia que ha tenido al tratar de comunicarse con nosotros. 
            Nos esforzamos por estar disponibles durante el horario de atención, pero a veces puede haber momentos de alta demanda que impiden que podamos atender todas las llamadas simultáneamente. 
            Apreciamos su feedback y trabajaremos para mejorar este aspecto. Gracias por tomarse el tiempo de compartir su reseña. `
        },
        {
            review: {
                rating: 5,
                text: `Contratamos un viaje a Punta Cana y todo fue muy bien. Buen trato, atendieron a todas nuestras dudas y estuvieron pendientes de los trámites.`
            },
            answer: `¡Hola Sara! Nos alegra muchísimo que hayáis disfrutado de vuestro viaje a Punta Cana. 
            Siempre estamos aquí para resolver vuestras dudas y asegurarnos de que todo salga perfecto. 
            ¡Os esperamos para vuestra próxima aventura! Gracias por tu gran valoración.`
        },
    ]

}

import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';

import { Utils } from '../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { GoogleServices } from 'src/app/services/google-service';
import { AddSubDialogComponent } from '../profiles/panel-profiles.component';
import { timingSafeEqual } from 'crypto';
import { ImgService } from 'src/app/services/img.service';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
  selector: 'profile-detail',
  templateUrl: 'profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})

export class ProfileDetailComponent implements OnInit {

    error;

    review_price = 100;

    public review_status;

    columnsToDisplay = ['actions', 'date', 'stars', 'text', 'img', 'extras', 'city', "sex", "profile", "tries", "state"];

    textControl : FormControl;

    dateControl : FormControl;
    today : Date;

    public newReview = {
        stars : 5,
        sex : "xz",
        img : null,
        allow_rewrite: true
    }

    public extras = {
        lg: {value: false, cost: 20},
        chips: {value: false, cost: 7},
        credits: 0
    }

    private profile_id;

    public profile;

    public countrySelected;
    public countries;

    public citySelected;
    public cities;
    public citiesEdit;

    @ViewChild('reviewPag') 
    reviewPag: MatPaginator;
    reviews: MatTableDataSource<any>;

    //UI flags
    public promo_0: Boolean = false; //First review promo
  
    constructor(
        public _i18n: CustomTranslator,
        private _utils: Utils,
        private _user: UserServices,
        private _router: Router,
        private _google: GoogleServices,
        private _img: ImgService,
        public dialog: MatDialog,
        public dialogSubs: MatDialog
    ){
        this.review_status = this._utils.review_status;
        _router.events.forEach(event =>{
            if(event instanceof NavigationEnd) this._initComponent(false)
        });

        this.today = new Date();
    }


    ngOnInit() {
        this._initComponent(true)
    }


    _initComponent(first_time){

        this.promo_0 = false;

        let url = document.URL.split('/');
        if(!first_time && (url.length<2 || url[url.length-2] != 'profile' || url[url.length-1] == this.profile_id) )return;

        this.profile = null;
        this.countrySelected = null;
        this.countries = null;
        this.citySelected = null;
        this.cities = null;

        this.newReview.stars = 5;
        this.newReview.sex = "xz";
        this.newReview.img = null;
        this.newReview.allow_rewrite = true;
    
        this.extras = {
            lg: {value: false, cost: 20},
            chips: {value: false, cost: 7},
            credits: 0
        }

        this.textControl = new FormControl( '', [ Validators.required] );
        this.dateControl = new FormControl(new Date());

        this.profile_id = url[url.length-1];
        this._loadProfile(this.profile_id);
        this._loadReviews(this.profile_id);
        this._loadUI(this.profile_id);

    }

    _loadUI(id){
    
        this._google.allowFreeReview(id)
        .then(response =>{
            this.promo_0 = !!response['value'];
        })
        .catch(error =>{
            console.log("Error loading UI", error);
        })

    }


    countryChange(setDefault){
        
        this.citySelected = null;

        this._utils.getCities(this.countrySelected.alpha2Code)
        .then(cities =>{
            this.cities = cities;
            //Load default city
            if(setDefault){
                for (let i = 0; i < this.cities.length; i++) {
                    if(this.cities[i].name == this.profile.city){
                        this.citySelected = this.cities[i];
                        break;
                    }                    
                }
            }else{
                this.citySelected = this.cities[0];
            }
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de ciudades", 5000);
        })
    }

    compareCity(a,b){
        return a.name === b.name
    }


    countryChangeEdit(review){
        this._utils.getCities(review.countrySelected.alpha2Code)
        .then(cities =>{
            this.citiesEdit = cities;
            if(review.country == review.countrySelected.name){

                //Load default city
                for (const city of this.citiesEdit) {
                    if(city.name == review.city){
                        review.citySelected = city;
                        break;
                    }                    
                }

            }
            else review.citySelected = cities[0]
            
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de ciudades", 5000);
        })
    }

    
    _loadCountries(){
        this._utils.getCountries().toPromise()
        .then((countries: Array<any>) =>{
            countries.sort((a, b)=>{
                if(a.name == this.profile.country && this.countrySelected == null){this.countrySelected = a;this.countryChange(true)}
                var a_value: String = a.translations.es? a.translations.es : a.name;
                var b_value = b.translations.es? b.translations.es : b.name;
                return a_value.localeCompare(b_value);
            })
            this.countries = countries;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar la lista de paises", 5000);
        })
    }


    _loadProfile(id){
        this._user.getProfile(id).toPromise()
        .then(result =>{
            this.profile = result['profile'];
            //Once profile is loaded, load country list
            this._loadCountries();
            if(this.profile.sub_id)
            this._user.getSub(this.profile.sub_id).toPromise()
            .then(result =>{
                this.profile.sub = result['sub'];
            })
            .catch(error =>{
                console.log(error);
                this._utils.showError("Error al cargar subscripcion", 5000);
            })
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar perfil", 5000);
        })
    }


    _loadReviews(id){
        this._google.getReviews(id).toPromise()
        .then(result =>{
            this.reviews = new MatTableDataSource(result['reviews']);
            this.reviews.paginator = this.reviewPag;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar reseñas", 5000);
        })
    }


    parseDate(date){
        return new Date(date).toLocaleDateString();
    }


    setStars(value){
        this.newReview.stars = value;
    }


    loadImg(event){
        this.error = null;
        let reader = new FileReader();
        if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.newReview.img = reader.result
            };
        }
    }


    @ViewChild('imgInput') img_input: HTMLLIElement;

    clearImg(){
        this.error = null;
        this.newReview.img = null;
        console.log(this.img_input);
        this.img_input['nativeElement'].value = "";
    }


    editImg(event, review){
        let reader = new FileReader();
        if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                review.img = reader.result
            };
        }
    }


    confirmDelete(){
        this._utils.openConfirmation(
            "Va a eliminar el perfil de " + this.profile.name,
            "Eliminar Perfil",
            "Cancelar",
            ()=>{
                this._google.deleteProfile(this.profile_id).toPromise()
                .then(response =>{
                    this._utils.showSuccess("Perfil eliminado correctamente", 3000);
                    this._router.navigate(['/es/dashboard/profiles']);
                })
                .catch(error =>{
                    console.log(error);
                    this._utils.showError("Error al eliminar perfil, inténtalo más tarde.", 5000);
                })
            }, 
            ()=>{console.log("Delete cancelled")}
        );
    }


    /**
     * Create Review 
     *
     */
    async save(method){

        let image;

        if(this.newReview.img){
            try{
                image = await this._img.uploadImg(this.newReview.img).toPromise();
            }catch(e){
                this._utils.showError("Error al guardar imagen compruebe el tamaño o pruebe más tarde. Max: 1920×1080 y 6MB", 5000);
                this.error = {img: true};
                return;
            }
        }


        //Load data
        var data = {
            profile_id: this.profile._id,
            rating: this.newReview.stars,
            text: this.textControl.value,
            img: image? image.url : '',
            date: this.dateControl.value,
            gender: this.newReview.sex,
            country: this.countrySelected.name,
            city: this.citySelected.name,
            extras: this.extras,
            allow_rewrite: this.newReview.allow_rewrite
        }
        
        //Asociate sub
        switch(method){
            case 'sub':
                data['sub_id'] = this.profile.sub_id;
            break;
            case 'credits':
                data['review_price'] = this.review_price;
            break;
            case 'promo':
                data['promo_0'] = true;
            break;
        }

        //Create review
        this._google.newReview(data).toPromise()
        .then(result =>{
            this.reviews = new MatTableDataSource([result['review']].concat(this.reviews.data));
            this.reviews.paginator = this.reviewPag;
            if(method === 'sub'){
                this.profile.sub.rw_published++;
                this.profile.sub.rw_total++;
            }
            if(method === 'credits' || this.extras.credits){
                var user = this._user.getLocalUser();
                const cost = method === 'credits'? 
                    this.review_price + this.extras.credits : 
                    this.extras.credits;
                user.credits -= cost;
                this._user.setLocalUser(user);
            }
            if(method === 'promo'){
                this.promo_0 = false;
            }
            
            //Clear form
            this.textControl.reset();
            this.newReview.img = undefined,

            this._utils.showSuccess("Reseña programada correctamente", 5000);
        })
        .catch(response =>{
            let error = response.error;

            console.log(error.msg);

            if(response.status == 413){
                this._utils.showError("Error al guardar reseña. La imagen es demasiado grande.", 5000);
                this.error = {img: true};
                return;
            }

            switch(error.errorCode){

                case this._utils.error_codes.MAX_REVIEWS_REACHED:
                    this._utils.showError("Ya ha programado " + error.max + " reseñas para el mismo día. Para evitar que Google las elimine seleccione una fecha distinta.", 10000);
                break;

                case this._utils.error_codes.RW_TEXT_REPEATED:
                    this._utils.showError("Ya ha programado alguna reseña con el mismo texto. Modifíquelo para evitar opiniones duplicadas.", 10000);
                break;

                default:
                    this._utils.showError("Error al guardar reseña", 5000);
                break;
            }

        })
    }


    openSubDialog(): void {
        const dialogRef = this.dialogSubs.open(AddSubDialogComponent, {
          width: 'auto',
          data:  {profile: this.profile}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
    }


    isEditable(review){
        return review.status === this._utils.review_status.WAITING;
        // if(review.status !== this._utils.review_status.WAITING) return false;
        // const today = new Date().setHours(0,0,0,0);
        // return Date.parse(review.date) > today;
    }

    isOnWarranty(review){

        if(review.status !== this._utils.review_status.PUBLISHED) return;

        let limit_date = new Date(review.date_published);
        limit_date.setHours(0,0,0);
        limit_date.setDate(limit_date.getDate() + 30);

        return new Date() <= limit_date;

    }

    reportHidden(review){

        this._utils.openConfirmation(
            "Se va a procesar la garantía de esta reseña. Esto solo se puede realizar una vez por publicación, asegúrese de que la reseña está realmente ocultada antes de continuar o de lo contrario perderá la garantía.",
            "Confirmar",
            "Volver",
            ()=>{
                this._google.reviewReported(review._id).toPromise()
                .then(response =>{
                    this._loadReviews(this.profile_id);
                    this._utils.showSuccess("La reseña será revisada por nuestros técnicos. Recibirá un email en caso de ser cancelada.", 5000);
                })
                .catch(error =>{
                    console.log(error);
                    this._utils.showError("Error al procesar la solicitud.", 5000);
                })
            },
        )

    }


    startEdit(review){
        review._old = Object.assign({}, review);

        for (const country of this.countries) {
            if(country.name == review.country){
                review.countrySelected = country;
                this.countryChangeEdit(review);
                break;
            }
        }

        review.edit = true;
    }


    cancelEdit(review){
        console.log("Restoring", review._old)
        Object.assign(review, review._old);
        review.edit = false;
        console.log("Restored", review)
    }


    async editReview(review){

        //Update img
        if(review.img && review.img != review._old.img){
            let image;
    
            try{
                image = await this._img.uploadImg(review.img).toPromise();
            }catch(e){
                this._utils.showError("Error al guardar reseña. La imagen es demasiado grande. Max: 6mb", 5000);
                return;
            }
    
            review.img = image.url;
        }

        //Update location
        if(review.countrySelected && review.citySelected){
            review.country = review.countrySelected.name;
            review.city = review.citySelected.name;
        }

        delete review._old;

        this._google.updateReview(review).toPromise()
        .then(response =>{
            this._utils.showSuccess("Reseña modificada correctamente", 5000);
            review.edit = false;
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al modificar reseña", 5000);
        })
    }


    public removeReview(review){

        this._utils.openConfirmation(
            'Se va a eliminar la reseña seleccionada.',
            'Eliminar',
            'Cancelar',
            ()=>{
                this._google.deleteReview(review._id).toPromise()
                .then(response =>{
                    this._loadReviews(this.profile_id);
                    this._utils.showSuccess("Reseña eliminada correctamente", 5000);
                    this._user.loadUser();
                })
                .catch(error =>{
                    console.log(error);
                    this._utils.showError("Error al eliminar reseña", 5000);
                })
            },
            ()=>{}
        );

    }


//EXTRAS WITH CREDITS


    calcExtra(){
        console.log("Extras changed", this.extras)
        this.extras.credits = 0;
        if(this.extras.lg.value) this.extras.credits += this.extras.lg.cost;
        if(this.extras.chips.value) this.extras.credits += this.extras.chips.cost;
    }


    /* ADD REVIEW WITH CREDITS */
    confirmnRW_order(): void {
        const dialogRef = this.dialog.open(CreditsDialogComponent, {
            width: 'auto',
            data:  {
                method: 'order',
                extras: this.extras
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.save('sub');
            }
        });
    }


    /* ADD REVIEW WITH CREDITS */
    confirmnRW_credits(): void {
        const dialogRef = this.dialog.open(CreditsDialogComponent, {
            width: 'auto',
            data:  {
                method: 'credits',
                extras: this.extras
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.save('credits');
            }
        });
    }


    /* ADD REVIEW WITH PROMO 0 */
    confirmnRW_promo(): void {
        const dialogRef = this.dialog.open(CreditsDialogComponent, {
            width: 'auto',
            data:  {
                method: 'promo',
                extras: this.extras
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.save('promo');
            }
        });
    }


}
    
@Component({
    selector: 'credits-dialog',
    templateUrl: 'credits-dialog.html',
    styleUrls: ['./credits-dialog.scss'],
})

/* BUY DIALOG */
export class CreditsDialogComponent implements OnInit{

    //Added with credits or from order
    public method;

    //Extras selected
    public extras;

    //Cost in credits
    public cost = 0;
    public credits;

    constructor(
        public dialogRef: MatDialogRef<CreditsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _utils: Utils,
        private _user: UserServices
    ) {
        
        this.method = data.method;
        this.extras = data.extras;

        if(this.method == 'credits') this.cost = _utils.credits_rw;
        if(this.extras) this.cost += this.extras.credits;
    };

    ngOnInit(){
        this.credits = this._user.getLocalUser().credits;
    }

}
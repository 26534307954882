import { Component, OnInit, ViewChild } from '@angular/core';
import {Utils} from '../../../../../services/utils';
import { UserServices } from 'src/app/services/user.service';
import { GoogleServices } from 'src/app/services/google-service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import Chart from 'chart.js';

@Component({
  selector: 'tools-profile',
  templateUrl: './tools-profile.component.html',
  styleUrls: ['./tools-profile.component.scss']
})

export class ToolsProfileComponent implements OnInit {

    @ViewChild('reviewPag')
    reviewPag: MatPaginator;
    reviews: MatTableDataSource<any>;
    review_columns = ['date', 'stars', 'text', 'user', 'actions'];

    public profiles;
    public profileSelected;
    public rw_stats;

    public report;

    constructor(
        private _utils: Utils,
        private _user: UserServices,
        private _google: GoogleServices
    ) {}

    ngOnInit() {

        //Load profiles
        this._user.loadProfiles()
        .then(profiles => {
            this.profiles = profiles;
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        });

    }

    loadProfile(){

        this.rw_stats = { 
            quantity: 0,
            rating: { r5: 0, r4:0, r3:0 },
            diff: 0,
            lg: 0
        };

        let rw_data = [];
        let stars_data = [];

        for(let item of this.profileSelected.history){
            rw_data.push({ t: new Date(item.date), y: item.reviews });
            stars_data.push({ t: new Date(item.date), y: item.rating })
        }

        let rw_ctx = document.getElementById('rwChart');
        let stars_ctx = document.getElementById('starsChart');
    
        let config = {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: {
                datasets: [{
                    label: this.profileSelected.name,
                    backgroundColor: "#ffae35",
                    borderColor: "#000000",
                    showLine: true,
                    data: rw_data
                }]
            },

            // Configuration options go here
            options: {
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: { unit: 'month' }
                    }]
                }
            }
        }

        let rw_chart = new Chart(rw_ctx, config);

        let stars_config = Object.assign({}, config);
        stars_config.options.scales['yAxes'] = [{ ticks: { suggestedMin: 0, suggestedMax: 5 } }];
        stars_config.data.datasets[0].data = stars_data;
        let stars_chart = new Chart(stars_ctx, stars_config);

        this.loadReviews();

    }

    loadReviews(){
        this._google.getReviews(this.profileSelected._id).toPromise()
        .then(response =>{
            let reviews = response['reviews'];

            this.rw_stats.quantity = reviews.length;

            for(let rw of reviews){
                this.updateRWStats(rw);
            }

            this.getDiff(this.profileSelected.rating, this.profileSelected.reviews, reviews.length, this.rw_stats.rating);
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar perfiles", 5000);
        })
    }

    updateRWStats(rw){
        if(rw.rating == 5) this.rw_stats.rating.r5++;
        if(rw.rating == 4) this.rw_stats.rating.r4++;
        if(rw.rating == 3) this.rw_stats.rating.r3++;
    }

    getDiff(rating, rw_total, rw_added, rating_added_bulk){

        if(rw_added == 0)return;

        let n_reviews = rw_total - rw_added;
        
        let rating_added = rating_added_bulk.r5 * 5 + rating_added_bulk.r4 * 4 + rating_added_bulk.r3 * 3;

        let old_rating = (rating - (rating_added / rw_total)) / (n_reviews / rw_total);

        let diff = rating - old_rating;

        let k = 2;
        while(Number(diff.toFixed(k)) == 0)k++;

        this.rw_stats.diff = Number(diff.toFixed(k)); 
    }

    getURL(link){
        const hangoutURL = 'https://hangouts.google.com/webchat/start?hl=en&action=chat&pi=';
        var id = link.split('/')[5];
        return hangoutURL + id;
    }

    getImg(string: String){
        return string.slice(5,string.length-2);
    }

    getStars(string: String){
        return string.slice(0,1);
    }

}

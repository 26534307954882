import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import { CustomTranslator } from 'src/app/services/i18n';

@Component({
    selector: 'alert-snackbar',
    templateUrl: 'alert-snackbar.html',
    styleUrls: ['./alert-snackbar.scss'],
  })
  export class AlertSnackbarComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public _i18n: CustomTranslator){}

  }
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CompanyService } from "src/app/services/company.service";
import { Utils } from "src/app/services/utils";

@Component({
    templateUrl: 'group-profiles.html',
    styleUrls: ['group-profiles.scss'],
})

export class GroupProfilesDialog{

    public group;
    public profiles_in;
    public profiles_out;

    constructor(
        private _dialogRef: MatDialogRef<GroupProfilesDialog>,
        @Inject(MAT_DIALOG_DATA) private _input,
        private _company: CompanyService,
        private _utils: Utils
    ){
        this.group = _input.group;
        this._loadProfiles(_input.profiles);
    }


    public close(){
        this._dialogRef.close();
    }


    private _loadProfiles(profiles){

        this.profiles_in = [];
        this.profiles_out = [];

        for(let profile of profiles){
            if(profile.group == this.group) this.profiles_in.push(profile); 
            else this.profiles_out.push(profile);
        }

    }


    public addProfile(profile){

        //Add profile to group
        this._company.addProfileGroup(profile._id, this.group)
        .then(response => {
            console.log(response)
            this._loadProfiles(response['profiles'])
            this._utils.showSuccess("Perfil actualizado correctamente.")
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar perfil. Inténtelo más tarde.")
        })

    }


    public removeProfile(profile){

        //Remove profile from group
        this._company.removeProfileGroup(profile._id, this.group)
        .then(response => {
            console.log(response)
            this._loadProfiles(response['profiles'])
            this._utils.showSuccess("Perfil actualizado correctamente.")
        })
        .catch(error => {
            console.log(error);
            this._utils.showError("Error al actualizar perfil. Inténtelo más tarde.")
        })        

    }

}
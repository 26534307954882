import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/services/utils';
import { ShopService } from 'src/app/services/shop.service';
import { UserServices } from 'src/app/services/user.service';

@Component({
    selector: 'payment-component',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})

export class PaymentComponent implements OnInit {

    private payerID;
    private token;

    public fail = false;
    public loading = false;
    public waiting = false;

    //Transfer data binding
    public order;
    public orderID;

    constructor(
        private _url: ActivatedRoute,
        private _utils: Utils,
        private _shop: ShopService,
        private _user: UserServices,
        private router: Router
    ) { }

    ngOnInit() {
        this._url.queryParams.subscribe( params => {

            const { method, orderID, canceled } = params;

            //Process different methods
            if (method) switch (method) {

                case 'stripe':
                    this.loading = true;
                    this._shop.paymentCompleted(orderID)
                    .then(result => {

                        const status = result['status'];

                        if(status === 'completed'){
                            //Update credits
                            this._user.loadUser().finally();
                            //Payment successful -> Dashboard/orders
                            this.router.navigate(['es/dashboard/orders']);
                        }

                        if(status === 'waiting'){
                            this.loading = false;
                            this.waiting = true;
                        }

                    })
                    .catch(error => {
                        console.log(error);
                        this.orderID = orderID;
                        this.loading = false;
                        this.fail = true;
                        this._utils.showError("Error al procesar el pago", 5000);
                    })
                    return
                break;

                case 'transfer':
                    this.loading = true;
                    //Load order data
                    this._user.getOrder(orderID)
                    .then(order => {
                        this.loading = false;
                        this.order = order;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fail = true;
                        this._utils.showError("Error al datos del pedido, contacte con el soporte.", 5000);
                    })
                    return
                break;

                case 'paypal': 

                    this.loading = true;

                    this._shop.paymentCompleted(orderID, { token: params['token'] })
                    .then(result => {

                        const status = result['status'];

                        if(status === 'completed'){
                            //Update credits
                            this._user.loadUser().finally();
                            //Payment successful -> Dashboard/orders
                            this.router.navigate(['es/dashboard/orders']);
                        }

                        if(status === 'waiting'){
                            this.loading = false;
                            this.waiting = true;
                        }

                    })
                    .catch(error => {
                        console.log(error);
                        this.fail = true;
                        this.loading = false;
                        this._utils.showError("Error al procesar el pago", 5000);
                    })
                    return
                break;

                default: return this.router.navigate(['es/dashboard/shop']);

            }

            //Order canceled
            if (canceled) {
                this._utils.pixelEvent("Order_Canceled", {});
                this.router.navigate(['es/dashboard/shop']);

                //orderID is available, could be used for engagement here

                return;
            }

            //Paypal order or sub payment callback
            else {

                console.log("Should not be here, check the code.")
                
                // this.loading = true;

                // this.token = params['token'];
                // this.payerID = params['PayerID'];

                // //Process payment
                // if (this.payerID && this.token) this.processPayment();

                // //Process subscription
                // else this.processSub();

            }

        },
        error => {
            console.log(error);
            this.loading = false;
            this.fail = true;
            this._utils.showError("Error al cargar la página, vuelva a intentarlo por favor.", 5000);
        });
    }

    // processPayment() {

    //     this._shop.payOrder({ token: this.token }).toPromise()
    //         .then(result => {
    //             //Update credits
    //             this._user.loadUser().finally();
    //             //Payment successful -> Dashboard/orders
    //             this.router.navigate(['es/dashboard/orders']);

    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.fail = true;
    //             this.loading = false;
    //             this._utils.showError("Error al procesar el pago", 5000);

    //         })
    // }

    // processSub() {

    //     var paypalData = { token: this.token }

    //     this._shop.paySub(paypalData).toPromise()
    //         .then(result => {

    //             //Payment successful -> Dashboard/orders
    //             this.router.navigate(['es/dashboard/orders']);

    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.fail = true;
    //             this.loading = false;
    //             this._utils.showError("Error al procesar la suscripción", 5000);

    //         })
    // }
}

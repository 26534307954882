import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AdminService } from "src/app/services/admin.service";
import { Utils } from "src/app/services/utils";
import { GoogleServices } from "src/app/services/google-service";

@Component({
    selector: 'confirm-publication-dialog',
    templateUrl: 'confirm-publication.html',
    styleUrls: ['./confirm-publication.scss'],
})

/* BUY DIALOG */
export class ConfirmPublicationDialog implements OnInit{

    public review;
    public edit;
    public lg;
    public conf = {};

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmPublicationDialog>,
        private _utils: Utils,
        private _google: GoogleServices
    ) {
        this.review = data.review;
    };

    ngOnInit(){

        //Remove access to info when timer ends
        //TODO


        //Load rw
        this._google.getAssignedReview(this.review._id).toPromise()
        .then(response =>{
            this.review = response['rw'];
        })
        .catch(error =>{
            console.log(error);
            this._utils.showError("Error al cargar información", 3000);
            this.dialogRef.close(false);
        })

    }

    approveReview(){
        this.dialogRef.close(true);
    }

    checkConfirm(){

        let ok = 
            this.conf['company'] &&
            this.conf['text']  &&
            this.conf['rating']  &&
            this.conf['name']  &&
            this.conf['account'];

        if(this.review.img) ok = ok && this.conf['img'];

        return !ok;

    }

}
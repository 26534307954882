import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

import { Utils } from '../../services/utils';
import { AuthenticationService } from '../../services/authentication.service';
import {UserServices} from '../../services/user.service';
import { CustomTranslator } from 'src/app/services/i18n';
import { GoogleServices } from 'src/app/services/google-service';
import { FpServices } from 'src/app/services/fp.service';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

    returnURL = '/es/dashboard';

    emailControl: FormControl;
    passControl: FormControl;

    constructor(
        public _i18n: CustomTranslator,    
        private _utils: Utils,
        private _auth: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _user: UserServices,
        private _google: GoogleServices,
        private _fp: FpServices,
    ){}

    ngOnInit() {

        this._auth.logout();//Force logout

        this.emailControl = new FormControl( '', [ Validators.required, Validators.email ] );
        this.passControl = new FormControl( '', [ Validators.required] );

        //Check query params
        this.activatedRoute.queryParams.subscribe(params => {

            const userId = params['code'];
            const google = params['scope'];
            const state = params['state'];

            if(!userId) return;
                
            //Login or register with Google
            if (google) {

                //Registro de Google
                if(state && JSON.parse(atob(state)).type){
                    this._user.registerGoogle(userId, state).toPromise()
                    .then(user => {
                        this._registerSuccessful(user);
                    })
                    .catch(error => {
                        console.log(error);
                        this._utils.showError('Error al logear con Google', 5000);
                    });
                }

                // Login de Google
                else{
                    this._user.loginGoogle(userId).toPromise()
                    .then(user => {
                        this._socialLoginSuccessful(user);
                    })
                    .catch(error => {
                        console.log(error);
                        this._utils.showError('Error al logear con Google', 5000);
                    });            
                }
            } 
            
            //Login or register with Facebook
            else {

                const fbState = localStorage.fbRegister;

                //Registro de Facebook
                if(fbState){
                    this._user.registerFacebook(userId, fbState).toPromise()
                    .then(user => {
                        localStorage.removeItem('fbRegister');
                        this._registerSuccessful(user);
                    })
                    .catch(error => {
                        console.log(error);
                        this._utils.showError('Error al logear con Facebook', 5000);
                    });
                }

                //Login de Facebook
                else{
                    this._user.loginFacebook(userId).toPromise()
                    .then(user => {
                        this._socialLoginSuccessful(user);
                    })
                    .catch(error => {
                        console.log(error);
                        this._utils.showError('Error al logear con Facebook', 5000);
                    });
                }
            }

        });

    }

    login(){

        //Check inputs
        if(!this.emailControl.valid || !this.passControl.valid)return;

        //Server auth (local user data is set there)
        this._auth.login(this.emailControl.value, this.passControl.value)
        .subscribe(
            result => {

                //Login successful
                if(result){
                    this._utils.pixelEvent("Login", this._user.sharedUser);

                    //Add fp to user
                    this._fp.onLoginSuccessful().finally(()=>{
                        //Redirect to dashboard
                        this.router.navigate([this.returnURL]);
                    });

                }

                //Internal Error
                else this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);
            },
            error => {

                //Login unsuccessful
                var cause = error.error.error;

                //Wrong email
                if(cause.includes('email')){
                    this.emailControl.setErrors({'email_unknown': true});
                }

                //Wrong password
                else if(cause.includes('password')){
                    this.passControl.setErrors({'password' : true});
                }

                //Unknown error
                else{
                    this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);
                    console.log(cause);
                }
            });
    }

    async googleLogin() {

        // let valid = await this._user.checkFP();

        window.location.href = 'https://api.imperatool.com/api/user/auth/google';

    }

    async facebookLogin() {

        // let valid = await this._user.checkFP();

        window.location.href = 'https://api.imperatool.com/api/user/auth/facebook';

    }

    private async _socialLoginSuccessful(user){

        //Check fp (this is a double check on normal flow but 1st check could be skipped so we check again here)
        // let valid = await this._user.checkFP();

        //Check Ban
        if(user.timeout || user.hard_ban){
            this._utils.showError("Ha ocurrido un error, inténtelo de nuevo más tarde.", 5000);
            return;
        }

        this._user.setLocalUser(user);
        this._utils.pixelEvent("Login", user);

        //Add fp to user
        this._fp.onLoginSuccessful().finally(()=>{
            //Redirect to dashboard
            this.router.navigate([this.returnURL]);
        });

    }

    private _registerSuccessful(user){
        this._user.setLocalUser(user);
        this._utils.pixelEvent("CompleteRegistration", user);

        //Add fp to user
        this._fp.onRegisterSuccessful().finally(()=>{
            //Redirect to dashboard
            this.router.navigate([this.returnURL]);
        });
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class ApiService {
  
  constructor(private _http: HttpClient, private _utils: Utils) { }

  getServiceTexts(network, service){
    return this._http.get(this._utils.frontURL + "/assets/texts/" + network + "/" + service+".json").pipe();
  }

  getCountries(){
    return this._http.get(this._utils.frontURL + "/assets/countries/es_ES_country.json").pipe();
  }

}

/**
 * Created by marco on 18/06/2017.
 */
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';

@Injectable()
export class SeoServices {

    constructor(private _http: HttpClient, private _utils: Utils) {}

    getKeywords(){
        return this._http.get(this._utils.backURL + '/api/serp/', this._utils.jwt()).pipe();
    }
    
    addKeyword(keyword, profile_id){
        var data = {
            keyword: keyword,
            profile_id: profile_id
        };
        return this._http.post(this._utils.backURL + '/api/serp/addKeyword', data, this._utils.jwt()).pipe();
    }

    deleteKeyword(keyword){
        return this._http.post(this._utils.backURL + '/api/serp/delete', {keyword: keyword}, this._utils.jwt()).pipe();
    }

}
